import manager from '../platform/Manager'


// HTML resources
//import resource from './resource.html'; manager.addHtmlResource( 'resource', resource )

// Page classes
//import page from './page'; manager.addPageClass( 'page', page )
import Pruebas from './Pruebas'; manager.addPageClass( 'Pruebas', Pruebas )
import GenericUpdateTest from './GenericUpdateTest'; manager.addPageClass( 'GenericUpdateTest', GenericUpdateTest )
import Upload from './Upload'; manager.addPageClass( 'Upload', Upload )
import TestModals from './Modals'; manager.addPageClass( 'TestModals', TestModals )
