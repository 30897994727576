import manager from '../platform/Manager'

import './usuario/_index'

// HTML resources
//import resource from './resource.html'; manager.addHtmlResource( 'resource', resource )

// Page classes
//import page from './page'; manager.addPageClass( 'page', page )
import {Admin} from './Admin'; manager.addPageClass( 'Admin', Admin )
