// Module
var code = `<div class="container">
<form id="{{id}}_form">
    <div class="mb-3">
        <label for="{{id}}_nombre" class="form-label">Nombre</label>
        <input class="form-control" id="{{id}}_nombre"/>
    </div>

    <div class="d-flex"> 
        <button class="btn btn-secondary ms-auto me-2" type='button' onclick="_.returns()">CANCELAR</button>
        <button type='button' class="btn btn-primary" onclick="_('crear')">ACEPTAR</button>
    </div>


</form>
</div>
`;
// Exports
export default code;