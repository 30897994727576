// Module
var code = `<div class="container">
<form id="{{id}}_form">
    <h4>Nueva Ubicación</h4>

    <div class="row pb-3">
        <div class="col-6">
            <label for="{{id}}_tipo" class="form-label">Tipo de ubicación</label>
            <select class="form-select" id="{{id}}_tipo">
                <option value="0">SALON</option>
                <option value="1">BAR</option>
                <option value="2">HOTEL</option>
                <option value="3">ALMACEN</option>
                <option value="4">BINGO</option>
              </select>
        </div>
    </div>

    <div class="d-flex"> 
        <button class="btn btn-secondary ms-auto me-2" type='button' onclick="_.returns()">CANCELAR</button>
        <button type='button' class="btn btn-primary" onclick="_('crear')">ACEPTAR</button>
    </div>


</form>
</div>
`;
// Exports
export default code;