import manager from '../../platform/Manager'
import {Ficha} from '../../platform/pages/Ficha'
import html from './ficha.html'
import html_nuevo from './ficha-nueva.html'

export class Mueble extends Ficha{

    constructor (id, mueble, valoresIniciales){
        let config={
            procView: 'mueble__ficha'
            , updateTable: 'mueble'
            , htmlCreate: html_nuevo
            , htmlEdit: html
            , valida: ['fabricante']
        }
        if ( valoresIniciales ) config['defValues']= valoresIniciales
        super( id, config, mueble )
    }

    crear(){
        let self= this
        manager.request('DB/procedure/run/mueble__crea', [this.getValor('fabricante')])
        .done( response => {
            self.mode= 'edit'
            self.receiveDataAndRefresh( response )
        })
    }

    getClave(){ return this.data.mueble }



}
