// Module
var code = `<div class="container bg-secondary-subtle">
    <h4>Ficha de Ubicación</h4>
    <form id="{{id}}_form">

        <div class="row pb-3">

            <div class="col">
                <label for="{{id}}_ubicacion" class="form-label">Nº ubicación</label>
                <input class="form-control" id="{{id}}_ubicacion" value="{{ubicacion}}" readonly>
            </div>


            <div class="col">
                <label for="{{id}}_tipo" class="form-label">Tipo de ubicación</label>
                <select class="form-select" id="{{id}}_tipo" onblur="_('validate', {id:'tipo'})">
                    <option value="0" {{tipo_sel_0}}>SALON</option>
                    <option value="1" {{tipo_sel_1}}>BAR</option>
                    <option value="2" {{tipo_sel_2}}>HOTEL</option>
                    <option value="3" {{tipo_sel_3}}>ALMACEN</option>
                    <option value="4" {{tipo_sel_4}}>BINGO</option>
                  </select>
            </div>
        </div>
    
        <div class="row pb-3">

            <div class="col">
                <label for="{{id}}_cod_gestimaq" class="form-label">Cod. Gestimaq</label>
                <input class="form-control" id="{{id}}_cod_gestimaq" value="{{cod_gestimaq}}" onblur="_('validate', {id:'cod_gestimaq'})">
            </div>

        </div>

        <div class="row pb-3">


            <div class="col">
                <label for="{{id}}_nombre" class="form-label">Nombre</label>
                <input class="form-control" id="{{id}}_nombre" value="{{nombre}}" onblur="_('validate', {id:'nombre'})">
            </div>

        </div>
        <div class="row pb-3">


            <div class="col">
                <label for="{{id}}_direccion" class="form-label">Dirección</label>
                <input class="form-control" id="{{id}}_direccion" value="{{direccion}}" onblur="_('validate', {id:'direccion'})">
            </div>

        </div>
        
        <div class="row pb-3">
            
    
            <div class="col">
                <label for="{{id}}_poblacion" class="form-label">Poblacion</label>
                <input class="form-control" id="{{id}}_poblacion" value="{{poblacion}}" onblur="_('validate', {id:'poblacion'})">
            </div>

        </div>

        <div class="row pb-3">
            
            

            <div class="col">
                <label for="{{id}}_cod_postal" class="form-label">Cod. postal</label>
                <input class="form-control" id="{{id}}_cod_postal" value="{{cod_postal}}" onblur="_('validate', {id:'cod_postal'})">
            </div>

            <div class="col">
                <label for="{{id}}_provincia" class="form-label">Provincia</label>
                <input class="form-control" id="{{id}}_provincia" value="{{provincia}}" onblur="_('validate', {id:'provincia'})">
            </div>


        </div>

        <div class="row pb-3">
            <div class="col-3">
                <label for="{{id}}_zona" class="form-label">Zona</label>
                <div class="input-group">
                    <input class="form-control" id="{{id}}_zona" onblur="_('validate', {id:'zona'})" value="{{zona}}">
                    <button class="btn btn-secondary" type="button" onclick="_('lookup', {id:'zona'} )" ><i class="bi bi-three-dots"></i></button>
                </div>
            </div>

            <div class="col-9">
                <label for="{{id}}_zona_nombre" class="form-label">Nombre zona</label>
                <input class="form-control" id="{{id}}_zona_nombre" readonly value="{{zona_nombre}}">
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-3">
                <label for="{{id}}_empresa" class="form-label">Empresa</label>
                <div class="input-group">
                    <input class="form-control" id="{{id}}_empresa" onblur="_('validate', {id:'empresa'})" value="{{empresa}}">
                    <button class="btn btn-secondary" type="button" onclick="_('lookup', {id:'empresa'} )" ><i class="bi bi-three-dots"></i></button>
                </div>
            </div>
            <div class="col-9">
                <label for="{{id}}_empresa_nombre" class="form-label">Nombre empresa</label>
                <input class="form-control" id="{{id}}_empresa_nombre" readonly value="{{empresa_nombre}}">
            </div>
        </div>

       <div class="row pb-3">
    
            <div class="col">
                <label for="{{id}}_latitud" class="form-label">Latitud</label>
                <input class="form-control" id="{{id}}_latitud" value="{{latitud}}" onblur="_('validate', {id:'latitud'})">
            </div>

            <div class="col">
                <label for="{{id}}_longitud" class="form-label">Longitud</label>
                <input class="form-control" id="{{id}}_longitud" value="{{longitud}}" onblur="_('validate', {id:'longitud'})">
            </div>

        </div>

        <div class="row pb-3">
            
            <div class="col">
                <label for="{{id}}_telefono" class="form-label">Teléfono</label>
                <input class="form-control" id="{{id}}_telefono" value="{{telefono}}" onblur="_('validate', {id:'telefono'})">
            </div>

        </div>

        <div class="row pb-3">
                
            <div class="col">
                <label for="{{id}}_email" class="form-label">Correo electrónico</label>
                <input class="form-control" id="{{id}}_email" value="{{email}}" onblur="_('validate', {id:'email'})">
            </div>

        </div>

    </form>
    </div>
    `;
// Exports
export default code;