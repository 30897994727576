import {TObject} from './TreeObject'
import manager from './Manager'

export class EObject extends TObject{

    eventHandlers= {}
    registeredEvents= []
    
    registerEvents( events ){
        for( let e=0; e < events.length; e++) this.registeredEvents.push( events[e] )
    }

    /**
        event: Identificador del evento
        handler: Identificador del método de manejo del evento
        oid: Identificador el objeto que va a manejar el evento. Ademas se toma en los únicos eventos que tenemos hasta ahora (hide, show, unload) para 
        restringir la ejecución a solo ese elemento. osea, si la pagina que vamos a mostrar es la que se carga, entonces se llama el metodo, sino nada.
    */ 

// Si el oid está en la misma rama lo cargamos como local, sino como global.

    addListener( event, objectId, method ){
        let entry= { event: event, id: objectId, method: method }
        if ( typeof this.eventHandlers[objectId] === 'undefined' ) this.eventHandlers[objectId] = []
        this.eventHandlers[objectId].push( entry )
    }

/** OFF: Da de baja un manejador registrado anteriormente
        event: Identificador del evento
        handlerId: Identificador del handler obtenido al ejecutar el on CUIDADO! No funciona si borramos y ponemos, porque no estamos dando identificador, sino cursor!!!!
*/
    removeListeners( objectId ){
        if ( typeof this.eventHandlers[ objectId ] !== 'undefined' ) {
            delete( this.eventHandlers[ objectId ] )
        }
    }

/** FIRES
	Lanza los manejadores de un evento
        event: Identificador del evento a lanzar
        params: Parámetros de la llamada
        obj: Objeto que se toma como this para el handler del evento.
            Si el evento se ha configurado con un bind, y se define este campo, solo se lanza el handler si coninciden.
*/

/*
    event: Identificador el evento.
    params: Parametros a pasar al manejador
    branch: Identificador de la pagina-rama sobre la que se lanza el evento o false para todas las ramas.


    Esta habiendo un problema con los evento generados por el manager. 
    En el manager se meten todas las paginas y se estan ejecutando shows de paginas que no se abren.
    Los dataset se ejecutan todos porque solo se dan de alta al un dataset los componentes que tienen que responder.
    Hay que buscar como parar el show para solo la página que se está ejecutando.

*/
    fire( event, params, objectId ) 
        // Lanza el evento solo para la pagina y subcomponentes que estén en la misma rama que la página actual. 
        // Es el objeto que lanza los eventos el que decide a quien (global/local)
        // (¿ Modulos de varias páginas ?)
        // Si el componente no esta dentro de una pagina (rama), ¿ lo lanzamos a nivel global por defecto ?
        // si el dispatcher es una pagina o subcomponente de página se lanza a nivel local, sino se lanza a nivel global.
        // Si queremos hacer algo global lo tenemos que poner fuera de una página.  
        // Se lanzaran todos los asociados mientras el objeto dispatcher esté vivo.
        // Solo está la excepción del Manager.
    {
        let returns, count= 0, objectIds= []

//        console.log( `firing event ${event} on id ${objectId} `)

        if ( ! this.registeredEvents.includes( event )) {
            console.log(`No se ha registrado el evento '${event}' en la clase '${this.constructor.name}'`)
            return false
        }
        if ( typeof params  === 'undefined' ) params=[]
        if ( !Array.isArray( params ) ) params= [ params ]

//        if ( typeof objectId !== 'undefined' && typeof this.eventHandlers[objectId] !== 'undefined' ) objectIds.push( objectId )
        if ( typeof objectId !== 'undefined' ){ 
            Object.getOwnPropertyNames( this.eventHandlers ).forEach( id => {
                if ( id.substring( 0, objectId.length ) == objectId ) objectIds.push( id )
            })
        } else Object.getOwnPropertyNames( this.eventHandlers ).forEach( id => objectIds.push( id ))
        let self= this
        objectIds.forEach( objectId => {
            self.eventHandlers[ objectId ]
            .filter( entry => entry.id == objectId && entry.event == event )
            .forEach( entry => {
//                console.log( `entry ${entry.id} id ${objectId}`)
                let handler= manager.getObject( objectId ) // manager = main TObject.
                if (handler) {
                    if ( typeof handler[entry.method] === 'function' ) {
                        returns= handler[entry.method].apply( handler, params )
                        count++
                    } else console.log(`Error. No se encuentra el método '${entry.method}' en el objeto '${objectId}'`)
                }
            })
        })
        if (count == 1 && returns ) return returns
        return false
    }
    


}