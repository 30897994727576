import mustache from 'mustache' 

class RenderError extends Error {
    constructor( tptId, message ){
        super( message )
        this.error= 121
        this.errorMsg= `Error de renderizado en plantilla ${tptId}: ${message}`
    }
}

/**
 * tpt - template
 * context - render-params
 * id - identificador solo para el log de errores.
 */
export default function render ( tpt, context, id ){
    let result
    if ( typeof id === 'undefined' ) id='unknown'
    try { result= mustache.render( tpt, context ) }
    catch( error ) { throw new RenderError( id, error )}
    return result
}

