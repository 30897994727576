import manager from '../platform/Manager'
import {UIComponent} from '../platform/UIComponent'
import layout from './layout.html'
import home from './home.html'
import modals from '../comun/modals/modals.html'
import {Dialog} from '../comun/modals/Dialog'

export class Admin extends UIComponent{
    renderCount= 0

    constructor(id, datosSesion){
        super(id)
        this.renderParams={
            usuario: datosSesion.usuario
            , modals: modals
        }
        this.datosSesion= datosSesion
        this.menu= this.addUIComponent( manager.getClass( 'Menu' ) )
        this.menu.setAlias('menu')
        this.menu.addItem( "_.open('AgendaLista')", 'AGENDAS' , 'Lista de Agendas pendientes de registrar.', 'A' )
        this.menu.addItem( "_.open('Salones')", 'SALONES' , 'Listado de salones (priv).', 'S' )
        this.menu.addItem( "_.open('Pruebas')", 'PRUEBAS' , 'Pruebas de la aplicación.', 'P' )
        this.menu.addItem( "_.open('UsuarioLista')", 'USUARIOS' , 'Lista de usuarios y permisos de acceso.', 'U' )
        this.showErrorDlg= this.addComponent(Dialog, 1)
        
//        this.menu.addItem( "_.open('')", '' , '.', '' )
    }




     htmlDynamic(){
        let htmlTemplate= {
             html: home
        }
    
        if ( !this.renderCount++ ){
            htmlTemplate.wrap= layout
        }

        return htmlTemplate
    }

    showError( err ){
        if ( err.errorMsg ) err.errorMsj= err.errorMsg 
        this.showErrorDlg.show( err ) 
    }
    
}