import manager from '../../platform/Manager'

// HTML resources
//import resource from './resource.html'; manager.addHtmlResource( 'resource', resource )

// Page classes
//import page from './page'; manager.addPageClass( 'page', page )
//import {Integraciones} from './Integraciones'; manager.addPageClass( 'Integraciones', Integraciones )

import {TestAPI} from './TestAPI'; manager.addPageClass( 'IS_ApiTest', TestAPI )
import {Maestros} from './Maestros'; manager.addPageClass( 'IS_Maestros', Maestros )
import {Clientes} from './Clientes'; manager.addPageClass( 'IS_Clientes', Clientes )
import {Query} from './Query'; manager.addPageClass( 'IS_Query', Query )
