// Module
var code = `<div class="container bg-secondary-subtle">
    <h3>AGENDA NUEVA</h3>

    <div class="row pb-3">
        <div class="col-3">
            <label for="{{idx}}_fabricante" class="form-label">Fabricante</label>
            <div class="input-group">
                <input class="form-control" id="{{idx}}_fabricante" onblur="_('validate', {set:{{set}}, row:{{row}}, id:'fabricante'})" value="{{fabricante}}">
                <button class="btn btn-secondary" type="button" onclick="_('lookup', {set:{{set}}, row:{{row}}, id:'fabricante'} )" ><i class="bi bi-three-dots"></i></button>
            </div>
        </div>
        <div class="col-9">
            <label for="{{idx}}_fabricante_nombre" class="form-label">Nombre fabricante</label>
            <input class="form-control" id="{{idx}}_fabricante_nombre" readonly value="{{fabricante_nombre}}">
        </div>
    </div>




    <div class="d-flex pb-3"> 
        <button class="btn btn-secondary ms-auto me-2" type='button' onclick="_.returns()">CANCELAR</button>
        <button type='button' class="btn btn-primary" onclick="_('crear')">ACEPTAR</button>
    </div>

</div>`;
// Exports
export default code;