import manager from '../platform/Manager'

// HTML resources
import Bye from './login/bye.html'; manager.addHtmlResource( 'ByeBye', Bye )
//import resource from './resource.html'; manager.addHtmlResource( 'resource', resource )

// Page classes
//import page from './page'; manager.addPageClass( 'page', page )
import {Salones} from './lists/Salones'; manager.addPageClass( 'Salones', Salones )
import {Grupos} from './lists/Grupos'; manager.addPageClass( 'Grupos', Grupos )
import {Maquinas} from './lists/Maquinas'; manager.addPageClass( 'Maquinas', Maquinas )
import {MaquinasPorSalon} from './lists/MaquinasPorSalon'; manager.addPageClass( 'MaquinasPorSalon', MaquinasPorSalon )

import {Lookup} from './lookup/Lookup'; manager.addPageClass( 'Lookup', Lookup )
import {Query} from './query/Query'; manager.addPageClass( 'Query', Query )
import {Login} from './login/Login'; manager.addPageClass( 'Login', Login )
import {SinPerfil} from './login/SinPerfil'; manager.addPageClass('SinPerfil', SinPerfil)

// Component Classes
import {Menu} from './menu/Menu'; manager.addClass( 'Menu', Menu )