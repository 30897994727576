import {UIComponent} from '../../platform/UIComponent'
import manager from '../../platform/Manager'
import htmltpt from './ficha.html'
import AgendaUI from './Agenda'
import MaquinaUI from './Maquina'
import cargando from './cargando.html'

export default class extends UIComponent{
    agenda= null
    maquinas= []
    data= null
    lookingUp= false //identificador del campo y puesto del grupo del que se está esperando el lookup
   

    constructor( id, idAgenda ){
        super(id)
        if ( idAgenda ){
            manager.request(
                'DB/procedure/run/agenda__ficha', [ idAgenda ]
            )
            .done( this.inicializaAgenda.bind( this ) )
            .fail( this.showError.bind( this ) )
        } else {
            // Agenda Nueva
            manager.request(
                'DB/procedure/run/agenda__crea'
            )
            .done( this.inicializaAgenda.bind( this ) )
            .fail( this.showError.bind( this ) )
        }
    }


    inicializaAgenda( response ){
        let self= this
        this.data= {}
        if (typeof response.agenda !== 'undefined') this.data= response.agenda[0]
        else this.data={}
        if (typeof response.grupo !== 'undefined') this.data.grupos= response.grupo; else this.data.grupos=[]
        if (typeof response.maquina !== 'undefined') this.data.maquinas= response.maquina; else this.data.maquinas=[]
        this.agenda= this.addUIComponent( AgendaUI, this.data )
        this.maquinas= []
        this.data.maquinas.map( ( maquina) =>{
            self.maquinas.push( this.addUIComponent( MaquinaUI, maquina ))
        })
        this.refresh()
    }

    agregaGrupo( grupo ){
        manager.request(
            'DB/procedure/run/agenda__agrega_grupo/', [ this.data.agenda, grupo ]
        )
        .done( this.inicializaAgenda.bind( this ) )
        .fail( this.showError.bind( this ) )

    }

    agregaMaquina(){
        manager.request(
            'DB/procedure/run/agenda__agrega_maquina/', [ this.data.agenda ]
        )
        .done( this.inicializaAgenda.bind( this ) )
        .fail( this.showError.bind( this ) )

    }

    quitaGrupo( grupo ){
        manager.request(
            'DB/procedure/run/agenda__quita_grupo/', [ this.data.agenda, grupo ]
        )
        .done( this.inicializaAgenda.bind( this ) )
    }

    quitaMaquina( maquina ){
        manager.request(
            'DB/procedure/run/agenda__quita_maquina/', [ this.data.agenda, maquina ]
        )
        .done( this.inicializaAgenda.bind( this ) )
        .fail( this.showError.bind( this ) )
    }

    showError( error ){
        console.log( error )
    }

    htmlDynamic(){
        if ( ! this.agenda ) return cargando
        else {
            let html= { agenda: null, maquinas: '' }
            html.agenda= this.agenda.html().html          
            this.maquinas.map( (maq) => html.maquinas+= maq.html().html )
            return _.render( htmltpt, html )
        }
    }

    lookup( ){
        this.lookingUp= arguments[0]
        _.open('Lookup', this.lookingUp.id )

    }

    ret( ret ){
        if ( this.lookingUp ){
            console.log(`looked-up id: ${this.lookingUp.id}. puesto: ${this.lookingUp.puesto}. val: ${ret}.`)
            this.validate( Object.assign( {valor: ret}, this.lookingUp ) )
            this.lookingUp= false
        }
    }

    validate( ){
        let validando = arguments[0]
        if (typeof validando.valor == 'undefined'){
            validando.valor= this.getValor( validando.id, validando.puesto )
        }
        switch( validando.id ){
            case 'fabricante': 

                if ( validando.valor == '') validando.valor= null
                if ( validando.valor !== this.data.fabricante ){
                    manager.request('DB/procedure/run/agenda__valida_fabricante', [this.data.agenda, validando.valor] )
                    .done( response => {
                        this.setValor( 'fabricante', validando.puesto, response[0][0].fabricante)
                        this.setValor( 'fabricante_nombre', validando.puesto, response[0][0].nombre)
                        this.fabricante= response[0][0].fabricante
                        this.fabricante_nombre= response[0][0].fabricante_nombre
                    })
                }
                break;

            case 'descripcion':
            default: 
                if ( validando.valor == '') validando.valor= null
                if ( validando.valor !== this.data[validando.id] ){
                    manager.request('DB/procedure/run/agenda__valida_' + validando.id, [this.data.agenda, validando.valor] )
                    .done( response => {
                        this.setValor( validando.id, validando.puesto, response[0][0][validando.id])
                        this.data[validando.id]= response[0][0][validando.id]
                    })
                }
                break;
        }

    }

    getValor( id, puesto ){
        let element
        if ( puesto == 0 ) element= document.getElementById( this.agenda.id + '_' + id )
        else element= document.getElementById( this.maquinas[puesto - 1].id + '_' + id )
        return element.value
    }

    setValor( id, puesto, valor ){
        let element
        if ( puesto == 0 ) element= document.getElementById( this.agenda.id + '_' + id )
        else element= document.getElementById( this.maquinas[puesto - 1].id + '_' + id )
        element.value= valor
    }


}