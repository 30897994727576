import manager from '../../platform/Manager'

// HTML resources
//import resource from './resource.html'; manager.addHtmlResource( 'resource', resource )

/**
 * CREA LA CONSULTA Zona.sql en el servidor
 * PONLE CONTENIDO a los formularios de ficha
 * CERA LOS PROCEDIMIENTOS. zona__ficha, zona__elimina, zona__crea
 * ACTUALIZA LOS VALORES DE LA TABLA permiso__update por para los diferentes perfiles de los campos actualizables por perfil
 * PREPARA LOS CAMPOS DE LOOKUP, UPLOAD, Y ENUMS.
 */


// Page classes
//import page from './page'; manager.addPageClass( 'page', page )
import {Zona} from './Ficha'; manager.addPageClass( 'ZonaFicha', Zona )
import {ZonaLst} from './Lista'; manager.addPageClass( 'ZonaLst', ZonaLst )
