import { Lista } from '../../platform/pages/Lista'
import actions from './lista-actions.html' 

export class CertificadoLst extends Lista{

    constructor(id){
        super( id, 'certificado' )
        this.renderParams={ 
            id: id
            , title: 'CERTIFICADOS DEL FABRICANTE PDTES DE RECIBIR'
            , actions: actions
        }
    }

    registra(){
        let selected= this.list.getSelectedKeys()
        if ( selected.length > 0 ) _.open('Certificado', selected[0])
    }

}