import manager from '../Manager'
import {UIComponent} from '../UIComponent'
import {Dialog} from '../../comun/modals/Dialog'
import dom from '../libs/Dom'

export class Ficha extends UIComponent{
    config /* { procView, updateTable
                htmlCreate, htmlEdit
                , valoresIniciales
                , valida
            */
    mode // create | edit
    data // datos del registro o ficha
    lookingUp= false
    uploading= false
    uploadDlg
    enabledUploadFileTypes= ['application/pdf']
    selects=[]
    /** VALIDA - lista con los identificadores de los campos que deben validarse
     *  Inicialmente solo se usa en la ficha - nueva, en modo create para validar los alias y traer los nombres
     *  La validacion real en modo edit se hace a través de la función update que ¿ valida ? ¿ cuando ?
     *  Para una siguiente fase simplificamos esto y realizamos dos llamadas, la de validación primero y luego la de update.
     */
    valida=[] // Son los campos que deben validarse.

    /**
     * Para gestionar los scroll al volver de la página
     * en onHide almacenamos el scroll= window.scrollY
     * al volver lo cargamos window.scroll({top: scroll, left: 0, behavior: 'instant'})
     */ 

    
    constructor (id, config, clave){
        super(id)
        this.renderParams={ id: id }
        this.config= config
        if (typeof config.valida !== 'undefined' ) this.valida= config.valida
        

        // TODO CLAVE DE MAS DE UN CAMPO.
        if ( clave ) {
            this.mode= 'edit'
            manager.request(
                'DB/procedure/run/' + this.config.procView, clave
            )
            .done( this.receiveDataAndRefresh.bind( this ) )
        } else {
            this.mode = 'create'
            if( config.defValues ) {
                let self= this
                setTimeout( () =>{
                    Object.keys( config.defValues ).map( id => self.validate( {id: id, valor: config.defValues[id] } ))
                }, 0 )
            }
        }

        this.uploadDlg= this.addComponent( Dialog, 2)

    }

    receiveData( response ){
        this.data= response[0][0]
    }

    receiveDataAndRefresh( response ){
        this.receiveData( response )
        this.refresh()
    }

    getRenderParams( ){
        if ( this.data && this.data.agenda ) {
            return this.prepareRenderParams( Object.assign( {}, this.data.agenda[0] ) )
        } else return {}
    }

    getHtmlTemplate(){
        switch ( this.mode ){
            case 'create': return this.config.htmlCreate
            case 'edit': return this.config.htmlEdit
        }
    }

    prepareRenderParams( params ){ 
        if (this.data ){
            Object.getOwnPropertyNames( this.selects ).map( id =>{
                if ( this.data[ id ] ) {
                    let ix= this.selects[ id ].indexOf( this.data[ id ] )
                    if ( ix >= 0 ) params[ id + '_sel_' + ix ] = 'selected'
                }
            })
        }
        return params
    }

    /**
     * lOOKUP
     * Lanza una llamada a la ventana de lookup y esconde la página hasta que retorna el valor
     * lookingUp={
     *      .id -> pagina de lookup
     *      .query -> query de lookup
     * }
     */

    lookup( lookingUp ){
        let query
        this.lookingUp= arguments[0]
        query= typeof this.lookingUp.query !== 'undefined' ? this.lookingUp.query : this.lookingUp.id
        _.open('Lookup', query )
    }

    /**
     * RETORNO
     * Si esta activo un lookup validamos el resultado.
     */

    ret( ret ){
        if ( this.lookingUp ){
//            console.log(`looked-up: ${this.lookingUp}. val: ${ret}.`)
            this.lookingUp.valor= ret //[0]
            this.validate(  this.lookingUp )
            this.lookingUp= false
        }
    }

    /**
     * VALIDATE 
     * validando{
     *      .id - identificador del campos que se quiere validar
     *      .valor - valor que quiere validarse
     * }
     * En this.valida tenemos los campos que deben validarse en el servidor con table__valida_campo
     * El resto se actualizan con DB/update
     */
    validate( validando ){
        let self= this
        if (typeof validando == 'string') validando= {id: validando}
        if (typeof validando.valor == 'undefined'){
            validando.valor= this.getValor( validando.id )
        }

        if ( validando.valor == '') validando.valor= null
        if ( this.mode == 'edit' ) {
            if ( validando.valor !== this.data[validando.id] ){
                manager.request('DB/update/run/'+ this.config.updateTable, [[ this.getClave() ], validando.id, validando.valor] )
                .done( response => {
                    self.setValor( validando.id, response[0][0][validando.id])
                    self.data[validando.id]= response[0][0][validando.id]
                    if ( typeof response[0][0].nombre !== 'undefined'){
                        this.setValor( validando.id + '_nombre', response[0][0].nombre)
                        this.data[validando.id + '_nombre']= response[0][0].nombre
                    }
                })
            }
        } else { // mode: create
            if ( this.valida.includes(validando.id)  ){
                self.setValor( validando.id, validando.valor)
                manager.request('DB/procedure/run/'+ validando.id +'__valida', [ validando.valor, '@void'] ) //el segundo parametro es necesario, es de salida y se usa llamadas directas entre procedimientos almacenados
                .done( response => {
                    self.setValor( validando.id, response[0][0][validando.id])
                    self.setValor( validando.id + '_nombre', response[0][0].nombre)
                })
            } else self.setValor( validando.id, validando.valor )
        }

    }

    /**
     * UPLOAD.
     * Los uploads los gesionamos con el modal M02. 
     * No se esconde la página
     * El mismo modal responde ejecutando _(doupload)
     */

    upload( id ){
        console.log( 'upload ' + id )
        this.uploading= id
        document.getElementById('_M02_upload').value= null
        this.uploadDlg.show({
            titulo: id
        })
    }

    doupload( element ){
        let self= this

        if ( element.files.length ){
            let file= element.files[0], reader= new FileReader()
            if ( this.enabledUploadFileTypes.includes( file.type )){

                reader.onload = () => {
                    let base64Content= reader.result.split(',')[1] // Obtén solo la parte base64 del resultado
                    manager.request(`/DB/upload/run/${self.config.updateTable}/${self.getClave()}/${self.uploading}`, {
                        content: base64Content, // Enviar directamente la base64
                        filename: file.name,
                        filetype: file.type
                    }).done( response => {
                        console.log(response[0][0])
                        document.getElementById( `${self.id}_${this.uploading}`).value= response[0][0].documento
                        dom.createTextNodes([{id: `${self.id}_${this.uploading}_nombre` , text: response[0][0].nombre}])
                        this.uploadDlg.hide()
                    }).finally(
                        self.uploading= false
                    )
                }
                reader.readAsDataURL(file) // Leer el archivo como una URL base64

            } else {
                this.uploadDlg.hide()
                manager.route('showError', {
                    errorMsg: `Los ficheros de tipo '${file.type}' no están permitidos.
                    Los ficheros permitidos son: ${enabledTypes}.`
                })
            }
        }
    }



    download( id ){ //tabla, clave, campo 
        console.log( 'download ' + id )
        manager.request(`/DB/download/run/${this.config.updateTable}/${this.getClave()}/${id}`)
        .done(response => { _.app.export( response[0][0].contenido, response[0][0].mime_type)}) 
        
    }



    getValor( id ){
        let element
        element= document.getElementById( this.id + '_' + id )
        switch ( element.nodeName ){
            case 'SELECT':
                if ( element.selectedIndex == -1 ) return null //''
                else return element.selectedIndex + 1  //this.selects[ id ][  ] // Los enums comienzan por 1
            default: 
                return typeof element.value == 'string' ? element.value.trim() : element.value
        }
    }

    setValor( id, valor ){
        let element
        element= document.getElementById( this.id + '_' + id )
        switch ( element.nodeName ){
            case 'SELECT':
                if (typeof valor == 'number') element.value= valor - 1
                else element.value= this.selects[id].indexOf(valor) 
                break
            default: element.value= valor
        }
        
    }

}
