// Module
var code = `<div class="container bg-secondary-subtle">
    <h1>FICHA DE AGENDA</h1>

    <form id="{{id}}_form">
    
        <div class="row pb-3">
            <div class="col-3">
                <label for="{{idx}}_agenda" class="form-label">Agenda</label>
                <input class="form-control" id="{{idx}}_agenda" readonly value="{{agenda}}">
            </div>
            <div class="col-9">
                <label for="{{idx}}_usuario" class="form-label">Creada por</label>
                <input class="form-control" id="{{idx}}_usuario" readonly value="{{usuario}}">
            </div>
        </div>
    
        <div class="row pb-3">
            <div class="col-3">
                <label for="{{idx}}_fabricante" class="form-label">Fabricante</label>
                <div class="input-group">
                    <input class="form-control" id="{{idx}}_fabricante" readonly  value="{{fabricante}}">
                </div>
            </div>
            <div class="col-9">
                <label for="{{idx}}_fabricante_nombre" class="form-label">Nombre fabricante</label>
                <input class="form-control" id="{{idx}}_fabricante_nombre" readonly value="{{fabricante_nombre}}">
            </div>
        </div>
    
        <div class="row pb-3">
            <div class="col-3">
                <label for="{{idx}}_modelo" class="form-label">Modelo</label>
                <div class="input-group">
                    <input class="form-control" id="{{idx}}_modelo" onblur="_('validate', {set:{{set}}, row:{{row}}, id:'modelo'})" value="{{modelo}}">
                    <button class="btn btn-secondary" type="button" onclick="_('lookup', {set:{{set}}, row:{{row}}, id:'modelo' } )" ><i class="bi bi-three-dots"></i></button>
                </div>
            </div>
            <div class="col-9">
                <label for="{{idx}}_modelo_nombre" class="form-label">Nombre modelo</label>
                <input class="form-control" id="{{idx}}_modelo_nombre" readonly value="{{modelo_nombre}}">
            </div>
        </div>
    
        <div class="pb-3">
            <label for="{{idx}}_descripcion" class="form-label">Descripción</label>
            <textarea class="form-control" id="{{idx}}_descripcion" onblur="_('validate', {set:{{set}}, row:{{row}}, id:'descripcion'})" rows="3">{{descripcion}}</textarea>
        </div>

        <div class="pb-3">
            {{{grupo}}}
        </div>
            

        <div class="row pb-3">
            <div class="col">
                <label for="{{idx}}_maquinasNuevas" class="form-label">Nº de máquinas nuevas</label>
                <div class="input-group">
                    <input class="form-control" id="{{idx}}_maquinasNuevas" readonly value="{{maquinas_nuevas}}">
                    <button class="btn btn-primary" type="button" onclick="_('agregaMaquina')" ><i class="bi bi-plus"></i></button>
                </div>
            </div>
            <div class="col">
                <label for="{{idx}}_maquinasTotal" class="form-label">Nº de máquinas totales</label>
                <input class="form-control" id="{{idx}}_maquinasTotales" readonly value="{{maquinas_totales}}">
            </div>
    
        </div>
    
    
    
    </form>
    

    {{{maquina}}}

    <nav class="d-flex pb-3">
        <div class="ms-auto">
            <button class="btn btn-secondary me-2" onclick="_.returns()">CANCELAR</button>
            <button class="btn btn-primary" onclick="_('confirma')">CONFIRMAR</button>
        </div>
    </nav>

</div>


<!-- modals -->
<div class="modal fade" tabindex="-1" id="{{id}}_AG">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">AGREGA GRUPO</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="pb-3">
                    <label for="{{id}}_AG_NoGrupo" class="form-label">Nº de grupo</label>
                    <div class="input-group">
                        <input class="form-control" id="{{id}}_AG_NoGrupo" >
                        <button class="btn btn-secondary" type="button" onclick="_('lookup', {id:'agrega_grupo', puesto: 0} )" data-bs-dismiss="modal"><i class="bi bi-three-dots"></i></button>
                    </div>
        
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">CANCELAR</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onclick="_( '{{id}}', 'agregaGrupo' )">ACEPTAR</button>
            </div>
        </div>
    </div>
</div>
`;
// Exports
export default code;