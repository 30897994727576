// Module
var code = `<div class="row border-bottom  p-2 mb-3">
    <div class="col col-10">
        <form id="{{id}}_form">
            <div class="row mb-3">
                <div class="col-3">
                    <label for="{{id}}_fabricante" class="form-label">Fabricante</label>
                    <div class="input-group">
                        <input class="form-control" id="{{id}}_fabricante" onblur="_('validate', {id:'fabricante', puesto: 0})" value="{{fabricante}}">
                        <button class="btn btn-secondary" type="button" onclick="_('lookup', {id:'fabricante', puesto: 0} )" ><i class="bi bi-three-dots"></i></button>
                    </div>
                </div>
                <div class="col-9">
                    <label for="{{id}}_fabricante_nombre" class="form-label">Nombre fabricante</label>
                    <input class="form-control" id="{{id}}_fabricante_nombre" readonly value="{{fabricante_nombre}}">
                </div>
            </div>

            <div class="mb-3">
                <label for="{{id}}_descripcion" class="form-label">Descripción</label>
                <textarea class="form-control" id="{{id}}_descripcion" onblur="_('validate', {id:'descripcion', puesto: 0})" rows="3">{{descripcion}}</textarea>
            </div>

            <div class="mb-3">
                <label for="{{id}}_grupos" class="form-label">Grupos</label>
                <div class="form-control" id="{{id}}_grupos">{{{grupos}}}
                    <span >
                        <a class="text-primary ms-1" data-bs-toggle="modal" data-bs-target="#{{id}}_AG"><i class="bi bi-plus-square"></i></a>
                    </span>
                </div>
                <div class="form-text">We'll never share your email with anyone else.</div>
            </div>
        </form>

    </div>
    
    

    

    <div class="col">
        <ul class="nav">
            <li class="nav-item"><a class="nav-link" onclick="_('agregaMaquina')"><i class="bi bi-file-plus"></i> MAQUINA </i></a></li>
        </ul>
    </div>
</div>

<div class="modal fade" tabindex="-1" id="{{id}}_AG">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">AGREGA GRUPO</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                    <label for="{{id}}_AG_NoGrupo" class="form-label">Nº de grupo</label>
                    <input type="input" class="form-control" id="{{id}}_AG_NoGrupo">
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">CANCELAR</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onclick="_( '{{id}}', 'agregaGrupo' )">ACEPTAR</button>
            </div>
        </div>
    </div>
</div>

`;
// Exports
export default code;