// Module
var code = `<div class="container bg-secondary-subtle">
    <form id="{{id}}_form">

        <div class="row mb-3">
            <div class="col">
                <label for="{{id}}_mueble" class="form-label">Mueble</label>
                <input class="form-control" id="{{id}}_mueble" readonly value="{{mueble}}"/>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-3">
                <label for="{{id}}_fabricante" class="form-label">Fabricante</label>
                <div class="input-group">
                    <input class="form-control" id="{{id}}_fabricante" onblur="_('validate', {id:'fabricante'})" value="{{fabricante}}">
                    <button class="btn btn-secondary" type="button" onclick="_('lookup', {id:'fabricante'} )" ><i class="bi bi-three-dots"></i></button>
                </div>
            </div>
            <div class="col-9">
                <label for="{{id}}_fabricante_nombre" class="form-label">Nombre fabricante</label>
                <input class="form-control" id="{{id}}_fabricante_nombre" readonly value="{{fabricante_nombre}}">
            </div>
        </div>

        <div class="mb-3">
            <label for="{{id}}_nombre" class="form-label">Nombre</label>
            <input class="form-control" id="{{id}}_nombre" value="{{nombre}}" onblur="_('validate', {id:'nombre'})"/>
        </div>
        
    </form>
    </div>
    `;
// Exports
export default code;