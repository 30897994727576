/** FILTRO
 * 
 *  Se trata de una ficha básica sin updates, pero con la opción de validar y ver los permisos.
 *  Se pondran todos los campos de una tabla, con posibles añadidos de nombres
 *  Cada vez que haya una modificación se envía un evento change al padre para que actualice
 *  En este evento solo se remite la información de los campos no añadidos
 *  El padre para el que se ha diseñado es la lísta
 */

import { UIComponent } from "../UIComponent"
import manager from "../Manager"


export class Filtro extends UIComponent{

    lookingUp= false
    data // definición de los campos para un futuro donde se explica el tipo de entrada o control que debe tener
    filtro=[] // campos que se envían como filtros
    valida=[] // campos que deben validarse
    tabla // Nombre de la tabla para la validación

    constructor(id, tabla, filtro, valores, html, valida){
        super(id)
        this.registerEvents(['change']) // incluir request-start request-end
        this.tabla= tabla
        this.filtro= filtro
        this.data= valores
        this.htmlTemplate= html
        this.renderParams= Object.assign( {id:id}, valores)
        if (valida) {
            let self= this
            this.valida= valida
            this.valida.map( id => {
                if ( self.data[ id ]) self.validate( {id:id, valor: self.data[id] }, true )
            })
        } 
    }

    lookup( ){
        let query
        this.lookingUp= arguments[0]
        query= typeof this.lookingUp.query !== 'undefined' ? this.lookingUp.query : this.lookingUp.id
        manager.openWithReturn('Lookup', [query], this.ret.bind(this) )
    }

    ret( ret ){
        console.log('ejecutando ret')
        if ( this.lookingUp ){
//            console.log(`looked-up: ${this.lookingUp}. val: ${ret}.`)
            this.lookingUp.valor= ret[0]
            this.validate(  this.lookingUp )
            this.lookingUp= false
        }
    }

    validate( validando, nofire ){
        let self= this

        if (typeof validando == 'string') validando= {id: validando}
        if (typeof validando.valor == 'undefined'){
            validando.valor= this.getValor( validando.id )
        }

        if ( validando.valor == '') validando.valor= null
        if ( validando.valor !== this.data[validando.id] ){
       
            if (this.valida.includes( validando.id )){
                manager.request('DB/validate/run/'+this.tabla,[ validando.id, validando.valor] )
                .done( response => {

                    self.setValor( validando.id, response[0][0][validando.id])
                    self.data[validando.id]= response[0][0][validando.id]
                    self.renderParams[validando.id]= response[0][0][validando.id]
                    if ( typeof response[0][0].nombre !== 'undefined') {
                        self.setValor( validando.id + '_nombre', response[0][0].nombre)
                        self.data[validando.id + '_nombre'] = response[0][0].nombre
                        self.renderParams[validando.id + '_nombre']= response[0][0].nombre
                    }
                    if( !nofire){
                        this.fire('change', self.getFiltro())
                    } 
                })
    
            } else {
                this.setValor( validando.id, validando.valor)
                this.data[validando.id]= validando.valor
                this.renderParams[validando.id]= validando.valor
                if( !nofire) {
                    this.fire('change', self.getFiltro())
                }

            }
        }
    }

    getValor( id ){
        let element
        element= document.getElementById( this.id + '_' + id )
        switch ( element.nodeName ){
            case 'SELECT':
                if ( element.selectedIndex == -1 ) return null //''
                else return element.selectedIndex + 1  //this.selects[ id ][  ] // Los enums comienzan por 1
            default: 
                return typeof element.value == 'string' ? element.value.trim() : element.value
        }
    }

    setValor( id, valor ){
        let element
        element= document.getElementById( this.id + '_' + id )
        switch ( element.nodeName ){
            case 'SELECT':
                if (typeof valor == 'number') element.value= valor - 1
                else element.value= this.selects[id].indexOf(valor) 
                break
            default: element.value= valor
        }
        
    }

    getFiltro(){
        let filtro= {}, self= this
        this.filtro.map( id => filtro[id]= self.data[ id ] )
        return filtro
    }

}