import {Component} from '../../platform/Component'
import {Modal} from 'bootstrap'
import dom from '../../platform/libs/Dom'

export class Dialog extends Component{
    modalId= 0 //Identificador de plantilla modal en modals.html. Que tiene tres bigotes como mucho - title, content y actions
    _dlg

    constructor( id, modalId){
        super(id)
        this.modalId= modalId
    }

    show( params, options ){
        let id= '_M' + this.modalId.toString().padStart(2, '0')

        if (params){
            let texts= []
            Object.getOwnPropertyNames( params ).map( ( tid ) => { // Al cerrar el dialogo hay que volver a dejarlo vacío
                texts.push({ id: id + '_' + tid, text: params[tid] })
            })
            dom.createTextNodes( texts )
        }
            
        this._dlg= new Modal( '#'+id, options ) 
        this._dlg.show( )
    }

    isShown(){ return this._dlg._isShown }
    hide(){ this._dlg.hide() }

}