import manager from '../../platform/Manager'
import { UIComponent } from '../../platform/UIComponent'
import { RecordSet } from '../../platform/db/RecordSet'
import { List } from '../../platform/list/List'
import default_html from './lista.html'

export class Lista extends UIComponent{
    cardPage
    query //id, params, options, keys
    deleteProc

    constructor(id, query, html ){
        super( id )
        if (typeof html == 'undefined') html= default_html
        this.htmlTemplate= html
        this.setQuery( query )
        this.recordset= this.addComponent( RecordSet )
        this.list= this.addUIComponent( List, this.recordset )
        setTimeout( this.openQuery.bind(this), 0 )
        manager.addListener( 'show', this.id,  'onShow')
        this.renderParams={id: id}

    }

    setQuery( query ){
        if ( typeof query == 'string' ) {
            this.cardPage= query + 'Ficha'
            this.deleteProc= query + '__elimina'
            this.query={id: query.toLowerCase()}
        } else this.query= query
    }

    onShow(){
        this.recordset.refresh()
    }

    openQuery(){ this.recordset.setQuery( this.query )  } //query, params, options, keys

    next(){ this.recordset.seekNextPage(1) }

    previous(){ this.recordset.seekNextPage(-1) }

    ficha(){
        manager.open( this.cardPage, this.list.getSelectedKeys()[0] )
    }

    elimina(){
        let self= this
        manager.request('/DB/procedure/run/' + this.deleteProc, this.list.getSelectedKeys()[0] )
        .always( () => {self.recordset.refresh()} )
    }

    default(){ this.ficha() }
    cancel(){ 
        let selected= this.list.getSelectedKeys()
        if ( selected.length > 0 ) this.list.unselect()
        else manager.returns()
     }


}


 