// Module
var code = `<div class="container bg-secondary-subtle">
    <form id="{{id}}_form">

        <div class="row mb-3">
            <div class="col">
                <label for="{{id}}_fabricante" class="form-label">Fabricante</label>
                <input class="form-control" id="{{id}}_fabricante" readonly value="{{fabricante}}"/>
            </div>
            <div class="col">
                <label for="{{id}}_alias" class="form-label">Alias</label>
                <input class="form-control" id="{{id}}_alias" value="{{alias}}" onblur="_('validate', {id:'alias'})"/>
            </div>
        </div>

        <div class="mb-3">
            <label for="{{id}}_nif" class="form-label">NIF</label>
            <input class="form-control" id="{{id}}_nif" value="{{nif}}" onblur="_('validate', {id:'nif'})"/>
        </div>

        <div class="mb-3">
            <label for="{{id}}_nombre" class="form-label">Nombre</label>
            <input class="form-control" id="{{id}}_nombre" value="{{nombre}}" onblur="_('validate', {id:'nombre'})"/>
        </div>
        
        <div class="row pb-3">
            <div class="col-8">
                <label for="{{id}}_email" class="form-label">Correo electrónico</label>
                <input  class="form-control" id="{{id}}_email" onblur="_('validate', {id:'email'})" value="{{email}}"/>
            </div>
            
            <div class="col-4">
                <label for="{{id}}_telefono" class="form-label">Teléfono</label>
                <input  class="form-control" id="{{id}}_telefono" onblur="_('validate', {id:'telefono'})" value="{{telefono}}"/>
            </div>
        </div>
    
    </form>
    </div>
    `;
// Exports
export default code;