import manager from '../../platform/Manager'
import { UIComponent } from '../../platform/UIComponent'
import { RecordSet } from '../../platform/db/RecordSet'
import { List } from '../../platform/list/List'
import dom from  '../../platform/libs/Dom'
import html from './maquinas.html'
import html_sat from './maquinas-sat.html'

export default class extends UIComponent{

    constructor(id, grupo){
        super( id )
        this.renderParams={id: id}
        switch(manager.getMainPage().datosSesion.perfil){
            case 'SAT': this.renderParams[ 'acciones' ]= html_sat; break
        }
        this.htmlTemplate= html
        this.grupo= grupo
        this.recordset= this.addComponent( RecordSet )
        this.list= this.addUIComponent( List, this.recordset, {selectType: 'none'} )
        setTimeout( this.openQuery.bind(this), 0 )
        manager.addListener( 'show', this.id,  'onShow')

    }

    onShow(){ this.recordset.refresh() }

    openQuery(){ 
        let self= this
        this.recordset.setQuery( 'Maquinas', { grupo: this.grupo } )  
        manager.request('DB/procedure/run/grupo__nombre', [ this.grupo ])
        .done( (response) => {
            dom.createTextNodes([{id: self.id + '_grupo' , text: response[0][0].nombre}])
            dom.createTextNodes([{id: self.id + '_ubicacion' , text: response[0][0].nombre_ubicacion}])
        })  
    } 

    imprimeEtiquetas(){
        console.log('IMPRIME ETIQUETAS')
    }

    first() { this.recordset.seekPage(0) }
    previous(){ this.recordset.seekNextPage(-1) }
    next(){ this.recordset.seekNextPage(1) }
    last(){ }


}