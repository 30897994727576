import manager from '../../platform/Manager'
import {Ficha} from '../../platform/pages/Ficha'
import html from './ficha.html'
import html_nuevo from './ficha-nueva.html'

export class Zona extends Ficha{
    selects= {
//        impresora: ['', 'MAIN', 'SAT-GC' , 'SAT-LZ', 'SAT-TF', 'SAT-PM']
//        , perfil: ['ADMIN', 'CONTABLE', 'CONSULTOR', 'DOCUMENTACION', 'GESTOR', 'OPERACIONES', 'RECAUDACION', 'SAT']
    }


    constructor (id, maestro){
        super(id, { procView: 'maestro__ficha', updateTable: 'maestro', htmlCreate: html_nuevo, htmlEdit: html }, maestro)
    }

    crear(){
        let self= this
        manager.request('DB/procedure/run/maestro__crea', [])
        .done( response => {
            self.mode= 'edit'
            self.receiveDataAndRefresh( response )
        })
    }

    getClave(){ return this.data.maestro }


}
