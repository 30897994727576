import manager from '../../platform/Manager'
import dom from '../../platform/libs/Dom'
import {UIComponent} from '../../platform/UIComponent'
import html from './login.html'
import htmlError from './login-error.html'

export default class extends UIComponent{
    htmlTemplate= html

    constructor(id){
        super(id)
        this.renderParams={id:id}
    }

    login(){
        let data= _.dom.getFormData( 'login' )
        manager.request('/DB/procedure/run/sesion__inicio_pub', [data.usuario, data.clave])
        .done( (response) => {
            _.app.setSessionData( {token: response[0][0].token })
            switch( response[0][0].perfil ){
                case 'SAT':  _.switchTo( 'Sat', response[0][0]); break

                case 'OPERACIONES':
                case 'ADMIN':
                case 'CONTABLE':
                case 'RECAUDACION':
                case 'DOCUMENTACION':
                case 'GESTOR':
                case 'CONSULTOR':
                default: _.switchTo( 'Gestor', response[0][0] )
            } 
        })
    }

    showError( err ){
        let html= _.render( htmlError, Object.assign( {id: this.id} , err ) )
        $( '#' + this.id +'_error' ).append( html )
    }

}