// Module
var code = `<div class="container">
    <h4>JACKPOT API TEST</h4>

    <ul>
        <li><button onclick="_('doInfo')">INFO</button></li>
        <li><button onclick="_('doRequest', 'locations/buildings' )">BUILDINGS</button></li>
    </ul>
        


</div>`;
// Exports
export default code;