// Module
var code = `<div class="container">
    <div class="row border-bottom p-2 mb-3">
        <div class="col col-10">
            <h3>PUESTO {{no_puesto}} MAQUINA {{maquina}} [{{grupo}}]</h3>
            <p>juego: {{juego}}</p>
            <p>mueble: {{mueble}}</p>
        </div>

        <div class="col">
            <ul class="nav">
                <li class="nav-item"><a class="nav-link text-danger" onclick="_('quitaMaquina',{{maquina}})"><i class="bi bi-file-minus"></i> MAQ.</a></li>
                <li class="nav-item"><a class="nav-link text-danger" onclick="_('quitaGrupo',{{grupo}})"><i class="bi bi-folder-minus"></i> GRUPO</a></li>
                <li class="nav-item"><a class="nav-link" onclick="_()" ><i class="bi bi-file-play"></i>REASIGNAR</a></li>
                <li class="nav-item"><a class="nav-link" onclick="_()" ><i class="bi bi-folder-symlink"></i>REASIGNAR</a></li>
            </ul>
        </div>
    </div>
</div>`;
// Exports
export default code;