import manager from '../../platform/Manager'
import {UIComponent} from '../../platform/UIComponent'
import html from './InsertarUbicacion.html'

export class GTM_InsertarUbicacion extends UIComponent{
    htmlTemplate= html

    inserta(){
        let cod_gestimaq= document.getElementById( this.id + '_cod' ).value.trim()
        , valida= document.getElementById( this.id + '_valida_empresa' ).checked ? 1 : 0
        manager.request('DB/procedure/run/migracion__inserta_ubicacion', [cod_gestimaq, valida])
        .done( response => {
            console.log(response)
        })

    }
}