// Module
var code = `<div class="container">
    <h4>{{title}}</h4>

    {{{list}}}

    <nav class="d-flex">
        <ul class="nav justify-content-start">
            <!--li class="nav-item"><a class="text-primary" onclick="_('first')"><i class="bi-rewind" style="font-size: 2rem; "></i></a></li!-->
            <li class="nav-item"><a class="text-primary" onclick="_('previous')"><i class="bi-skip-start" style="font-size: 2rem;"></i></a></li>
            <li class="nav-item"><a class="text-primary" onclick="_('next')"><i class="bi-skip-end" style="font-size: 2rem; "></i></a></li>
            <!--li class="nav-item"><a class="text-primary" onclick="_('last')"><i class="bi-fast-forward" style="font-size: 2rem; "></i></a></li!-->
        </ul>
        <div class="ms-auto">
            {{{actions}}}
        </div>
    </nav>
</div>

<!-- modals -->
<div class="modal fade" tabindex="-1" id="{{id}}_LEF">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="{{id}}_LEF_title"></h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body"><form id="{{id}}_LEF_form">
                {{{form}}}
            </form></div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">CANCELAR</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onclick="_( 'guardaFicha' )">ACEPTAR</button>
            </div>
        </div>
    </div>
</div>

`;
// Exports
export default code;