import manager from './platform/Manager'

import './comun/_index'
import './gestor/_index'
import './sat/_index'
import './operaciones/_index'
import './contable/_index'
import './admin/_index'
import './pruebas/_index'
import './documentacion/_index'
import './salon/_index'
import './integraciones/_index'

import {StaticPage} from './platform/StaticPage'; manager.addPageClass('StaticPage', StaticPage)
import render from './platform/Render'
import dom from './platform/libs/Dom' 
import $ from 'jquery'
import * as bs from 'bootstrap'
import App from './App' 

export * from '../style/index.scss'

window.$= $
window.bs= bs

window._= function(){ return manager.route.call( manager, ...arguments ) }
window._.render= render
window._.dom= dom
window._.app= new App()
window._.open= manager.open.bind(manager)
window._.openModal= manager.openModal.bind( manager )
window._.switchTo= manager.switchTo.bind( manager )
window._.returns= manager.returns.bind( manager )
window._.current= manager.getCurrPage.bind( manager )
window._.main= manager.getMainPage.bind( manager )
window._.home= manager.goHome.bind( manager )
// getApp()
window._.manager= manager

window._boot.codeLoaded()