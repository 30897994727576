import {Component} from '../../platform/Component'
import defaultTpt from './modal-tpt.html'
import render from '../../platform/Render'
import {Modal} from 'bootstrap'
import actionsOK from './actions.html'

/*
    actions - hay tres tipos de acciones
        1) si / no - aceptar / cancelar
            En ese caso la acción se crea cuando se pulsa el aceptar o enter 
            por lo que el código se debe poner en el default de la página actual con el condicional que el dialogo se esté ejecutando
            En el caso que se pulse escape o se pulse cancelar, no se hace nada mas que ocultar el dialogo.
            Habría que ver que pasa si una página puede tener mas de un dialogo.
        2) aceptar
            El dialogo es solo de muestra de información y no hay otra accion posible que cerrar el dialogo
        3) acciones definidas por el usuario
            En este caso cada acción llamará a un método diferente de la pagina actual, si hay alguna por defecto se hará con el default 
            El escape igualmente anula cualquier acción y cierra el dialogo. 

*/

export class CustomDialog extends Component{
    layoutTpt= defaultTpt // plantilla de layout del dialogo
    contentTpt= null // plantilla de contenido
    actions= actionsOK //html de las acciones en el pie
    _dlg

    constructor( id, title, content){
        super(id)
        this.contentTpt= content
        this.title= title
    }

    setLayout( tpt ){ this.layoutTpt= tpt }
    setContent( tpt ){ this.contentTpt= tpt }
    setActions( html ){ this.actions= html  } 

    /* En el custom dialog la base del dialogo se carga en el id _M00 */

    show( params, options ){
        let content= render( this.contentTpt, params )
            , html= render( this.layoutTpt, {title: this.title, content: content, actions: this.actions})
            , node= document.getElementById( '_M00_content_' )

        node.innerHTML= html
        this._dlg= new Modal( '#_M00', options ) 
        this._dlg.show( )
    }

    isShown(){ return this._dlg._isShown }
    hide(){ this._dlg.hide() }

}