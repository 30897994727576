import manager from '../../platform/Manager'
import { UIComponent } from '../../platform/UIComponent'
import { RecordSet } from '../../platform/db/RecordSet'
import { List } from '../../platform/list/List'
import dom from '../../platform/libs/Dom'
import html from './grupos.html'

export default class extends UIComponent{

    constructor(id, ubicacion){
        super( id )
        this.renderParams={id: id}
        this.ubicacion= ubicacion
        this.htmlTemplate= html
        this.recordset= this.addComponent( RecordSet )
        this.list= this.addUIComponent( List, this.recordset, {selectType: 'none', linkedCols: [ 'maquinas' ]} )
        setTimeout( this.openQuery.bind(this), 0 )
        manager.addListener( 'show', this.id,  'onShow')
    }

    onShow(){ this.recordset.refresh() }

    openQuery(){ 
        let self= this
        this.recordset.setQuery( 'Grupos', { ubicacion: this.ubicacion } )
        manager.request('DB/procedure/run/ubicacion__nombre', [ this.ubicacion ])
        .done( (response) => {
            dom.createTextNodes([{id: self.id + '_salon' , text: response[0][0].nombre}])
        })  
    } 

    onLinkedClick( params ){
        let [col, key ]= params
        switch( col ){
            case 'maquinas': manager.switchTo('Maquinas', key); break
        }


    }
    
    first() { this.recordset.seekPage(0) }
    previous(){ this.recordset.seekNextPage(-1) }
    next(){ this.recordset.seekNextPage(1) }
    last(){ }


}