import manager from '../../platform/Manager'

// HTML resources
//import resource from './resource.html'; manager.addHtmlResource( 'resource', resource )

// Page classes
//import page from './page'; manager.addPageClass( 'page', page )
import {GTM_InsertarMultipuesto} from './InsertarMultipuesto'; manager.addPageClass( 'GTM_InsertarMultipuesto', GTM_InsertarMultipuesto )
import {GTM_InsertarUbicacion} from './InsertarUbicacion'; manager.addPageClass( 'GTM_InsertarUbicacion', GTM_InsertarUbicacion )

