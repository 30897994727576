// Module
var code = `<div class="table-container mb-3">
    <table class="table table-striped table-sm" style="width:{{width}}rem">
        <thead>
            <tr id="{{id}}_HR">
                {{#cabs}}
                    <th style="width:{{width}}rem" class="text-center">{{{caption}}}</th>
                {{/cabs}}
            </tr>
        </thead>
        <tbody>
            {{#lins}}
            <tr id="{{id}}-{{row}}" onclick="_('{{id}}', 'select', [{{row}}])" class="{{#isSelected}}table-primary{{/isSelected}}">
                {{#cols}}
                    <td style="text-align:{{textAlign}}">{{{content}}}</td>
                {{/cols}}
            </tr>
            {{/lins}}
        </tbody>
    </table>
</div>
`;
// Exports
export default code;