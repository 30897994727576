import manager from '../../platform/Manager'
import {UIComponent} from '../../platform/UIComponent'
import html from './TestAPI.html'

export class TestAPI extends UIComponent{
    htmlTemplate= html

   
    doRequest( req ){
        manager.request( 'IS/APICall/execute/' + req.name )
    }

    doLogin(){
        manager.request( 'IS/APICall/execute/login' )
        .then( (response) =>{
            console.log(response)
            if ( response.success ) {
                _.app.setVars( 'sesion', response.result )
                console.log( _.app.getSectionVars('sesion')) // session_id: number
            }

        } )
    
    }


    doInfo(){
        manager.request( 'IS/APICall/info' )
        .done( response => console.log(response) )
    }

    doRequest( path, get, post ){
        manager.request( `IS/APICall/request/${path}`, get, post)
        .done( response => console.log( response ))
    }

}