// Module
var code = `<div class="container">
    <h4>JACKPOT API TEST</h4>

    <ul>
        <li><button onclick="_('doRequest',{name:'hello'})">HELLO</button></li>
        <li><button onclick="_('doInfo')">INFO</button></li>
        <li><button onclick="_('doRequest',{name:'error'})">ERROR</button></li>
        <li><button onclick="_('doLogin')">LOGIN</button></li>
        <li><button onclick="_('doAccounting')">ACCOUNTING</button></li>
        <li><button onclick="_('getAccounting')">get ACCOUNTING</button></li>
        <li><button onclick="_.returns()">volver</button></li>
    </ul>
        


</div>`;
// Exports
export default code;