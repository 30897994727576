
class Dom{

	/** GET FORM DATA
		Recupera los valores de los controles de un formulario
			formName: Nombre del formulario.
	*/
	getFormData( formName, asArray ){
		var data, form, i, id
		
		data= asArray ? [] : {};
		
		if ( typeof formName === 'undefined' && typeof document.forms[0] !== 'undefined' ) {
			form= document.forms[0] 
		
		} else if( typeof formName !== 'undefined' && typeof document.forms[ formName ] !== 'undefined' ) {
			form= document.forms[ formName ]
			
		} else return {} 
		
		for( i= 0; i < form.elements.length; i++){
			
			if ( form.elements[i].name.length > 0 ) id= form.elements[i].name
			else if ( form.elements[i].id.length > 0 ) id= form.elements[i].id
			else id = i
			
			if ( asArray ) id= i
			data[ id ]= form.elements[i].value
		}
		
		return data
	}

	/** SET FORM DATA
		Carga unos valores concretos en los controles de un formulario
			formName: Nombre del formulario.
			values: Objeto cuyas propiedades son los identificadores de los controles donde cargar sus valores
	*/
	setFormData( values, formName ){
		var form, i, id
		
		if ( typeof formName === 'undefined' && typeof document.forms[0] !== 'undefined' ) {
			form= document.forms[0] 
		
		} else if( typeof formName !== 'undefined' && typeof document.forms[ formName ] !== 'undefined' ) {
			form= document.forms[ formName ]
			
		} else return {} 
		
		for( i= 0; i < form.elements.length; i++){
			
			if ( form.elements[i].name.length > 0 ) id= form.elements[i].name
			else if ( form.elements[i].id.length > 0 ) id= form.elements[i].id
			else id = i
			
			if (typeof values[id] !== 'undefined' ) form.elements[i].value= values[id]
		}
		
	}


	/** CLEAR FORM DATA
		Borra los valores de los controles de un formulario
			formName: Nombre del formulario.
	*/
	clearFormData( formName ){
		var form, i
		
		if ( typeof formName === 'undefined' && typeof document.forms[0] !== 'undefined' ) {
			form= document.forms[0] 
		
		} else if( typeof formName !== 'undefined' && typeof document.forms[ formName ] !== 'undefined' ) {
			form= document.forms[ formName ]
			
		} else return {} 
		
		for( i= 0; i < form.elements.length; i++) form.elements[i].value= '';
	}

	/** CREATE TEXT NODES
		Busca nodos por sus ids y sustituye el contenido que tuvieran por los textos que se pasan.
			textos: array de {id: identificador, text: texto a sustituir
	*/
	createTextNodes( texts ){
		var i, node, text, old
		
		for( i=0; i < texts.length; i++ ){
			node= document.getElementById( texts[i].id )
			if ( node ){
				text= document.createTextNode( texts[i].text );
				old= node.firstChild
				if ( old ) node.replaceChild( text, old );
				else node.appendChild( text );
			}
		}
	}

	getOffset( id ){
		let width, height
		let left=0, top=0
		let e= document.getElementById( id )
		if (e){

			width= e.offsetWidth; height= e.offsetHeight
			do {
				console.log( `id ${e.id}: left: ${e.offsetLeft}, top: ${e.offsetTop}`)
				left+= e.offsetLeft; top+= e.offsetTop
				e= e.offsetParent
			} while (e)
			
			let ret=  {
				left: left, right: document.documentElement.clientWidth-left-width, width: width
				, top: top, bottom: document.documentElement.clientHeight-top-height, height: height
			}
			console.log( `window: width: ${document.documentElement.clientWidth}, height: ${document.documentElement.clientHeight}`)
			console.log(ret)
			return ret

		} else return false
	}

	parseLocationSearch( search ) {
		if (! search) search= window.location.search.substring(1) // quitamos el ? inicial
		if (! search) return false
		let vars= {}
		search.split('&').forEach( (assignment) =>{
			let parts= assignment.split('=')
			vars[ decodeURIComponent( parts[0] ) ]= decodeURIComponent( parts[1] )
		})
		if ( Object.keys(vars).length ) return vars
		else return false
	}


}


export default new Dom()