import {UIComponent} from '../../platform/UIComponent'
import html from './item.html'

export default class extends UIComponent {

    constructor(id, comando, titulo, descripcion, tecla){
        super(id)
        this.renderParams= {
            comando: comando
            , titulo: titulo
            , descripcion: descripcion
            , tecla: tecla
        }
        this.htmlTemplate= html
    }

}