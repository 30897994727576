import manager from '../platform/Manager'

import './agenda/_index'

// HTML resources
//import resource from './resource.html'; manager.addHtmlResource( 'resource', resource )

// Page classes
//import page from './page'; manager.addPageClass( 'page', page )
import Gestor from './Gestor'; manager.addPageClass( 'Gestor', Gestor )
