import manager from '../platform/Manager'
import {UIComponent} from '../platform/UIComponent'
import layout from './layout.html'
import home from './home.html'

export default class extends UIComponent{
    renderCount= 0

    constructor(id, datosSesion){
        super(id)
        this.datosSesion= datosSesion
        this.menu= this.addUIComponent( manager.getClass( 'Menu' ) )
        this.menu.setAlias('menu')
        this.menu.addItem( "_.open('')", '' , '.', 'L' )
//        this.menu.addItem( "_.open('')", '' , '', 'L' )
        this.renderParams={ zona: datosSesion.nombre_zona, usuario: datosSesion.usuario }
    }

     htmlDynamic(){
        let htmlTemplate= {
             html: home
        }
    
        if ( !this.renderCount++ ){
            htmlTemplate.wrap= layout
        }

        return htmlTemplate
    }

    
}