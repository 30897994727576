// Module
var code = `<button class="btn btn-danger me-2" onclick="_('elimina')"><i class="bi bi-trash"></i></button>
<button class="btn btn-primary me-4" onclick="_('ficha')"><i class="bi bi-pencil"></i></button>
<button class="btn btn-secondary me-2" onclick="_.returns()"><i class="bi bi-x"></i> CANCELAR</button>
<button class="btn btn-primary" onclick="_('retornaSelected')"><i class="bi bi-check"></i> ACEPTAR</button>



`;
// Exports
export default code;