
/* global _ */

//import './manager.js'

/* datatypes MYSQL <-> JS

JS - 
	primitives: undefined, boolean, number, string, bigint, symbol
	structural: object, function

*/

/* todo. gestionar el null

	options.
		.readOnly - que no puede modificarse
		.textAlign: left, right
		.lookUp
		.wizard
		.wizardIcon
		.wizardCall
		.showCall
		.hideCall
*/

let controllers=[]
	, cfg={
		language: 'es-ES'
		, decimalSeparator: ','
		, currency: 'EUR'
		, dateOptions: {}
		, timeOptions: {}
		, datetimeOptions: {}
	}


controllers['default']= class {
	textAlign= 'left'
	importFromSQL( value ){ return value }
	exportToSQL( value ){ return value }
	printFormat( value ){ return typeof value === 'undefined' || value === null ? '' : value.toString() }
	editFormat( value ){ return this.printFormat( value ) }
	parseInput( value ){ return value }
	
}


controllers['string']=class extends controllers['default']{
}

controllers['blob']=class extends controllers['default']{

}

controllers['double']=class extends controllers['default']{
	textAlign= 'right'
	importFromSQL( value ){ return parseFloat( value ) }
	parseInput( value ){ 
		value.replace( /[^0-9]/g, function( c ){ return c == cfg.decimalSeparator ? '.' : '' } );
		return parseFloat ( value ); 
	}
}


controllers['currency']=class extends controllers['double']{
	printFormat( value ){
		if (typeof value === 'number' && !isNaN( value ) ){
			return value.toLocaleString( cfg.language, { style:'decimal', minimumFractionDigits: 2, maximumFractionDigits:2, useGrouping: true }) 
			// cfg.language = es-ES , que da problemas en chrome. usamos los alemanes
		} else return ''
	}
}
/* la configuración de los decimales para moneda es
n.toLocaleString('es-ES', {style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits:2, useGrouping: true})
	style: decimal, currency, percent. Si usamos currency tenemos que poner currency y currencydisplay. Si no trabajamos con varias monedas no merece la pena.
	maximumFractionDigits, minimumFractionDigits: 2
	useGrouping: true/false. Si queremos el separador de miles o no.
*/


controllers['percentage']=class extends controllers['default']{
	textAlign= 'right'

	importFromSQL( value ){ return parseFloat( value ) }
	printFormat( value ){
		if (typeof value === 'number' && !isNaN( value ) ) {
			return value.toLocaleString( cfg.language, { style:'percent' }) 
		} else return ''
	}
	parseInput( value ){ 
		value.replace( /[^0-9]/g, function( c ){ return c == cfg.decimalSeparator ? '.' : '' } );
		return parseFloat ( value ) / 100; 
	}
	
}


controllers['integer']=class extends controllers['default']{
	textAlign= 'right'
	importFromSQL( value ){ return parseInt( value ) }
	printFormat( value ){
		if (typeof value === 'number' && !isNaN( value ) ) return value 
		else return ''
	}
	parseInput( value ){ 
		return parseInt ( value ); 
	}
}


controllers['date']=class extends controllers['default']{
	textAlign= 'right'
	importFromSQL( value ){ return new Date( value ) }
	printFormat( value ){ 
		if ( value instanceof Date ){
			return value.toLocaleString( cfg.language, cfg.dateOptions ) 
		} else return ''
	}
	parseInput( value ){ 
		return value 
	}
}

controllers['time']=class extends controllers['default']{
	textAlign= 'right'
	importFromSQL( value ){ 
		if (value) return new Date( '1970/1/1 ' + value )
		else return null
	}
	printFormat( value ){ 
		if ( value instanceof Date ){
			return value.toLocaleString( cfg.language, cfg.timeOptions ) 
		} else return ''
	}
	parseInput( value ){ 
		return new Date(value)
	}
}


controllers['datetime']=class extends controllers['default']{
	textAlign= 'right'
	importFromSQL( value ){
		return  new Date(value.replace( /T/, ' ' ).replace( /Z/, '' ))
	}
	printFormat( value ){ 
		if ( value === null ) return ""
		else if ( value instanceof Date ){
			return value.toLocaleString( cfg.language, cfg.datetimeOptions ) 
		} else return value
	}
	parseInput( value ){ 
		return value 
	}
}

controllers['boolean']=class extends controllers['default']{
	textAlign= 'center'

	constructor( options ){
		this.valueCaptions= ['NO','SI']
		if ( options ){
			if (typeof options.textAlign !== 'undefined') this.textAlign= options.textAlign
			if (typeof options.valueCaptions !== 'undefined') this.valueCaptions= options.valueCaptions
		}
	}

	printFormat( value ){ 
		if ( value === null ) return ""
		else if ( value ) return this.valueCaptions[1]
		else return this.valueCaptions[0]
	}
}

export default controllers