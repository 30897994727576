import manager from '../platform/Manager'
import { UIComponent } from '../platform/UIComponent'
import html from './modals.html'
import {Dialog} from '../comun/modals/Dialog'
import {CustomDialog} from '../comun/modals/CustomDialog'
import sino from '../comun/modals/actions-si-no.html'
import actions from './actions.html'

/** Se trata de montar a mano todos los modals en main page y hacer las llamadas unificadas desde aquí
 * Podemos probar con el show error y con el upload documento.
 * Y para probar podemos copiar código con el select grupo que ya tenemos implementado en la agenda.
 * 
 */

/** viendo el contenido de agenda.html nos damos cuenta que hay renderizado necesario
 * para utilizar en un formulario habitual: el id del formulario que se utiliza con postfijos para cada campo, y comandos a llamar.
 * Para que esto sea global los estos campos no pueden ser específicos de una página sino globles para todos los formularios.
 * Podemos definir un identicador: _Mnn para todos los modales donde la M significa que es modal y nn es el número de pagina modal a dos cifras
 * 100 como maximo.
 * 
 * 00 - show error
 * 01 - upload documento
 * 02 - select grupo
 * ...
 * 
 * Otra cosa . La cabecera y el pie será el mismo para todos, lo único que cambia es el contenido.
 * Hay que valorar que se pase al iniciar o que se guarde en el inventario de recursos del manager.
 */

export default class extends UIComponent{
    htmlTemplate= html

    constructor(id){
        super(id)
        this.dialog= this.addComponent(Dialog, 2)
        this.dialogOK= this.addComponent(CustomDialog, 'Dialogo OK', '<p>{{mensaje}}<p>')
        this.dialogSINO= this.addComponent(CustomDialog, 'Dialogo SI-NO', '<p>{{mensaje}}<p>')
        this.dialogSINO.setActions( sino )
        this.dialogAcciones= this.addComponent(CustomDialog, 'Dialogo acciones diferenciadas','<p>{{mensaje}}<p>')
        this.dialogAcciones.setActions( actions )
    }


    test(){
        this.dialog.show( {titulo:'Dialog box', body:'Este es el caballo que viene de Bonanza'} ) //param, options
    }

    customOK(){
        this.dialogOK.show( {mensaje: 'HOla custom ok'})
    }
    customSINO(){
        this.dialogSINO.show( {mensaje: 'HOla custom si no'})
    }

    customAcciones(){
        this.dialogAcciones.show( {mensaje: 'HOla custom acciones'})
    }

    accion( a ){ console.log(a) }


    showError(){

    }

    upload( element ){
        if ( element.files.length ){
            let file= element.files[0]
            file.text().then( ( content ) => { 
                manager.request('/DB/upload/run/agenda/123/certificado', {
                    content: content
                    , filename: file.name
                    , filetype: file.type
                }).done( response => { 
                    console.log(response)
                })
            })
        }
    }

    modalDefault(){
        if ( this.dialog.isShown() ) this.dialog.hide()
        else if ( this.dialogOK.isShown() ) this.dialogOK.hide()
        else if ( this.dialogSINO.isShown() ) this.dialogSINO.hide()
        else if ( this.dialogAcciones.isShown() ) this.dialogAcciones.hide()
        console.log( 'modal-default' )
    }

}