import manager from '../../platform/Manager'
import { Lista } from '../../platform/pages/Lista'
import actionsHtml from '../../platform/pages/lista-actions.html'

export class AgendaLst extends Lista{

    constructor(id){
        super( id, 'Agenda' )
        this.renderParams= { actions: actionsHtml}

    }

    ficha(){
        manager.open('AgendaFicha', this.list.getSelectedKeys()[0] )
    }

    elimina(){
        if ( this.list.getSelectedKeys()[0] ){
            let self= this
            manager.request('/DB/procedure/run/agenda__elimina', this.list.getSelectedKeys()[0] )
            .done( () => { this.list.unselect()} )
            .always( () => {self.recordset.refresh()} )
        }
    }

}
 