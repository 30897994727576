import manager from '../../platform/Manager'
import { UIComponent } from '../../platform/UIComponent'
import { RecordSet } from '../../platform/db/RecordSet'
import { List } from '../../platform/list/List'
import dom from  '../../platform/libs/Dom'
import html from './maquinas.html'
import html_sat from './maquinas-sat.html'

export class MaquinasPorSalon extends UIComponent{

    constructor(id, ubicacion){
        super( id )
        this.renderParams={id: id}
        this.no_copias= 2
        switch(manager.getMainPage().datosSesion.perfil){
            case 'SAT': this.renderParams[ 'acciones' ]= html_sat; break
        }
        this.htmlTemplate= html
        this.ubicacion= ubicacion
        this.recordset= this.addComponent( RecordSet )
        this.list= this.addUIComponent( List, this.recordset, {selectType: 'none'} )
        setTimeout( this.openQuery.bind(this), 0 )
        manager.addListener( 'show', this.id,  'onShow')

    }

    onShow(){ this.recordset.refresh() }

    openQuery(){ 
        let self= this
        this.recordset.setQuery( 'maquinas_por_salon', { ubicacion: this.ubicacion } )  
        manager.request('DB/procedure/run/ubicacion__nombre', [ this.ubicacion ])
        .done( (response) => {
            dom.createTextNodes([{id: self.id + '_ubicacion' , text: response[0][0].nombre}])
        })  


    } 

    imprimeEtiquetas(){
        let self = this
        manager.request('DB/procedure/run/etiqueta__imprime_salon', [ this.ubicacion, this.no_copias])
        .done((response) =>{
            console.log(  response )
            manager.request('DB/query_export/run/Maquinas_por_salon', { ubicacion: self.ubicacion } )
            .done(response => { _.app.exportCsv( response.csvContent ) })
        })
/*        
    */
    }
   
    first() { this.recordset.seekPage(0) }
    previous(){ this.recordset.seekNextPage(-1) }
    next(){ this.recordset.seekNextPage(1) }
    last(){ }


}