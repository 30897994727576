import manager from '../platform/Manager'
import { UIComponent } from '../platform/UIComponent'
import html from './genericupdatetest.html'

export default class extends UIComponent{
    htmlTemplate= html


    test( campo ){
        switch (campo){
            case 'email': manager.request( `DB/update/run/usuario`, [['dmelian'], 'email', 'dmelian@pamaehijos.com'] ); break
            case 'telefono': manager.request( `DB/update/run/usuario`, [['dmelian'], 'telefono', '619101689'] ); break
        }
        
    }

}