import manager from '../platform/Manager'
import {UIComponent} from '../platform/UIComponent'
import layout from './layout.html'
import home from './home.html'
import modals from '../comun/modals/modals.html'

export class Documentacion extends UIComponent{
    renderCount= 0

    constructor(id, datosSesion){
        super(id)
        this.datosSesion= datosSesion
        this.menu= this.addUIComponent( manager.getClass( 'Menu' ) )
        this.menu.setAlias('menu')
        this.menu.addItem( 
            "_.open('CertificadoLst')"
            , 'NUEVOS CERTIFICADOS' 
            , 'Agendas confirmadas por operaciones pendientes de recibir el certificado del fabricante.'
            , 'C' 
        )
        this.menu.addItem( 
            "_.open('AutorizacionLst')"
            , 'AUTORIZACIONES PENDIENTES' 
            , 'Agendas registradas pendientes de autorizar en La Administración.'
            , 'A' 
        )
//        this.menu.addItem( "_.open('')", '' , '', 'L' )
        this.renderParams={ usuario: datosSesion.usuario, modals: modals }
        manager.addListener( 'show', this.id,  'onShow')
        this.onShow()
    }

    onShow(){
        manager.request('DB/query/run/documentacion_home', [], {multiquery: true} )
        .done( this.updateBadges.bind(this) )
    }

    updateBadges( response ){
        let self= this
        response.result.map(( rec, ix ) => {
            self.menu.setBadged( ix, rec[0].count == 0 ? '' : rec[0].count, 'primary' )
        })
    }

    htmlDynamic(){
        let htmlTemplate= {
             html: home
        }
    
        if ( !this.renderCount++ ){
            htmlTemplate.wrap= layout
        }

        return htmlTemplate
    }

    
}