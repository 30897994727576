/* global _ */
/*
    componente


    html - template


    render.
    1 ) genera el html de todos los childs en un objeto. 
    2 ) lanza el render del componente son su propia plantilla.
        Ahora el render puede ser un registro con otros renders.

    cuando se crea el subcomponente se le asigna.
        punto de montaje. (cuando el html ya está montado.)
        a) normal. Aparece como identificador en la plantilla html del componente principal
        2) sin ui. Simplemente no aparece como identificador en la plantilla, pero también lo usamos para no tener que llamar a la función html y devuelva vacío.
        3) warp. En lugar de usar la plantilla del componente principal usamos la plantilla del componente warp. 
            Dentro de la plantilla del warp podrá haber un {{{self}}} o {{{html}}} o {{{main}}}. Donde se sustituye el contenido principal que genera el componente.
            Si no queremos ponerlo simplemente o el componente warp no lo define, o la plantilla la pone a null.
        4) warpped. Es generado en el registro, pero no se carga en el html del componente principal (simplemente con no ponerlo en la plantilla tenemos.)
        
        ¿ Pueden haber varios elementos warp ? como jerarquizamos en este caso, por orden de creación ?
        ¿ Es necesario esto ? ¿ Usamos la tecnología de una página principal mantenta los puntos de anclaje ?
        Los objetos están identificados por su id en childs. Usamos este orden para generar el html

        Se generan los html en el mismo orden en que se crean.
        Desde que nos encontremos un componente "warp", generamos el contenido con la plantilla del componente principal antes de generar el warp.
        a todos los componentes warp le pasamos el registro de todo lo que estamos generando.
        El último responde como el componente principal.


*** TODO.


*/

import manager from './Manager.js'
import {EObject} from './EventObject'

export class Component extends EObject{
    components=[]

    addComponent( classDef, ...params ){ 
        if ( typeof classDef === 'string' ) classDef= manager.getClass( classDef )
        if ( classDef ){
            let component= this.createObject( classDef, ...params )
            component.alias= classDef.name.toLowerCase() 
            this.components.push( component )
            return component
        
        } else return false
    }

    setAlias( alias ){ this.alias= alias }
    

}