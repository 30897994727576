import manager from '../../platform/Manager'
import {Ficha} from '../../platform/pages/Ficha'
import {ListaEditable} from '../../platform/pages/Listaeditable'
import {Form} from '../../platform/pages/Listaeditable_Form'
import html_juego from './ficha-juego.html'
import html from './ficha.html'
import html_nuevo from './ficha-nueva.html'

class FormJuego extends Form{
    htmlTemplate= html_juego
}


export class Modelo extends Ficha{

    constructor (id, modelo, valoresIniciales){
        let config={
            procView: 'modelo__ficha'
            , updateTable: 'modelo'
            , htmlCreate: html_nuevo
            , htmlEdit: html
            , valida: ['fabricante']
        }
        if ( valoresIniciales ) config['defValues']= valoresIniciales
        super( id, config, modelo )
    }

    crear(){
        let self= this
        manager.request('DB/procedure/run/modelo__crea', [this.getValor('fabricante')])
        .done( response => {
            self.mode= 'edit'
            self.receiveDataAndRefresh( response )
        })
    }

    getClave(){ return this.data.modelo }

    validate( ){
        let validando = arguments[0]
            , self= this
        if (typeof validando == 'string') validando= {id: validando}
        if (typeof validando.valor == 'undefined'){
            validando.valor= this.getValor( validando.id )
        }
        switch( validando.id ){
            case 'fabricante':

                if ( validando.valor == '') validando.valor= null
                if ( this.mode == 'edit' ){
                    if ( validando.valor !== this.data[validando.id] ){
                        manager.request('DB/procedure/run/modelo__valida_' + validando.id, [this.data.modelo, validando.valor] )
                        .done( response => {
                            self.setValor( validando.id, response[0][0][validando.id])
                            self.setValor( validando.id + '_nombre', response[0][0].nombre)
                            self.data[validando.id]= response[0][0][validando.id]
                            self.data[validando.id +'_nombre']= response[0][0].nombre
                            if ( typeof response['update_fields'] !== 'undefined' ){
                                for ( let fd in response['update_fields'][0] ) if ( fd !== '_id' ){ 
                                    self.data[fd]= response['update_fields'][0][fd]
                                    self.setValor( fd, response['update_fields'][0][fd] )
                                }
                            }
                        })
                    }
                } else {
                    self.setValor( validando.id, validando.valor)
                    manager.request('DB/procedure/run/'+ validando.id +'__valida', [ validando.valor, '@void'] )
                    .done( response => {
                        self.setValor( validando.id, response[0][0][validando.id])
                        self.setValor( validando.id + '_nombre', response[0][0].nombre)
                    })
                }
                break;

            default: super.validate( validando ) 
        }
    }


    lookup( ){
        let query={}
        this.lookingUp= arguments[0]
        query.id= typeof this.lookingUp.query !== 'undefined' ? this.lookingUp.query : this.lookingUp.id
        switch (this.lookingUp.id ){
            case 'juego': case 'mueble':
                query.params={fabricante: this.data.fabricante}
                manager.open( ListaEditable , [ query, FormJuego ])
                break;

            default:
                manager.open('Lookup', query )
        }
    }


}
