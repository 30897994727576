import manager from '../../platform/Manager'
import {Ficha} from '../../platform/pages/Ficha'
import html from './ficha.html'
import html_nuevo from './ficha-nueva.html'
import {Grupo} from './Grupo'
import {Maquina} from './Maquina'

export class Agenda extends Ficha{

    constructor (id, agenda, valoresIniciales){
        let config={
            procView: 'agenda__ficha'
            , updateTable: { 'agenda': 'agenda', 'grupo': 'agenda__grupo', 'maquina': 'agenda__maquina' }
            , htmlCreate: html_nuevo
            , htmlEdit: html
            , valida: { 'agenda': ['fabricante']}
        }
        if ( valoresIniciales ) config['defValues']= valoresIniciales
        super( id, config, agenda )
        this.grupo= this.addUIComponent( Grupo, this )
        this.maquina= this.addUIComponent( Maquina, this )

    }

    crear(){
        let self= this
        manager.request('DB/procedure/run/agenda__crea', [this.getValor({set:'agenda', row: 0, id:'fabricante'})])
        .done( response => {
            self.mode= 'edit'
            self.receiveDataAndRefresh( response )
        })
    }

    getClave( set, row ){
        switch ( set ){
            case 'agenda': return [ this.data.agenda[row].agenda ]
            case 'grupo': return [ this.data.grupo[row].grupo ]
            case 'maquina': return [ this.data.agenda[0].agenda, this.data.maquina[row].no_puesto_agenda ]
        }
    }


    /** 
     * Por defecto al recibir el dato se toma el primer registro de la primera query.
     * Si se quiere cargar con otra funcionalidad habría que sobrecargar este método.
     */
    receiveData( response ){
        this.data= response
    }

    /**
     * MULTI NIVEL
     * --------------------------------------------------------------------
     * En este formulario no tenemos los datos planos de un solo registro, Tenemos datos de varios niveles con varias filas por nivel
     * El id pasa de ser un identificador alfa-numérico a ser un objeto de tres niveles set, row, id
     */

    getRenderParams( ){
        let params= { idx: [this.id, 'agenda', 0 ].join('_'), set:'"agenda"', row:0 }
        if ( this.data && this.data.agenda ) {
            Object.assign ( params, this.data.agenda[0] )
        }
        return params
    }

    getValor( id ){
        let element
        element= document.getElementById( [this.id, id.set, id.row, id.id ].join('_') )
        switch ( element.nodeName ){
            case 'SELECT':
                if ( element.selectedIndex == -1 ) return null //''
                else return element.selectedIndex + 1  //this.selects[ id ][  ] // Los enums comienzan por 1
            default: 
                return typeof element.value == 'string' ? element.value.trim() : element.value
        }
    }

    setValor( id, valor ){
        let element
        element= document.getElementById( [this.id, id.set, id.row, id.id ].join('_') )
        switch ( element.nodeName ){
            case 'SELECT':
                if (typeof valor == 'number') element.value= valor - 1
                else element.value= this.selects[id].indexOf(valor) 
                break
            default: element.value= valor
        }
        
    }

    /**
     * La configuración de las tablas y las claves se tienen en la configuración 
     * Pero además tienen un getClave que te da la clave específica para una fila.
     * Vamos a renombrarlo como connectos en lugar de config. 
     * Otra clase con la que podremos seguir creciendo.
     */

    validate( validando ){
        let self= this
        if (typeof validando.valor == 'undefined'){
            validando.valor= this.getValor( validando )
        }

        if ( validando.valor == '') validando.valor= null
        if ( this.mode == 'edit' ) {
            if ( validando.valor !== this.data[validando.set][validando.row][validando.id] ){
                manager.request('DB/update/run/'+ this.config.updateTable[validando.set], [ this.getClave(validando.set, validando.row) , validando.id, validando.valor] ) // update la tabla correcta.
                .done( response => {
                    self.setValor(validando, response[0][0][validando.id])
                    self.data[validando.set][validando.row][validando.id]= response[0][0][validando.id]
                    if ( typeof response[0][0].nombre !== 'undefined'){
                        validando.id= validando.id + '_nombre'
                        this.setValor( validando, response[0][0].nombre)
                        this.data[validando.set][validando.row][validando.id + '_nombre']= response[0][0].nombre
                    }
                })
            }
        } else { // mode: create
            if ( this.config.valida[validando.set].includes(validando.id)  ){
                self.setValor( validando, validando.valor)
                manager.request('DB/procedure/run/'+ validando.id +'__valida', [ validando.valor, '@void'] ) //el segundo parametro es necesario, es de salida y se usa llamadas directas entre procedimientos almacenados
                .done( response => {
                    self.setValor( validando, response[0][0][validando.id])
                    validando.id= validando.id + '_nombre'
                    self.setValor( validando, response[0][0].nombre)
                })
            } else self.setValor( validando, validando.valor )
        }

    }
    

    lookup( lookingUp ){
        let query

        this.lookingUp= lookingUp
        switch( this.lookingUp.id ){
            case 'agrega_grupo':
                let main= manager.getMainPage()
                manager.open('Lookup', {id:'grupo', params:{empresa: main.datosSesion.empresa ,fabricante: this.data.agenda[0].fabricante}} )
                break
            
            case 'modelo': case 'mueble': case 'juego':
                manager.open('Lookup', { id: this.lookingUp.id, params: {fabricante: this.data.agenda[0].fabricante} } )
                break

            default: 
            query= typeof this.lookingUp.query !== 'undefined' ? this.lookingUp.query : this.lookingUp.id
            manager.open('Lookup', this.lookingUp.id )
        }
    }


    /**
     * RETORNO
     * Si esta activo un lookup validamos el resultado.
     */

    ret( ret ){
        if ( this.lookingUp ){
            if ( this.lookingUp.id == 'agrega_grupo' ) this.agregaGrupo( ret )
            else this.validate( Object.assign( {valor: ret}, this.lookingUp ) )
            this.lookingUp= false
        }
    }





/*********************************************** 
 * Lo nuevo
 */

    confirma(){
        manager.request('/DB/procedure/run/agenda__confirma', [ this.data.agenda[0].agenda ])
        .done( () => {manager.returns()} )
    }


    /**
     * LOS COMANDOS
     * ------------------------------------------
     */

    agregaGrupo( grupo ){
        manager.request(
            'DB/procedure/run/agenda__agrega_grupo/', [ this.data.agenda[0].agenda, grupo ]
        )
        .done( this.receiveDataAndRefresh.bind( this ) )

    }

    quitaGrupo( grupo ){
        manager.request(
            'DB/procedure/run/agenda__quita_grupo/', [ this.data.agenda[0].agenda, grupo ]
        )
        .done( this.receiveDataAndRefresh.bind( this ) )
    }


    agregaMaquina(){
        manager.request(
            'DB/procedure/run/agenda__agrega_maquina/', [ this.data.agenda[0].agenda ]
        )
        .done( this.receiveDataAndRefresh.bind( this ) )

    }

    quitaMaquina( no_puesto ){
        manager.request(
            'DB/procedure/run/agenda__quita_maquina/', [ this.data.agenda[0].agenda, no_puesto ]
        )
        .done( this.receiveDataAndRefresh.bind( this ) )
    }

    mueveMaquina( no_puesto, sentido ){
        manager.request(
            'DB/procedure/run/agenda__mueve_maquina/', [ this.data.agenda[0].agenda, no_puesto, sentido ]
        )
        .done( this.receiveDataAndRefresh.bind( this ) )

    }


}