import manager from '../platform/Manager'
import {UIComponent} from '../platform/UIComponent'

export default class extends UIComponent{
    htmlTemplate='<div class="container"><h4>INTEGRACION JACKPOT SYSTEMS</h4>{{{menu}}}</div>'

    constructor(id){
        super(id)
        this.menu= this.addUIComponent( manager.getClass( 'Menu' ) )
        this.menu.setAlias('menu')
        let tecla= 1

        this.menu.addItem( "_.open('JS_ApiTest')", 'JACKPOT SYSTEMS API' , 'Prueba el API de Jackpot Systems.', tecla.toString() ); tecla++

        
//        this.menu.addItem( "_.open('')", titulo , descripcion, tecla )
    }
   
}