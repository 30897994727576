import manager from '../../platform/Manager'
import { UIComponent } from '../../platform/UIComponent'
import { RecordSet } from '../../platform/dbapi/RecordSet'
import { List } from '../../platform/list/List'
import default_html from '../../platform/pages/lista.html'

export class ListaApi extends UIComponent{

    apiQuery= {} // queryName, apiNamespace

    constructor(id, url, html){
        super( id )
        if (typeof html == 'undefined') html= default_html
        this.url= url
        this.recordset= this.addComponent( RecordSet )
        this.list= this.addUIComponent( List, this.recordset )
        setTimeout( this.openQuery.bind(this), 0 )
        manager.addListener( 'show', this.id,  'onShow')
        this.renderParams={id: id}

    }

    onShow(){ this.recordset.refresh() }

    openQuery(){ this.recordset.setUrl( this.url )  }

    next(){ this.recordset.seekNextPage(1) }

    previous(){ this.recordset.seekNextPage(-1) }

    cancel(){ 
        let selected= this.list.getSelectedKeys()
        if ( selected.length > 0 ) this.list.unselect()
        else manager.returns()
     }


}


 