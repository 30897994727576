// Module
var code = `<div class="container">
    <h4>INSERTAR CLIENTE COMO UBICACION</h4>
    <form id="{{id}}_form" class="bg-secondary-subtle p-3">

        <div class="row pb-3">

            <div class="col-8">
                <label for="{{id}}_cod" class="form-label">Cód. Cliente Gestimaq</label>
                <input  class="form-control" id="{{id}}_cod"  />
            </div>
            
        </div>

        <div class="row p-3">

            <div class="col-8 form-check">
                <input  class="form-check-input" type="checkbox" id="{{id}}_valida_empresa" checked />
                <label for="{{id}}_valida_empresa" class="form-check-label">Valida también la empresa desde gestimaq</label>
            </div>
            
        </div>

    </form>

    <nav class="d-flex pt-3">
        <div class="ms-auto">
            <button class="btn btn-primary" onclick="_('inserta')" ><i class="bi bi-plus"></i> INSERTAR</button>
        </div>
    </nav>

</div>
    



`;
// Exports
export default code;