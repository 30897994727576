import {UIComponent} from '../../platform/UIComponent'
import htmltpt from './maquina.html'

export default class extends UIComponent{
    htmlTemplate={ html: htmltpt }

    constructor( id, maquina){
        super(id)
        this.renderParams= maquina
    }
}
