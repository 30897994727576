import manager from '../platform/Manager'
import {UIComponent} from '../platform/UIComponent'

export class Maestros extends UIComponent{
    renderCount= 0
    htmlTemplate="<div class='container'><h4>MAESTROS</h4>{{{menu}}}</div>"

    constructor(id ){
        super(id)
        this.menu= this.addUIComponent( manager.getClass( 'Menu' ) )
        this.menu.setAlias('menu')
        this.menu.addItem( "_.open('FabricanteLst')", 'FABRICANTES' , 'Lista de Agendas pendientes de registrar.', 'A' )
        this.menu.addItem( "_.open('ModeloLst')", 'MODELOS' , 'Listado de modelos por fabricante / marca.', 'M' )
        this.menu.addItem( "_.open('MuebleLst')", 'MUEBLES' , 'Listado de muebles por fabricante.', 'B' )
        this.menu.addItem( "_.open('JuegoLst')", 'JUEGOS' , 'Listado de juegos por fabricante.', 'J' )
        this.menu.addItem( "_.open('UbicacionLst')", 'UBICACIONES' , 'Listado de ubicaciones.', 'U' )
        this.menu.addItem( "_.open('EmpresaLst')", 'EMPRESAS' , 'Listado de empresas.', 'E' )
        this.menu.addItem( "_.open('ZonaLst')", 'ZONAS' , 'Listado de zonas.', 'Z' )
        this.menu.addItem( "_.open('')", '' , '.', '' )
    }

}