import {UIComponent} from '../UIComponent'

export class Form extends UIComponent{

    constructor(id, html){
        super(id)
        this.htmlTemplate= html
    }

    getValor( id ){
        let element
        element= document.getElementById( this.id + '_' + id )
        switch ( element.nodeName ){
            case 'SELECT':
                if ( element.selectedIndex == -1 ) return ''
                else return this.selects[ id ][ element.selectedIndex ]
            default: 
                return typeof element.value == 'string' ? element.value.trim() : element.value
        }
    }

    setValor( id, valor ){
        let element
        element= document.getElementById( this.id + '_' + id )
        switch ( element.nodeName ){
            case 'SELECT': element.value= this.selects[id].indexOf(valor); break
            default: element.value= valor
        }
        
    }



}