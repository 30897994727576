import { Lista } from '../../platform/pages/Lista'
import actions_html from '../../platform/pages/lista-actions.html'
import html from './lista.html'
import htmlFiltro from './lista-filtro.html'
import {Filtro} from '../../platform/pages/Filtro'
import manager from '../../platform/Manager'

export class MuebleLst extends Lista{

    constructor(id, fabricante){
        super( id, 'Mueble', html )
        this.renderParams.actions= actions_html
        this.filtro= this.addUIComponent( Filtro, 'mueble', ['fabricante'], {fabricante: fabricante}, htmlFiltro, ['fabricante'] )
        this.filtro.addListener( 'change', id, 'onFilterChange')
    }

    onFilterChange( filter ){
        console.log('CAMBIO DE FILTRO RECIBIDO')
        console.log( filter )
        this.query.params= filter
        this.recordset.setQuery( this.query)
    }

    //ret( ret ){  this.filtro.ret( ret ) } //no me gusta. Es un open dado desde el filtro. Habría que ver como hacerlo mejor.
    /**
     * En lugar de usar _.open en el lookup de Filtro. usar un _.opendelegado( filtro ) que cuando retorna sabe aquien retorna. 
     * En (UICOmponent) ????? No, en page que no lo usamos.
     * TAMBIEN SE PUEDE GESTIONAR POR EL MANAGER.RETURN. DELEGATED OPEN AND DELEGATED RETURN, EN OPEN HAY UN 3ER PARAMETRO RET....
     */

    ficha(){
        manager.open( this.cardPage, this.list.getSelectedKeys()[0], {fabricante: this.filtro.data.fabricante} ) // añadirle el filtro de fabricante
    }

    
}
 