// Module
var code = `<div class="container bg-secondary-subtle">
    <form id="{{id}}_form">

        <div class="row mb-3">
            <div class="col-3">
                <label for="{{id}}_agenda" class="form-label">Agenda</label>
                <input class="form-control" id="{{id}}_agenda" readonly value="{{agenda}}">
            </div>
            <div class="col-9">
                <label for="{{id}}_usuario" class="form-label">Creada por</label>
                <input class="form-control" id="{{id}}_usuario" readonly value="{{usuario}}">
            </div>
        </div>
    
        <div class="row mb-3">
            <div class="col-3">
                <label for="{{id}}_fabricante" class="form-label">Fabricante</label>
                <input class="form-control" id="{{id}}_fabricante" readonly value="{{fabricante}}">
            </div>
            <div class="col-9">
                <label for="{{id}}_fabricante_nombre" class="form-label">Nombre fabricante</label>
                <input class="form-control" id="{{id}}_fabricante_nombre" readonly value="{{fabricante_nombre}}">
            </div>
        </div>
    

        <div class="row pb-3">
            <label for="{{id}}_certificado" class="form-label">Certificado del fabricante</label>
            <div class="col-3">
                <div class="input-group">
                    <input class="form-control" id="{{id}}_certificado" value="{{certificado}}">
                    <button class="btn btn-secondary" type="button" onclick="_('upload', 'certificado' )" ><i class="bi bi-three-dots"></i></button>
                </div>
            </div>
            <div class="col-9 pt-2">
                <a id="{{id}}_certificado_nombre" href="#" onclick="_('download', 'certificado')" >{{certificado_nombre}}</a>
            </div>
        </div>
    
    </form>
</div>
    `;
// Exports
export default code;