import manager from '../platform/Manager'

import './certificado/_index'

// HTML resources
//import resource from './resource.html'; manager.addHtmlResource( 'resource', resource )

// Page classes
//import page from './page'; manager.addPageClass( 'page', page )
import {Documentacion} from './Documentacion'; manager.addPageClass( 'Documentacion', Documentacion )
