// Module
var code = `<div class="container bg-secondary-subtle">
    <form id="{{id}}_form">

        <div class="row mb-3">
            <div class="col-3">
                <label for="{{id}}_agenda" class="form-label">Agenda</label>
                <input class="form-control" id="{{id}}_agenda" readonly value="{{agenda}}">
            </div>
            <div class="col-9">
                <label for="{{id}}_usuario" class="form-label">Creada por</label>
                <input class="form-control" id="{{id}}_usuario" readonly value="{{usuario}}">
            </div>
        </div>
    
        <div class="row mb-3">
            <div class="col-3">
                <label for="{{id}}_fabricante" class="form-label">Fabricante</label>
                <input class="form-control" id="{{id}}_fabricante" readonly value="{{fabricante}}">
            </div>
            <div class="col-9">
                <label for="{{id}}_fabricante_nombre" class="form-label">Nombre fabricante</label>
                <input class="form-control" id="{{id}}_fabricante_nombre" readonly value="{{fabricante_nombre}}">
            </div>
        </div>
    
        <div class="mb-3">
            <label for="{{id}}_descripcion" class="form-label">Descripción</label>
            <textarea class="form-control" id="{{id}}_descripcion" readonly rows="3">{{descripcion}}</textarea>
        </div>
    
        <div class="row pb-3">
            <label for="{{id}}_certificado" class="form-label">Certificado del fabricante</label>
            <div class="col-3">
                <div class="input-group">
                    <input class="form-control" id="{{id}}_certificado" value="{{certificado}}">
                    <button class="btn btn-secondary" type="button" onclick="_('upload', 'certificado' )" ><i class="bi bi-three-dots"></i></button>
                </div>
            </div>
            <div class="col-9 pt-2">
                <a id="{{id}}_certificado_nombre" href="#" onclick="_('download', 'certificado')" >{{certificado_nombre}}</a>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-3">
                <label for="{{id}}_modelo" class="form-label">Modelo</label>
                <div class="input-group">
                    <input class="form-control" id="{{id}}_modelo" onblur="_('validate', {id:'modelo', puesto: 0})" value="{{modelo}}">
                    <button class="btn btn-secondary" type="button" onclick="_('lookup', {id:'modelo', puesto: 0} )" ><i class="bi bi-three-dots"></i></button>
                </div>
            </div>
            <div class="col-9">
                <label for="{{id}}_modelo_nombre" class="form-label">Nombre modelo</label>
                <input class="form-control" id="{{id}}_modelo_nombre" readonly value="{{modelo_nombre}}">
            </div>
        </div>

        
        <div class="row mb-3">
            <div class="col">
                <label for="{{id}}_tipo" class="form-label">Tipo de máquina</label>
                <input class="form-control" id="{{id}}_tipo" value="{{tipo}}">
            </div>


            <div class="col">
                <label for="{{id}}_registro_modelo" class="form-label">Núm. registro modelo</label>
                <input class="form-control" id="{{id}}_registro_modelo" value="{{registro_modelo}}">
            </div>
        </div>

        <div class="row mb-3">
            <div class="col">
                <label for="{{id}}_noserie" class="form-label">Núm. serie máquina</label>
                <input class="form-control" id="{{id}}_noserie" value="{{noserie}}">
            </div>


            <div class="col">
                <label for="{{id}}_fec_fabricacion" class="form-label">Fecha fabricación</label>
                <input class="form-control" id="{{id}}_fec_fabricacion" value="{{fec_fabricacion}}">
            </div>
        </div>


        <div class="d-flex"> 
            <button class="btn btn-secondary ms-auto me-2" type='button' onclick="_.returns()">CANCELAR</button>
            <button type='button' class="btn btn-primary" onclick="_('crear')">REGISTRAR</button>
        </div>

    </form>
</div>
    `;
// Exports
export default code;