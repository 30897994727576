import html from './ficha.html'
import { Ficha } from '../../platform/pages/Ficha'

export class Certificado extends Ficha{
    htmlTemplate= html

    constructor(id, agenda){
        super(id, {
            procView: 'agenda__ficha_certificado'
            , updateTable: 'agenda'

        }, agenda )

    }

    getClave() { return this.data.agenda }

}