import manager from '../../platform/Manager'
import { UIComponent } from '../../platform/UIComponent'
import { RecordSet } from '../../platform/db/RecordSet'
import { List } from '../../platform/list/List'
import html from './lookup.html'

export default class extends UIComponent{

    constructor(id, query, title){
        super( id )
        if( typeof title == 'undefined' ) title= query
        this.renderParams={title: title}
        this.htmlTemplate= html
        this.recordset= this.addComponent( RecordSet )
        this.list= this.addUIComponent( List, this.recordset )
        this.queryname= query + '_lookup'
        setTimeout( this.openQuery.bind(this), 0 )
        manager.addListener( 'show', this.id,  'onShow')

    }

    onShow(){ this.recordset.refresh() }

    openQuery(){ this.recordset.setQuery( this.queryname )  } 

    next(){ this.recordset.seekNextPage(1) }

    previous(){ this.recordset.seekNextPage(-1) }

    retornaSelected(){
        let select= this.list.getSelectedKeys()
        if( select.length == 1 ) _.returns( select[0] )
        else _.returns( false )
    }

}