import {UIComponent} from '../../platform/UIComponent'
import html from './agenda.html'
import htmlGrupo from './grupo.html'

export default class extends UIComponent{


    constructor(id, agenda){
        super(id)
        this.agenda= agenda
        this.renderParams=  Object.assign({id: id}, agenda)
    }


    agregaGrupo(){
        let grupoInput= document.getElementById( this.id + '_AG_NoGrupo' )
            , grupo= grupoInput.value
        console.log( 'Grupo a agregar: ' + grupo )
        grupoInput.value= ''
        _( 'agregaGrupo', grupo )
    }


    htmlDynamic(){
        let grupos= ''
        this.agenda.grupos.map( grup =>{ grupos+= _.render( htmlGrupo, grup ) })
        this.renderParams.grupos= grupos
        return html
    }

  
}
