import manager from '../../platform/Manager'
import { UIComponent } from '../../platform/UIComponent'
import { RecordSet } from '../../platform/db/RecordSet'
import { List } from '../../platform/list/List'
import html from './lista.html'

export default class extends UIComponent{

    constructor(id){
        super( id )
        this.htmlTemplate= html
        this.recordset= this.addComponent( RecordSet )
        this.list= this.addUIComponent( List, this.recordset )
        setTimeout( this.openQuery.bind(this), 0 )
        manager.addListener( 'show', this.id,  'onShow')

    }

    onShow(){
        this.recordset.refresh()
    }

    openQuery(){ this.recordset.setQuery( 'agendas' )  } //query, params, options, keys

    next(){ this.recordset.seekNextPage(1) }

    previous(){ this.recordset.seekNextPage(-1) }

    ficha(){
        manager.open('AgendaFicha', this.list.getSelectedKeys()[0] )
    }

    elimina(){
        let self= this
        manager.request('/DB/procedure/run/agenda__elimina', this.list.getSelectedKeys()[0] )
        .always( () => {self.recordset.refresh()} )
    }

}
 