import {ListaApi} from '../../platform/pages/ListaApi'

export class Clientes extends ListaApi{

    constructor(id){
        super( id, 'IS/APICall/query/clientes' )
    }

}


 