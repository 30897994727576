import manager from '../platform/Manager'
import {UIComponent} from '../platform/UIComponent'

export default class extends UIComponent{
    htmlTemplate='<div class="container"><h4>INTEGRACION INFRASPEAK</h4>{{{menu}}}</div>'

    constructor(id){
        super(id)
        this.menu= this.addUIComponent( manager.getClass( 'Menu' ) )
        this.menu.setAlias('menu')
        let tecla= 1

        this.menu.addItem( "_.open('IS_ApiTest')", 'PRUEBA EL API' , 'Prueba el API de Infraspeak.', tecla.toString() ); tecla++
        this.menu.addItem( "_.open('IS_Maestros')", 'MAESTROS' , 'Prueba el API de Infraspeak.', 'M' )

        
//        this.menu.addItem( "_.open('')", titulo , descripcion, tecla )
    }
   
}