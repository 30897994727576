import {ListaApi} from '../../platform/pages/ListaApi'

export class Query extends ListaApi{

    constructor(id, path){
        super( id, path )
    }

}


 