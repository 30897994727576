/* global _, $ */

//import './manager.js'

/*
La clase List.
Para mostrar en el formulario la lista seleccionable asociada a una query

¿ Como funciona este control ?.
1) El formulario que lo utiliza en el onLoad se instancia un objeto Lista 
en cuyo constructor se les pasa el nodo dom, la query y opciones.

	- Se lanza la query donde se recupera la definición de la misma y sus datos.
	- Con los datos de la query se crea la plantilla y se cargan los resultados.
	( En la carga de la plantilla, se crean los eventos de la misma ).
		- Seleción y deselección, simple y multiple
		- Paginación. First, last, next, prev. (y mostrar cuantas páginas hay)
		- Mostrar, editar los filtros.
		- Mostrar, ocultar y cambio del orden de las columnas,
		- Una opción de buscar.
		- Seleccionar el orden de muestra de los registros.
		- Exportar a excel.
		- (imprimir en pdf)
	)

Este componente solo genera el html de los datos como una tabla.
Hay que ver que clases se usan para table, thead, tbody, tr, th, td ....

clases para los botones
	invisible - para ocultarlos (disabled)

Además de los datos tiene una serie de botones y enlaces de actualización
botones (a poner donde se quiera en el formulario)
	<id>_previous			onclick: _( '<id>', 'previous')
	<id>_pageInfo 				spans ids: <id>_page, <id>_pages
	<id>_next				onclick: _( '<id>', 'next')
	<id>_select				spans ids: <id>_selected onclick: _( '<id>', 'showSelectedRows')

more:
	mostrar otras barras
		Filtros, Buscar, Portapapeles, exportar, imprimir, orden, selección....
		Configuracion.
			seleccionar las columnas que se muestran y su orden, anchos..
			seleccionar número de filas por página.
			

*/

/**	
	LIST -- CONSTRUCTOR
	id- Identificador de la Lista, y del nodo DOM donde se va a crear la lista
	query- query a mostrar {name, params}
	options- opciones de la lista. A saber:
		recsxpage: Número de filas que se muestran en la lista.
		selectedKeys: Claves de los registros selecionados
*/

import {UIComponent} from '../UIComponent'
import SelectMultiple from './SelectMultiple'
import SelectNone from './SelectNone'
import SelectSingle from './SelectSingle'

export class List extends UIComponent{
    options={
        selectType: 'single' // 'none', 'single', 'multiple'
        , autoRecsxpage: false
        , includeWidths: false 
        , linkedCols: []
    }
    startx=0
    slidingCol=''
    recCount= 0 // número de registros pintados en pantalla.
    autoRecsxpage= 0 // número de registros que caben en la pagina (calculo automático)
    selectClass= "table-primary" //Clase css para las líneas seleccionadas
    selectType= ''

    constructor( id, recordset, options ){
        super( id )
        if ( options ) for (let opt in options) this.options[opt]=  options[opt]
        this.options.selectType= this.options.selectType.toLowerCase()
        switch ( this.options.selectType ){
            case 'single': this.selectedKeys= new SelectSingle(); break
            case 'multiple': this.selectedKeys= new SelectMultiple(); break
            case 'none': default: this.selectedKeys= new SelectNone(); this.options.selectType= 'none'
        }

        this.recordset= recordset
        this.recordset.addListener( 'defChange', this.id, 'refreshDef')
        this.recordset.addListener( 'pageChange', this.id, 'refresh' )
    }

    refreshDef(){
        let self= this

        this.cols=[]
        this.recordset.def.getCols().forEach( def => {
            self.cols.push({
                id: def.id
                , width: def.length <= 20 ? def.length : 20
                , hdClass: ''
                , caption: def.caption ? def.caption : def.id.toUpperCase()
                , dClass: ''
                , textAlign: def.controller.textAlign
                , controller: def.controller

            })
        })

    }

    /* cambia la lista al modo SHOW SELECTEDS, que muestra los registros seleccionados */
    /*  Si queremos quitar esta funcionalidad del recordset por sencillez de código,
        tenemos que traerlo a la lista. (podrían manejarse dos listas normal y multi-select.)
        Cada una con su barra de navegación.
    */
    showSelecteds(){
        console.log('SHOW SELECTED')
        this.recordset.setQuery( 
            this.recordset.query.id, this.recordset.query.params
            , {include_result_def: true}
            , this.selectedKeys.getKeys()
        )
//        this.datalink.showSelected()
/* 
    PARA QUE ESTO FUNCIONE HAY QUE PASAR LAS CLAVES COMO PARAMETRO NO COMO OPTION
    Y ADEMAS DEBE HABERSE ESCRITO UNA DEFINICION CON LA CLAVE EN EL FICHERO DE LA QUERY
*/

    }
    

    onLinkedClick( params ) { 
        let [row, colId] = params
        _('onLinkedClick', [colId, this.recordset.getKey( row ) ])
    }


    select( row ){ 
        this.selectedKeys.select( this.recordset.getKey( row ) )
        this.activeSelectedRows()


//        this.datalink.select( row ) 
    }

    unselect(){ 
        this.selectedKeys.clear()
        this.activeSelectedRows()
    }
    
    getSelectedKeys(){
        return this.selectedKeys.getKeys()
    }

    setSelectedKeys(keys){
        let self= this
        keys.map( key => self.selectedKeys.select( key ))
    }

    countSelectedKeys(){
        return this.selectedKeys.count()
    }


    /**
        HTML - WRITE LIST
        genera el html de la tabla y lo añade al documento
        La generación del código html es responsabilidad de List.
        La app no puede cambiar algo que no sea css.
    */
    htmlDynamic(){

//        let colgrip= '<i class="bi bi-grip-vertical"></i>' //'icon'// _.sprites.html( 'icon', ['colgrip'] ).icon_colgrip
        let self= this
            , tableWidth= ''


        if (typeof this.cols == 'undefined'){
//            return "<p>Empty list</p>{{{navbar}}}"
            return `<table class="table"><thead><tr id="${this.id}_HR"><th>...</th></tr></thead></table>`
        } else {
            if ( ! this.autoRecsxpage ){
                this.calcRecsxpage()
                if ( this.options.autoRecsxpage && this.recordset.options.recsxpage !== this.autoRecsxpage ){
                    this.recordset.options.recsxpage= this.autoRecsxpage
                    this.recordset.refresh()
                    return `<table class="table"><thead><tr id="${this.id}_HR"><th>...</th></tr></thead></table>`
                }
            }
        }
        
        let colgroupHtml, headerHtml, bodyHtml, rowHtml
            , row, rowId
            , record
            , width= 0
        
        colgroupHtml= '<colgroup>'
        headerHtml= `<thead><tr id="${this.id}_HR">`
        this.cols.forEach( function( col ){
            // hemos tenido que eliminar el colgroup y menter el ancho en el th porque los navegadores se lo pasan por el forro.
            let colwidth=''
            if (self.options.includeWidths) colwidth="max-width:${col.width}rem"
            colgroupHtml+= `<col style="${colwidth}">`
            headerHtml+= `<th style="${colwidth}" class="${col.hdClass}">${col.caption}</th>` 
            width+= col.width
        })
        colgroupHtml+= '</colgroup>'
        headerHtml+= '</tr></thead>'
        
        bodyHtml= '<tbody>'
        let recs= this.recordset.reset(); row= 0
        this.recCount= 0
        while ( !recs.EOP() ) {
            rowId= this.id + '-' + row
            rowHtml=''
            // getCurrentRecord debería devolver un objeto con todos los campos como propiedades. No un array se las propiedades seleccionadas.
            
            record= recs.current()
            this.cols.forEach( function( col ){
                let content= col.controller.printFormat(record[ col.id ])
                    , onclick= `onclick="_('${self.id}','onLinkedClick',[${row}, '${col.id}'])"`

                if ( self.options.selectType == 'none' && self.options.linkedCols.includes( col.id ) ){
                    content= `<a class="text-primary" ${onclick}>${content}</a>`
                }
//              rowHtml+= `<td class="${col.dClass}" id="${rowId + '-' + col.name}">${col.printFormat( record[ col.name ])}</td>` //record.getValue( col.id )
                rowHtml+= `<td class="${col.dClass}" style="text-align:${col.textAlign}" id="${rowId + '-' + col.id}">${content}</td>`
            })

            let rowClasses= ''
            if ( this.selectedKeys.isSelected( this.recordset.getKey( row )) ){
                rowClasses= ` class="${this.selectClass}"`
            } 
            let onclick= this.options.selectType !== 'none' ? `onclick="_('${this.id}','select',[${row}])"` : ''
            bodyHtml+=
                `<tr ${onclick} id="${rowId}"${rowClasses}>
                    ${rowHtml}
                </tr>`

            this.recCount++
            recs.next(); row++
        }
        bodyHtml+= '</tbody>'
        
        // while (row < recsxpage) bodyHtml+= filas en blanco.

//        return `<table style="min-width:${width}rem">` + colgroupHtml + headerHtml + bodyHtml + '</table>{{{navbar}}}'
        if ( this.options.includeWidths ) tableWidth= `min-width:${width}rem`
        return `<table class="table" style="${tableWidth}">` + headerHtml + bodyHtml + '</table>'

    }


    /**	SELECT
    */

    /** ACTIVE SELECTED ROWS
        Marca como seleccionada la linea cuya clave aparezca seleccionada 
        Ahora además de marcar las líneas seleccionadas, actualizamos el número de registros seleccionados (para casos de selección múltiple).
    */
    activeSelectedRows(){
        
        for (let r=0; r < this.recordset.options.recsxpage; r++){
            if ( this.selectedKeys.isSelected( this.recordset.getKey( r ) ) ){
                $('#' + this.id + '-' + r).addClass(this.selectClass)
            } else {
                $('#' + this.id + '-' + r).removeClass(this.selectClass);
            }
        }
        $('#' + this.id + '_selected').html( this.selectedKeys.count() )
    }


    beginSliding(col, node, event){
        this.startx= event.clientX
        this.slidingCol= col
//        node.onpointermove= function( ev ){console.log(ev.clientX, ev.clientY)}
        node.setPointerCapture(event.pointerId)
    }

    stopSliding(node, event){
        console.log( 'RESIZE COL', this.slidingCol, event.clientX - this.startx )
//        node.onpointermove= null
        node.releasePointerCapture(event.pointerId)
    }

/*  Registros por pagina automáticos. Los que quepan.
    queremos saber cuanto espacio tenemos para los registros.
    1) Calculamos el espacio disponible en la página antes de encontrarnos con el pie.
        #_page_.offsetHeight - #footer.offsetTop
    2) Esto lo dividimos por el alto de la cabecera. tr.offsetHeight
    3) Actualizamos el recordset.
    Evidentemente esta función solo podrá utilizarse cuando el html de la lista ya esté creado.

    Otros
    para calcular la altura de un registro
    vemos la altura del rem. document.defaultView.getComputedStyle( document.getElementsByTagName('html')[0] ).fontSize
    ¿ Que significa HR_ ? El identificador que hemos puesto en la cabecera de la lista
*/    
    calcRecsxpage(){
        let hr, freeSpace
        // si ya tenemos la lista instanciada en el browser tomamos el tr de la cabecera para medirle la altura
        if( hr= document.getElementById(this.id + '_HR') ){
            let footer= document.getElementById('footer'), footerOffset
            if ( footer ) footerOffset=  footer.offsetTop 
            else footerOffset= window.innerHeight

            let header= document.getElementById('header'), headerOffset
            if ( header ) headerOffset=  header.offsetTop + header.offsetHeight 
            else headerOffset= 0

//            freeSpace= footerOffset - document.getElementById('_page_').offsetHeight
            let pageOffset= document.getElementById('_page_').offsetHeight
            let windowOffset= window.innerHeight
            freeSpace= footerOffset - headerOffset
            this.autoRecsxpage= /* this.recCount + */Math.floor( freeSpace / hr.offsetHeight ) -2
            // si tenemos scroll horizontal este también suma. Quitamos una fila por el cabecero y otra por el scroll horizontal
            if (this.autoRecsxpage < 3 ) this.autoRecsxpage= 3
        }

    }


}
