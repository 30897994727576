// Module
var code = `<a href="#" class="list-group-item list-group-item-action d-flex gap-3 py-3" aria-current="true" onclick="{{comando}}">
  <i class="bi-caret-right " style="font-size: 1.2rem; "></i>
  
  <div class="d-flex gap-2 w-100 justify-content-between">
    <div>
      <h6 class="mb-0">{{titulo}}</h6>
      <p class="mb-0 opacity-75">{{descripcion}}</p>
    </div>
    <small class="opacity-50 text-nowrap">{{codigo}}</small>
  </div>
</a>`;
// Exports
export default code;