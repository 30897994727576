// Module
var code = `<div class="mb-3">
    <label for="{{id}}_nombre" class="form-label">nombre</label>
    <input type="input" class="form-control" id="{{id}}_nombre">
</div>
<div class="mb-3">
    <label for="{{id}}_apellido" class="form-label">apellido</label>
    <input type="input" class="form-control" id="{{id}}_apellido">
</div>
<div class="mb-3">
    <label for="{{id}}_cargo" class="form-label">cargo</label>
    <input type="input" class="form-control" id="{{id}}_cargo">
</div>
`;
// Exports
export default code;