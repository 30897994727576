import manager from '../platform/Manager'
import {UIComponent} from '../platform/UIComponent'

export default class extends UIComponent{
    htmlTemplate='<div class="container"><h4>INTEGRACIONES</h4>{{{menu}}}</div>'

    constructor(id){
        super(id)
        this.menu= this.addUIComponent( manager.getClass( 'Menu' ) )
        this.menu.setAlias('menu')
        let tecla= 1

        this.menu.addItem( "_.open('JSIX')", 'JACKPOT SYSTEMS' , 'Intregración con Jackpot Sytems.', 'J' )
        this.menu.addItem( "_.open('ISIX')", 'INFRASPEAK' , 'Integración con Infraspeak.', 'I' )
        this.menu.addItem( "_.open('GTMIX')", 'GESTIMAQ' , 'Integración con Gestimaq.', 'G' )

        
//        this.menu.addItem( "_.open('')", titulo , descripcion, tecla )
    }
   
}