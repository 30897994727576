// Module
var code = `<div class="container bg-secondary-subtle">
    <form id="{{id}}_form">

        <div class="row mb-3">
            <div class="col">
                <label for="{{id}}_modelo" class="form-label">Modelo</label>
                <input class="form-control" id="{{id}}_modelo" readonly value="{{modelo}}"/>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-3">
                <label for="{{id}}_fabricante" class="form-label">Fabricante</label>
                <div class="input-group">
                    <input class="form-control" id="{{id}}_fabricante" onblur="_('validate', {id:'fabricante'})" value="{{fabricante}}">
                    <button class="btn btn-secondary" type="button" onclick="_('lookup', {id:'fabricante'} )" ><i class="bi bi-three-dots"></i></button>
                </div>
            </div>
            <div class="col-9">
                <label for="{{id}}_fabricante_nombre" class="form-label">Nombre fabricante</label>
                <input class="form-control" id="{{id}}_fabricante_nombre" readonly value="{{fabricante_nombre}}">
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-3">
                <label for="{{id}}_mueble" class="form-label">Mueble</label>
                <div class="input-group">
                    <input class="form-control" id="{{id}}_mueble" onblur="_('validate', {id:'mueble'})" value="{{mueble}}">
                    <button class="btn btn-secondary" type="button" onclick="_('lookup', {id:'mueble'} )" ><i class="bi bi-three-dots"></i></button>
                </div>
            </div>
            <div class="col-9">
                <label for="{{id}}_mueble_nombre" class="form-label">Nombre mueble</label>
                <input class="form-control" id="{{id}}_mueble_nombre" readonly value="{{mueble_nombre}}">
            </div>
        </div>
    
        <div class="row mb-3">
            <div class="col-3">
                <label for="{{id}}_juego" class="form-label">Juego</label>
                <div class="input-group">
                    <input class="form-control" id="{{id}}_juego" onblur="_('validate', {id:'juego'})" value="{{juego}}">
                    <button class="btn btn-secondary" type="button" onclick="_('lookup', {id:'juego'} )" ><i class="bi bi-three-dots"></i></button>
                </div>
            </div>
            <div class="col-9">
                <label for="{{id}}_juego_nombre" class="form-label">Nombre juego</label>
                <input class="form-control" id="{{id}}_juego_nombre" readonly value="{{juego_nombre}}">
            </div>
        </div>
    

        <div class="mb-3">
            <label for="{{id}}_nombre" class="form-label">Nombre</label>
            <input class="form-control" id="{{id}}_nombre" value="{{nombre}}" onblur="_('validate', {id:'nombre'})"/>
        </div>
        
        <div class="row pb-3">
            <div class="col">
                <label for="{{id}}_tipo" class="form-label">Tipo</label>
                <div class="input-group">
                    <input  class="form-control" id="{{id}}_tipo" onblur="_('validate', {id:'tipo'})" value="{{tipo}}"/>
                    <button class="btn btn-secondary" type="button" onclick="_('lookup', {id:'tipo', query: 'modelo_tipo'} )" ><i class="bi bi-three-dots"></i></button>
                </div>
            </div>
            
            <div class="col">
                <label for="{{id}}_num_puestos" class="form-label">Número de puestos</label>
                <input  class="form-control" id="{{id}}_num_puestos" onblur="_('validate', {id:'num_puestos'})" value="{{num_puestos}}"/>
            </div>
        </div>
    
    </form>
    </div>
    `;
// Exports
export default code;