import manager from '../../platform/Manager'
import {Ficha} from '../../platform/pages/Ficha'
import html from './ficha.html'
import html_nuevo from './ficha-nueva.html'

export class Ubicacion extends Ficha{
    selects= {
        tipo: ['SALON','BAR','HOTEL','ALMACEN','BINGO']
    }

    constructor (id, ubicacion){
        super(id, { procView: 'ubicacion__ficha', updateTable: 'ubicacion', htmlCreate: html_nuevo, htmlEdit: html }, ubicacion)
    }

    crear(){
        let self= this
        manager.request('DB/procedure/run/ubicacion__crea', [this.getValor('tipo')])
        .done( response => {
            self.mode= 'edit'
            self.receiveDataAndRefresh( response )
        })
    }

    getClave(){ return this.data.ubicacion }


}
