export class TObject{
    
    id= ''
    lastId= 0
    childs= {}

    constructor( id ){
        this.id= id
    }

/*
    named object - Objetivo: que se puedan acceder a sus acciones directamente desde el default route porque están como identificador la página actual.
        ¿ no podemos acceder por su id ? No es para eso todo este jaleo ? El id es dinámico y no lo tenemos en tiempo de compilación.
    ui object - Objetivo: si el componente genera html o no, si forma parte de la ui o es solo de control. 
        Con devolver false en html tenemos.
    wrap object - Objetivo. En la generación automática de la pagina o componente, este es wrapped por otro que es subcomponente de si mismo.
*/


    createObject( classDef, ...params ){
        let childId= ++this.lastId
        let id= this.id + '_' + childId
        this.childs[ childId ]= new classDef( id, ...params )
        return this.childs[ childId ]
    }

    getObject( id ){
        let myId= this.id + '_'
        if ( id.substring( 0, myId.length ) == myId ){
            let childId= id.substring( myId.length )
            if ( childId.includes('_') ) {
                return this.childs[ childId.split('_')[0] ].getObject( id )
            } else if ( typeof this.childs[ childId ] !== 'undefined' ) {
                return this.childs[ childId ]
            }
        }
        return false
    }

    removeObject( id ){
        // Todo, confirmar que estamos en el TObject adecuado y existe el objeto a borrar.
        // Sera similar al getObject, pero en lugar de devolver el objecto, lo elimina.
        // Miramos si es un hijo nuestro.
        let myId= this.id + '_'
        if ( id.substring( 0, myId.length ) == myId ){
            let childId= id.substring( myId.length )
            if ( childId.includes('_') ) {
                this.childs[ childId.split('_')[0] ].removeObject( id )
            } else if ( typeof this.childs[ childId ] !== 'undefined' ) {
                delete this.childs[ childId ]
            }
        }

        //HABRIA QUE PROBAR QUE SI UN OBJECTO NOMBRADO DE BORRA, NO SE PUEDE SEGUIR ACCEDIENDO POR SU NOMBRE.
    }

}

