import manager from '../../platform/Manager'
import { UIComponent } from '../../platform/UIComponent'
import { RecordSet } from '../../platform/db/RecordSet'
import { List } from '../../platform/list/List'
import html from '../../platform/pages/lista.html'

export class Salones extends UIComponent{

    constructor(id){
        super( id )
        this.renderParams={title: 'Salones'}
        this.htmlTemplate= html
        this.recordset= this.addComponent( RecordSet )
        this.list= this.addUIComponent( List, this.recordset, {selectType: 'none', linkedCols: [ 'grupos', 'maquinas' ]} )
        setTimeout( this.openQuery.bind(this), 0 )
        manager.addListener( 'show', this.id,  'onShow')

    }

    onShow(){ this.recordset.refresh() }

    openQuery(){ this.recordset.setQuery( 'salones' )  } 

    onLinkedClick( params ){
        let [col, key ]= params
        switch( col ){
            case 'grupos':  manager.open('Grupos', key); break
            case 'maquinas': manager.open('MaquinasPorSalon', key); break
        }
    }
    
    first() { this.recordset.seekPage(0) }
    previous(){ this.recordset.seekNextPage(-1) }
    next(){ this.recordset.seekNextPage(1) }
    last(){ }


}