import SelectKeys from './SelectKeys'

export default class SelectMultiple extends SelectKeys{
    keys=[]

    select( key ){
        if ( this.isSelected( key ) ) this.unselect( key )
        else this.keys.push( key )
    }
    unselect( key ){
        let keyIndex, self= this
        this.keys.forEach( (selected, index) => { if( self.equals( selected, key) ) keyIndex= index } )
        if( typeof keyIndex === 'number' ) this.keys.splice( keyIndex, 1 )
    }
    clear(){ this.keys=[] }
    count(){ return this.keys.length }
    isSelected( key ){
        let self= this
        return this.keys.some( selected => self.equals(selected, key) )
    }
    getKeys(){ return this.keys }

}