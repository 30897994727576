// Module
var code = `<label for="{{id}}" class="form-label">Grupos</label>
<div class="form-control" id="{{id}}">
{{{html}}}
    <span >
        <a class="text-primary ms-1" data-bs-toggle="modal" data-bs-target="#{{idf}}_AG"><i class="bi bi-plus-square"></i></a>
    </span>
</div>
`;
// Exports
export default code;