// Module
var code = `<div class="container">
    <h2 id="{{id}}_salon"></h2>
    <h1>Grupos</h1>

    {{{list}}}

    <ul class="nav justify-content-start">
        <li class="nav-item"><a class="text-primary" onclick="_('first')"><i class="bi-rewind" style="font-size: 2rem; "></i></a></li>
        <li class="nav-item"><a class="text-primary" onclick="_('previous')"><i class="bi-skip-start" style="font-size: 2rem; transform: scaleX(-3);"></i></a></li>
        <li class="nav-item"><a class="text-primary" onclick="_('next')"><i class="bi-skip-end" style="font-size: 2rem; "></i></a></li>
        <li class="nav-item"><a class="text-primary" onclick="_('last')"><i class="bi-fast-forward" style="font-size: 2rem; "></i></a></li>
    </ul>


</div>`;
// Exports
export default code;