import { Lista } from '../../platform/pages/Lista'
import actions_html from '../../platform/pages/lista-actions.html'

export class UbicacionLst extends Lista{

    constructor(id){
        super( id, 'Ubicacion' )
        this.renderParams= { actions: actions_html}
    }


}
 