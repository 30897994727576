import {UIComponent} from '../../platform/UIComponent'
import htmltpt from './menu.html'
import Item from './Item'

export default class extends UIComponent {
    items=[]

    htmlDynamic(){
        let html= ''
        this.items.map( (item) => html+= item.html().html )
        return _.render( htmltpt, { items: html })
    }

    addItem( comando, titulo, descripcion, tecla ){
        let ix= this.items.push( this.addUIComponent( Item, comando, titulo, descripcion, tecla ) )
        this.items[ ix-1 ].setAlias ( 'item'+ix )
}

}