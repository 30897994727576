import {UIComponent} from '../../platform/UIComponent'
import html from './grupo.html'
import wrap from './grupo-wrap.html'

export class Grupo extends UIComponent{
    tag= false

    constructor( id, ficha ){
        super(id)
        this.ficha= ficha
        //maquina['maquina_nueva']= ! maquina.maquina && typeof maquina.maquina === 'object'
    }

    /**
     * El problema que nos plantea esta solución es que la plantilla tiene los mismos bigotes para cada grupo.
     * Así no se pueden definir nuevos 
     */

    getHtmlTemplate(){ 
        if ( this.ficha.data && this.ficha.data.grupo ){
            let tpt={ wrap: wrap, html: '' }
            for (let g= 0; g < this.ficha.data.grupo.length; g++) {
                let id= 'GRP' + g
                tpt.html += '{{{' + id + '}}}'
                tpt[id]= html
            }
            return tpt

        } else return { html: wrap }
    }

    getRenderParams( id ){
        if( id.substring(0,3) == 'GRP' ){
            let g= parseInt( id.substring(3) )
            return this.ficha.data.grupo[g]
        } else return { idf: this.ficha.id }
    }
    

}
