/**
 * RECORDSET API.
 * Recupera la información de un api externa para entregarla a la lista
 */

import manager from '../Manager'
import {RecordDef} from './RecordDef'
import {PageSet} from '../db/PageSet'
import {Component} from '../Component'


export  class RecordSet extends Component{ // extends RecordDef ¿?
    options={
        recsxpage: 10
    }
//  recordDef - Definición del record recogida de la query

    constructor( id, options ){
        super(id)
        
        if ( options ) for (let opt in options) this.options[opt]=  options[opt]
        
        this.registerEvents(['pageChange', 'defChange'])

        this.currentCount= 999999999 // aproximación. No se sabe el valor exacto del count
        this.realCount= -1 // Indica que no sabemos actualmente cuantos resgistros tiene el fichero.
        this.pageRecs= null //new PageSet()
        this.qryError= false // Se pone a cierto cuando hay un error en la query. Así se evitan bucles infinitos en el refresh cuando se vuelve del formulario de error.
    /*	ATRIBUTOS
        this.currentPage // página cargada actualmente 
        this.currentPos= 0 // Posición del registro actual
    */

    }


    /** SET QUERY
    **/
    setUrl( url , params ){
        let self= this

        manager.request(
            url // query.apiNamespace + '/APICall/query/' + query.queryName
        ).done( function( response ) {
            self.def= new RecordDef(  )
            if( response.def ) self.def.setDef( response.def )
            else self.def.setFromResult( response.result )
            self.fire('defChange', self.def) //aqui podemos enviar el def recibido.

            self.currentPos= 0
            self.currentPage= 0
            if (response.result){
                self.loadPageRecs( response.result )

                if( self.pageRecs.length < self.options.recsxpage + 1 ){
                    self.realCount= self.pageRecs.length
                    self.currentCount= self.realCount
    
                } else {
                    self.realCount= -1 
                    self.currentCount= 999999999 
                }
                if ( self.pageRecs.length > self.options.recsxpage ) self.pageRecs.splice(self.options.recsxpage, 1)

            } else {
                self.currentCount= 0
                self.realCount= 0

            }
            self.fire('pageChange') // y aqui los recs recibidos ¿?

        }).fail(function(response) {
            self.qryError= true
            console.log(`ERROR (datalink): El servidor responde con error en la petición de query ${self.query.name}`)
            
        })

    }

    reset(){ 
        return new PageSet( this.pageRecs ) 
    }


    /** END OF FILE
    */
     EOF(){ return this.realCount >= 0 && this.currentPos >= this.realCount }

    getCurrentPage(){ return this.currentPage }
    getPageCount(){ return Math.ceil( this.currentCount / this.options.recsxpage )} 

    /** GET KEY
        Devuelve un array con los valores de la clave del registro de posición pos en la pagina actual
    */
    getKey( inPos ){
        if (inPos < this.options.recsxpage && typeof this.pageRecs[inPos] === 'object'){
            return this.def.getKey( this.pageRecs[inPos] )
        } else return false
    }

    getRec( inPos ){
        if (inPos < this.options.recsxpage && typeof this.pageRecs[inPos] === 'object'){
            return this.pageRecs[inPos] 
        } else return false
    }

    /**** NAVIGATION ******************************************************/


    /** SEEK
        Salta registro en la posicion n del fichero.
        si n no se especifica salta al primer registro de la página actual.
        si n = 0 el primer registro del fichero
        si n = -1 salta al último registro.
        si n < 0 salta al registro n comenzando por el final del fichero.
        
        SEEK NEXT - Salta al siguiente registro.
        SEEK PAGE - Salta al inicio de una página concreta
        SEEK NEXT PAGE - Salta al inicio de una página relativa a la actual.
        SEEK IN PAGE - Se mueve al registro x dentro de la página actual
    */
    seekPage( page ){ 
        if ( typeof page === 'undefined' ) page= this.currentPage
        this.seek( page * this.options.recsxpage ) 
    }
    seekNextPage( count ){ 
        if ( typeof count === 'undefined' ) count= 1
        this.seek( (this.currentPage + count) * this.options.recsxpage  ) 
    }
    seekInPage( inPos ){
        if ( typeof inPos === 'undefined' ) inPos= this.currentPos % this.options.recsxpage
        inPos= Math.abs( inPos ) %  this.options.recsxpage
        this.seek( this.currentPage * this.options.recsxpage + inPos ) 
    }
    seekNext( count ){
        if ( typeof count === 'undefined' ) count= 1
        this.seek( this.currentPos + count  ) 
    }
    seek( newPos ){

        if (typeof newPos === 'undefined' ) newPos= this.currentPage * this.options.recsxpage  // Inicio de la página actual
    //	Si la posición es negativa nos colocamos al final. (Hay que lanzar una llamada al servidor para que cuente si no tenemos el dato. POSTERGADO.
        if ( newPos < 0 ) return
    //	Si la posición es superior al EOF. ¿ Retornamos o actualizamos ?
        if ( this.realCount >= 0 && newPos >= this.realCount ) return
        
        let newPage= Math.floor( newPos / this.options.recsxpage )
        
        // Si saltamos de página vamos cargamos la página y nos rellamamos.
        if ( this.currentPage != newPage ) this.refresh( newPos )
        else this.currentPos= newPos;

    }

    /**	REFRESH
        Recupera los datos de la página actual del servidor.
    */
    refresh( newPos ){
        let self= this
            , newPage, offset
        
        if (typeof newPos === 'undefined') newPos= this.currentPos
        newPage= Math.floor( newPos / this.options.recsxpage )
        offset= newPage * this.options.recsxpage
        this.qryError= false

        manager.request(
            'DB/query/run/'+ this.query.id
            , this.query.params
            ,  { 
                    limit: this.options.recsxpage + 1
                    , offset: offset
                } 
            ).done( function( response ){
            if ( !self.def && response.def ){
                self.def= new RecordDef( response.def  )
                //            self.record= new Record( self.def )
                self.fire('defChange', self.def) //aqui podemos enviar el def recibido.
            }
     
            self.currentPos= newPos
            self.currentPage= Math.ceil(offset / self.options.recsxpage)
            self.loadPageRecs(response.result)

            if (self.pageRecs){
                if (( self.pageRecs.length > 0 && self.pageRecs.length < self.options.recsxpage + 1 ) // La pagina devuelve menos registros que los pedidos ( y mayor que 0 )
                    || ( self.pageRecs.length == 0 && offset == 0 )) { // La página devuelve 0 registros y estamos al inicio del fichero
                    self.realCount =  self.pageRecs.length + offset
                    self.currentCount = self.realCount
                }
                if ( self.pageRecs.length > self.options.recsxpage ) self.pageRecs.splice(self.options.recsxpage, 1)
            }
            self.fire('pageChange') // y aqui los recs recibidos ¿?

        }).fail( function( response ){
            self.qryError= true
            console.log(`ERROR: Error del servidor al recibir la query ${self.query.name}`)
        }) 
        
    }


    loadPageRecs( response ){
//        this.pageRecs= response
        let self= this
        this.pageRecs= []
        if ( response ){
            response.map( ( respRec ) =>{
                let pageRec={}
                Object.getOwnPropertyNames( self.def.cols ).map( (colname) => {
                  pageRec[ colname ]= self.def.cols[ colname ].controller.importFromSQL( respRec[ colname ] ) 
                })
                this.pageRecs.push( pageRec )
            })  
        }
    }
}
