// Module
var code = `<div class="container">
    <h1>FICHA DE AGENDA</h1>
    <p>Hay que mostrar el puesto de la agenda (se esta mostrando el puesto del grupo)</p>
    <p>Hay que mostrar los grupos en la cabecera para poderlos quitar o recargar si falta algún miembro.</p>
    <p>También debería mostrarse el número de puestos y el estado, por si hay algún puesto que esté en otra agenda</p>
    <p>Jugar un poco con las posibilidades</p>
    {{{agenda}}}
    {{{maquinas}}}
</div>`;
// Exports
export default code;