import SelectKeys from './SelectKeys'

export default class SelectSingle extends SelectKeys{
    key= false 

    select( key ){
        if( this.key ){
            if ( this.equals( this.key, key ) ) this.key= false
            else this.key= key
        } else this.key= key
    }
    clear(){ this.key= false }
    count(){ return this.key ? 1 : 0 }
    isSelected( key ){
        if( this.key ) return this.equals( this.key, key )
        else return false
    }
    getKeys(){ return this.key ? [this.key] : [] }

}