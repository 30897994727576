import manager from '../platform/Manager'
import {UIComponent} from '../platform/UIComponent'
import layout from './layout.html'
import home from './home.html'

export default class Home extends UIComponent{
    renderCount= 0

    constructor(id, datosSesion ){
        super(id)
        this.datosSesion= datosSesion
        this.menu= this.addUIComponent( manager.getClass( 'Menu' ) )
        this.menu.setAlias('menu')
        this.menu.addItem( "_.open('AgendaLista')", 'AGENDAS' , 'Lista de Agendas pendientes de registrar.', 'L' )
        this.menu.addItem( "_.open('AgendaFicha')", 'NUEVA AGENDA' , 'Crea una nueva agenda de máquina a la espera del certificado del fabricante.', 'L' )
        this.menu.addItem( "_.open('Query','grupos_agendados_pub')", 'GRUPOS AGENDADOS' , 'Lista los grupos agendados pendientes.', 'L' )
        this.menu.addItem( "_.open('Query','empresas_pub')", 'EMPRESAS' , 'Listado de empresas (pub).', 'L' )
        this.menu.addItem( "_.open('Salones')", 'SALONES' , 'Listado de salones (priv).', 'L' )
        this.menu.addItem( "_.open('Query','grupos_pub')", 'GRUPOS' , 'Grupos (pub).', 'L' )
        this.menu.addItem( "_.open('Query','maquinas_pub')", 'MAQUINAS' , 'Máquinas (pub).', 'L' )
        this.menu.addItem( "_.open('Query','ubicaciones_pub')", 'UBICACIONES' , 'Ubicaciones.', 'L' )
        this.menu.addItem( "_.open('Query','sesionvars')", 'VARIABLES DE SESION' , 'Variables de la sesión actual.', 'L' )
        this.renderParams={ empresa: datosSesion.nombre_empresa }
    }

     htmlDynamic(){
        let htmlTemplate= {
             html: home
        }
    
        if ( !this.renderCount++ ){
            htmlTemplate.warp= layout
        }

        return htmlTemplate
    }

    
}