import { Lista } from '../../platform/pages/Lista'
import actions_html from './lista-actions.html'

export class UsuarioLst extends Lista{

    constructor(id){
        super( id, 'Usuario' )
        this.renderParams= { actions: actions_html}
    }

}
 