import manager from '../platform/Manager'

import './agenda/_index'
import './fabricante/_index'
import './modelo/_index'
import './mueble/_index'
import './juego/_index'
import './ubicacion/_index'
import './zona/_index'
import './empresa/_index'


// HTML resources
//import resource from './resource.html'; manager.addHtmlResource( 'resource', resource )

// Page classes
//import page from './page'; manager.addPageClass( 'page', page )
import {Gestor} from './Gestor'; manager.addPageClass( 'Gestor', Gestor )
import {Maestros} from './Maestros'; manager.addPageClass( 'Maestros', Maestros )
