import manager from '../platform/Manager'
import {UIComponent} from '../platform/UIComponent'

export default class extends UIComponent{
    htmlTemplate='<div class="container"><h4>INTEGRACION GESTIMAQ</h4>{{{menu}}}</div>'

    constructor(id){
        super(id)
        this.menu= this.addUIComponent( manager.getClass( 'Menu' ) )
        this.menu.setAlias('menu')
        let tecla= 1

        this.menu.addItem( "_.open('GTM_InsertarMultipuesto')", 'INSERTAR MULTIPUESTO' , 'Inserta una maquina Multipuesto (tipo M) de gestimaq que aún no tiene numeración en el nuevo sistema.', tecla.toString() ); tecla++
        this.menu.addItem( "_.open('GTM_InsertarUbicacion')", 'INSERTAR UBICACIÓN' , 'Inserta un cliente de gestimaq como nueva ubiación en el sistema.', tecla.toString() ); tecla++

        
//        this.menu.addItem( "_.open('')", titulo , descripcion, tecla )
    }
   
}