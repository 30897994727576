// Module
var code = `<div class="container">
    <h4 id="{{id}}_salon"></h4>
    <h4>Grupos</h4>

    {{{list}}}

    <ul class="nav justify-content-start">
        <li class="nav-item"><a class="text-primary" onclick="_('previous')"><i class="bi-skip-start" style="font-size: 2rem; transform: scaleX(-3);"></i></a></li>
        <li class="nav-item"><a class="text-primary" onclick="_('next')"><i class="bi-skip-end" style="font-size: 2rem; "></i></a></li>
    </ul>


</div>`;
// Exports
export default code;