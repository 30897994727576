// Module
var code = `<div class="container">
<form id="{{id}}_form">
    <!--div class="mb-3">
        <label for="{{id}}_fabricante" class="form-label">Fabricante</label>
        <input class="form-control" id="{{id}}_fabricante"/>
    </div-->
    <div class="row mb-3">
        <div class="col-3">
            <label for="{{id}}_fabricante" class="form-label">Fabricante</label>
            <div class="input-group">
                <input class="form-control" id="{{id}}_fabricante" onblur="_('validate', {id:'fabricante'})" value="{{fabricante}}">
                <button class="btn btn-secondary" type="button" onclick="_('lookup', {id:'fabricante'} )" ><i class="bi bi-three-dots"></i></button>
            </div>
        </div>
        <div class="col-9">
            <label for="{{id}}_fabricante_nombre" class="form-label">Nombre fabricante</label>
            <input class="form-control" id="{{id}}_fabricante_nombre" readonly value="{{fabricante_nombre}}">
        </div>
    </div>


    <div class="d-flex"> 
        <button class="btn btn-secondary ms-auto me-2" type='button' onclick="_.returns()">CANCELAR</button>
        <button type='button' class="btn btn-primary" onclick="_('crear')">ACEPTAR</button>
    </div>


</form>
</div>
`;
// Exports
export default code;