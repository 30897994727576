// Module
var code = `<div class="modal-header">
    <h5 class="modal-title">{{title}}</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
</div>
<div class="modal-body">
    {{{content}}}
</div>
<div class="modal-footer">
    {{{actions}}}
</div>
`;
// Exports
export default code;