import manager from '../../platform/Manager'
import {UIComponent} from '../../platform/UIComponent'
import html from './TestAPI.html'

export class TestAPI extends UIComponent{
    htmlTemplate= html

   
    doRequest( req ){
        manager.request( 'JS/APICall/execute/' + req.name )
    }

    doLogin(){
        manager.request( 'JS/APICall/execute/login' )
        .then( (response) =>{
            console.log(response)
            if ( response.success ) {
                _.app.setVars( 'sesion', response.result )
                console.log( _.app.getSectionVars('sesion')) // session_id: number
            }

        } )
    
    }


    doAccounting(){
        manager.request( 'JS/APICall/execute/accounting')
        .done( (response)=> console.log(response) )
    }



    doInfo(){
        manager.request( 'JS/APICall/info' )
        .done( response => console.log(response) )
    }

}