import manager from '../../platform/Manager'
import {UIComponent} from '../../platform/UIComponent'

export class Maestros extends UIComponent{
    htmlTemplate='<div class="container"><h4>MAESTROS INFRASPEAK</h4>{{{menu}}}</div>'

    constructor(id){
        super(id)
        this.menu= this.addUIComponent( manager.getClass( 'Menu' ) )
        this.menu.setAlias('menu')
        let tecla= 1

        this.menu.addItem( "_.open('IS_Query', 'IS/APICall/query/clients')", 'CLIENTES' , '.', tecla.toString() ); tecla++
        this.menu.addItem( "_.open('IS_Query', 'IS/APICall/query/categories')", 'CATEGORIAS' , '.', tecla.toString() ); tecla++
        this.menu.addItem( "_.open('IS_Query', 'IS/APICall/query/elements' )", 'ELEMENTOS' , '.', tecla.toString() ); tecla++
        this.menu.addItem( "_.open('IS_Query', 'IS/APICall/query/elements?s_category_id=199299' )", 'MAQUINAS-B' , '.', tecla.toString() ); tecla++
        this.menu.addItem( "_.open('IS_Query', 'IS/APICall/query/operators' )", 'USUARIOS' , '.', tecla.toString() ); tecla++
        this.menu.addItem( "_.open('IS_Query', 'IS/APICall/query/locations' )", 'UBICACIONES' , '.', tecla.toString() ); tecla++
        this.menu.addItem( "_.open('IS_Query', 'IS/APICall/query/locations/buildings' )", 'EDIFICIOS' , '.', tecla.toString() ); tecla++
        this.menu.addItem( "_.open('IS_Query', 'IS/APICall/query/failures' )", 'FALLOS' , '.', tecla.toString() ); tecla++
        this.menu.addItem( "_.open('IS_Query', 'IS/APICall/query/problems' )", 'PROBLEMAS' , '.', tecla.toString() ); tecla++

        
//        this.menu.addItem( "_.open('')", titulo , descripcion, tecla )
    }
   
}