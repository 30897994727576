import manager from '../platform/Manager'
import {UIComponent} from '../platform/UIComponent'
import layout from './layout.html'
import home from './home.html'
import modals from '../comun/modals/modals.html'
import {Dialog} from '../comun/modals/Dialog'

export class Gestor extends UIComponent{
    renderCount= 0

    constructor(id, datosSesion ){
        super(id)
        this.datosSesion= datosSesion
        this.menu= this.addUIComponent( manager.getClass( 'Menu' ) )
        this.menu.setAlias('menu')
        this.menu.addItem( "_.open('AgendaLista')", 'AGENDAS' , 'Lista de Agendas pendientes de registrar.', 'A' )
        this.menu.addItem( "_.open('Salones')", 'SALONES' , 'Listado de salones (priv).', 'S' )
        this.menu.addItem( "_.open('Pruebas')", 'PRUEBAS' , 'Pruebas de la aplicación.', 'P' )
        this.menu.addItem( "_.open('UsuarioLista')", 'USUARIOS' , 'Lista de usuarios y permisos de acceso.', 'U' )
        this.menu.addItem( "_.open('Maestros')", 'MAESTROS' , 'Acceso y modificación de las tablas maestras y de configuración.', 'M' )
        this.menu.addItem( "_.open('Integraciones')", 'INTEGRACIONES' , 'Integraciones con otras aplicaciones.', 'I')
        this.menu.addItem( "_.open('Queries')", 'CONSULTAS' , 'Consultas varias.', 'C')
        this.renderParams={ empresa: datosSesion.nombre_empresa, modals: modals }
        this.showErrorDlg= this.addComponent( Dialog, 1 )
    }

     htmlDynamic(){
        let htmlTemplate= {
             html: home
        }
    
        if ( !this.renderCount++ ){
            htmlTemplate.wrap= layout
        }

        return htmlTemplate
    }

    showError( err ){
        console.log(err) 
        if ( err.errorMsg ) err.errorMsj= err.errorMsg 
        this.showErrorDlg.show( err ) 
    }
    
}
