// Module
var code = `<div class="container">
    <h2 id="{{id}}_ubicacion"></h2>
    <h2 id="{{id}}_grupo"></h2>
    <h1>Maquinas</h1>

    {{{list}}}

    <div class="row">
        <div class="col">
            <ul class="nav justify-content-start ">
                <li class="nav-item"><a class="text-primary" onclick="_('first')"><i class="bi-rewind" style="font-size: 2rem; "></i></a></li>
                <li class="nav-item"><a class="text-primary" onclick="_('previous')"><i class="bi-skip-start" style="font-size: 2rem;"></i></a></li>
                <li class="nav-item"><a class="text-primary" onclick="_('next')"><i class="bi-skip-end" style="font-size: 2rem; "></i></a></li>
                <li class="nav-item"><a class="text-primary" onclick="_('last')"><i class="bi-fast-forward" style="font-size: 2rem; "></i></a></li>
            </ul>
        </div>
        <div class="col">
            {{{acciones}}}
        </div>
    </div>


</div>`;
// Exports
export default code;