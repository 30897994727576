/* global $ */

//import modalHtml from './Modal.html'
import {EObject} from './EventObject'
let modalHtml=
`<div class="Modal__backdrop" onclick="_.returns()"></div>
<div class="Modal__container">{{{html}}}</div>`

class Manager extends EObject{
    repository= {
        default: { 
            htmlResources:{},
            pageClasses:{},
            classes:{}
        }
    }
    currentModule='default'
    modules={}
    lastRequest= 0
    pageStack= []
    modalOpened= false
    minimalMode= false //definir

    constructor(){
        super( '' )
        this.checkMinimalMode( false )
        this.registerEvents(['show','hide','unload', 'key-down']) // incluir request-start request-end
        document.addEventListener( 'keydown', this.keyEvent.bind(this) )
        //window.addEventListener( "resize", this.checkMinimalMode.bind(this, true) )
        // otros eventos 
        //      request-start request-end cuando se realizan llamadas al servidor (para mostrar alguna señal)
        // NO   ready-boot (para cargar módulos en el inicio) NO esto se hace en app.start
        //      click, keypress o cualquier otro evento estandar. -> mejor al app o en HomePage. 
        //      Yo lo haría en Home Page donde aglutina los layouts y los comportamientos estandars de teclas y ratones. 


    }

    // ADDMODULE [ID], OBJECT
    addModule(){
        let id, module, iarg=0

        if ( typeof arguments[iarg] === 'string' ) id= arguments[iarg++]
        module= arguments[iarg++]
        if (!id) id= module.constructor.name.toLowerCase()

        if (typeof this.modules[id] !== 'undefined') {
            console.log(`Ignoring module ${id}. It has already been loaded`)
            return false
        }
        this.modules[id]= module
        this.repository[id]={
            htmlResources:{},
            pageClasses:{},
            classes:{}
        }
        window._[id] = module
        if( typeof module.init === 'function' ) module.init( this )

        return true
    }

    setModule( id ){ this.currentModule= id }
    defaultModule(){ this.currentModule= 'default' }

    addClass( id, clss ){ 
        let module= this.currentModule
        if ( typeof id === 'function' ) { clss= id; id= clss.name }
        if ( id.includes('/') ) [module, id]= id.split('/', 2)
        this.repository[module].classes[id]= clss 
    }
    getClass( id ){ 
        let module= this.currentModule
        if ( id.includes('/') ) [module, id]= id.split('/', 2)
        return typeof this.repository[module].classes[id] !== 'undefined' ? this.repository[module].classes[id] : false 
    }

    addPageClass( id, clss ){ 
        let module= this.currentModule
        if ( typeof id === 'function' ) { clss= id; id= clss.name }
        if ( id.includes('/') ) [module, id]= id.split('/', 2)
        this.repository[module].pageClasses[id]= clss 
    }
    getPageClass( id ){ 
        let module= this.currentModule
        if ( id.includes('/') ) [module, id]= id.split('/', 2)
        return typeof this.repository[module].pageClasses[id] !== 'undefined' ? this.repository[module].pageClasses[id] : false 
    }

    addHtmlResource( id, html ){ 
        let module= this.currentModule
        if ( id.includes('/') ) [module, id]= id.split('/', 2)
        this.repository[module].htmlResources[id]= html 
    }
    getHtmlResource( id ){ 
        let module= this.currentModule
        if ( id.includes('/') ) [module, id]= id.split('/', 2)
        return typeof this.repository[module].htmlResources[id] !== 'undefined' ? this.repository[module].htmlResources[id] : false 
    }


    /* the main page can define a defaultHtml property as array or function that return the parts of a subpage 
        html - de la página
        modal - si se abre modal
        reload - si se saca de la pila y no es creada nueva
    */
    renderPageHtml( page, modal, reload ){ 
        let html= page.html()
        if ( modal ) {
            if (typeof html === 'string') html= {html: html}
            let innerHtml= _.render(modalHtml, html)
            this.modal( page.id, innerHtml)
        
        } else {
            let renderParts={}

            if ( this.pageStack.length == 1 && !reload){
                let pageAnchor= window.document.getElementById('_layout_') ? '_layout_' : '_page_'
                if ( typeof html === 'string' ){
                    renderParts[ pageAnchor ]= html    
                } else {
                    for( let part in html ){
                        if ( part == 'html' ) renderParts[ pageAnchor ]= html[ part ]
                        else renderParts[ '_' + part + '_' ]= html[ part ]
                    }
                }

            } else {
                if ( typeof html === 'string' ){
                    renderParts[ '_page_' ]= html

                } else {
                    let defaultParts={}, mainPage= this.getMainPage()
                    if ( typeof mainPage.htmlDynamic !== 'undefined') {
                        defaultParts= mainPage.htmlDynamic()
                        if( typeof defaultParts === 'string' ) defaultParts={ html: defaultParts }

                    } else if ( typeof mainPage.htmlTemplate !== 'undefined' ) {
                        if( typeof mainPage.htmlTemplate === 'string' ) defaultParts={ html: mainPage.htmlTemplate }
                        else defaultParts= {...mainPage.htmlTemplate}
                    }

                    for( let part in html ){
                        if ( part == 'page' || part == 'html' ) renderParts[ '_page_' ]= html[ part ]
                        else if ( defaultParts.hasOwnProperty( part ) ) {
                            renderParts[ '_' + part + '_' ]= html[ part ]
                            delete defaultParts[part ]
                        }
                    }

                    for (let part in defaultParts ) if ( part !== 'html' && part !== 'page') renderParts[ '_' + part + '_' ]= defaultParts[ part ]
                }
            }

            for( let partAnchor in renderParts ){
                let element= window.document.getElementById( partAnchor )
                if ( element ) element.innerHTML= renderParts[ partAnchor ]
            }
        }
    }

    /*  además de una página modal, pueden haber otros componentesUI que sean modales. 
        Se abren y cierran con estas funciones.
        Solo se utiliza _modal_ como ancla y se le redirigen las pulsaciones de teclas al modal en lugar de a la pagina activa.
    */
    modal(id, html){
        this.modalOpened= id
        window.document.getElementById( '_modal_' ).innerHTML= html
    }

    freeModal(){
        this.modalOpened= false
        window.document.getElementById( '_modal_' ).innerHTML = ''
    }

    getCurrPage(){
        if ( this.pageStack.length > 0 ) return this.pageStack[ this.pageStack.length - 1 ].page
        else return false
    }

    /* ??? getModulePage getCurrModuleBasePage ¿ Una página cargada encima de la principal y que sea la inicial del módulo ?? 
        Pila específica por módulo ?
        Modulo solo accesible como componente del manager ?
        UI Modulo con páginas ?
    */
    getMainPage(){
        if ( this.pageStack.length > 0 ) return this.pageStack[ 0 ].page
        else return false
    }

    /* retorna cierto si la página existe (tanto si está dada de alta como clase o como pagina estatica-html ) */
    existsPage( id ){
        return typeof this.getPageClass( id ) === 'function' 
            || this.getHtmlResource( id ) !== false
    }

    open( id, ...params ){ this.loadPage( id, params, {save: true, modal: false} )  }
    openModal( id, ...params ){ this.loadPage( id, params, {save: true, modal: true})  }
    switchTo( id, ...params ){ this.loadPage( id, params, {save: false, modal: false} ) }
    openWithReturn( id, params, ret ){ this.loadPage( id, params, {save: true, modal: false, ret:ret} )  }
    openModalWithReturn( id, params, ret ){ this.loadPage( id, params, {save: true, modal: true, ret: ret}) }

    /** options: 
     *  save (bool): si la página actual la savamos en la pila
     *  ret (fn): callback de retorno cuando se recupere la página de la pila. La enlazamos con la página correspondiente (bind)
     *  modal (bool): si la página que vamos a cargar la cargamos de forma modal. (mantenemos la página actual y esta la montamos en modals con su backdrop)
     */
    loadPage( id, params, options ){
        let page, pageClass, pageHtml
        let stackEntry
        //if( !params ) params=[]
        //if( ! Array.isArray(params) ) params=[ params ]//¿?
        
        if ( typeof id === 'function' ) { // Se remite una clase como Pagina en lugar de su identificador.
            pageClass= id
            page= this.createObject( pageClass, ...params)
            //page.alias= 'remotepage'
            
        } else if ( pageClass= this.getPageClass( id ) ){
            page= this.createObject( pageClass, ...params )
            page.alias= id.toLowerCase() //classDef.name.toLowerCase()
                // Para el resto de componentesUI, el alias se crea en Component.constructor, pero las páginas las manega mr. manager.

        } else if ( pageHtml= this.getHtmlResource( id ) ) {
            pageClass= this.getPageClass( 'StaticPage' )
            page= this.createObject( pageClass, pageHtml, ...params )
            page.alias= id.toLowerCase() 

        } else { 
            /*  No tenemos este id de pagina en local. Preguntamos por el en el servidor.
                Puede que se trate de una query u otra página de tipo dinámico.
            */
           
            if ( typeof _.app.openUnknownPage === 'function' ) _.app.openUnknownPage( id, params, options )
            else {
                let error= `Error. No se encuentra la página '${id}'.`
                console.log( error)
                this.route('showError', {errorMsj: error })
            }
            return false
        }
        //page.id= id, ahora son uids datos por TObject.

        // LA CLASE YA SE HA CREADO. 

        stackEntry= { page: page }
        if ( options.modal ) stackEntry.modal= true
        let currPage= this.getCurrPage()
        if ( options.save ){
            if ( currPage ){
                if ( typeof options.ret === 'function' ){ 
                    stackEntry.ret= options.ret
                } else if ( typeof options.ret === 'string' && typeof currPage[options.ret] === 'function'){
                    stackEntry.ret= currPage[options.ret]
                }
                if (! options.modal ) this.fire( 'hide', null, currPage.id )
            }
            this.pageStack.push( stackEntry )

        } else {
            if( currPage ){
                this.fire( 'unload', null, currPage.id )
                this.removeObject( currPage.id )
            }
            this.pageStack[ this.pageStack.length -1 ]= stackEntry
        
        }

        // LA NUEVA PÁGINA YA ESTÁ EN EL TOP DE LA PILA COMO CLASE ACTUAL.

        // todo: lanzar algo si la página tiene algo que hacer antes de generar el html (usamos el constructor)
        try {
            this.renderPageHtml( page, options.modal, false )
        } catch (error) {
            console.error(error);
            this.returns()
            this.route('showError', {error: 13, errorMsg: `Error al cargar la página ${id}\n${error}`} )
            return false
        }
        //this.fire( 'show', false, page.id ) // con la nueva filosofia es mas bien un awake que un show
        return true

    }

    returns( data ){
        let stackEntry, page
        if ( this.pageStack.length > 1 ){
            let currPage= this.getCurrPage()
            this.fire( 'unload', null, currPage.id )
            this.removeObject( currPage.id )
            stackEntry= this.pageStack.pop()
            page= this.getCurrPage()
            if ( stackEntry.modal ) {
                this.freeModal()
                if ( typeof data !== 'undefined' && typeof page.ret == 'function' ) page.ret.apply( page, data )
                this.fire( 'show', true, page.id )

            } else {
                this.renderPageHtml( page, false, true )
                if ( typeof data !== 'undefined' ) {
                    if ( typeof stackEntry.ret == 'function' ) stackEntry.ret( data )
                    else if ( typeof page.ret == 'function' ) page.ret.apply( page, data )
                }
                this.fire( 'show', true, page.id )
            }

        } else {
        // Cuando no hay donde retornar. Salimos.....
            if( typeof _.app.logout === 'function' ) _.app.logout()
            else console.log( 'No more page to return' )
        }
    }

    //Carga la página de inicio
    goHome(){
        let closedPages= 0

        while( this.pageStack.length > 1 ){
            let currPage= this.getCurrPage()
            this.fire( 'unload', null, currPage.id )
            this.removeObject( currPage.id )
            this.pageStack.pop()
            closedPages++
        }

        if (closedPages){
            this.freeModal()
            let page= this.getCurrPage()
            this.renderPageHtml( page, false, true ) // ¿ render-page + show ????
            this.fire( 'show', true, page.id )
        }

    }

    closeAllPages(){
        while( this.pageStack.length > 0 ){
            let currPage= this.getCurrPage()
            this.fire( 'unload', null, currPage.id )
            this.removeObject( currPage.id )
            this.pageStack.pop()
        }

    }


	request( path, params, options ){
		let dfd= $.Deferred()
		    , body= {}
            , self= this

		body.params= JSON.stringify( params )
		body.options= JSON.stringify( options )
        if ( typeof _.app == 'object' && typeof _.app.getSessionData == 'function' ) body.session= JSON.stringify( _.app.getSessionData() )

		let url= `/api.php/${path}`

		let requestId= ++this.lastRequest
//		if ( 'onRequestStart' in _.app) _.app.onRequestStart( requestId, url, data, new Date() ) 
		
		$.ajax( url,{
			method: 'POST'
			, data: body
		}).done( function( response, status, xhr ){
			let error
			
//			if ( 'onRequestEnd' in _.app ) _.app.onRequestEnd( requestId, new Date() ) 
			try { 
				error= false;
				response= JSON.parse( xhr.responseText );
			} catch (e) { 
				error= true;
				response= {error: 3, errorMsj: 'Error en la respuesta en request ' + path + '. Se esperaba un JSON.' };
			}
			if ( error ) {
                self.route('showError', response )
                dfd.reject( response )
            } else dfd.resolve( response )

		}).fail( function( xhr, status, error ){
			let response
			// Cuando se produce un error lógico aunque falle la respuesta a la request, la respuesta trae el mensaje de error

//			if ( 'onRequestEnd' in _.app ) _.app.onRequestEnd( requestId, new Date() ) 
			// Error por defecto, si no hay conexión con el servidor
			response= {error: 4, errorMsj: 'Error en en request ' + path + '. El servidor no responde.' }
			try { 
				//Error logico devuelto por el servidor.
				if ( xhr.responseText ) response= JSON.parse( xhr.responseText )
			} catch (e) { 
				//Cuando el servidor responde con un mensaje que no es json.
				response= {error: 5, errorMsj: 'Error en la respuesta en request' + path + ' Se esperaba un JSON.' };
			}
            self.route('showError', response )
			dfd.reject( response )

		})
		
		return dfd.promise()

	}

    /** REQUEST GENERICA
        Envía una petición jquery-ajax al servidor
        url: dirección
        options: jquery ajax options 
    */
    genericRequest( url, options ){ 
        var dfd= $.Deferred()
//        dfd.fail( _.app.showError );

        var requestId= ++_.lastRequest
//        if ( 'onRequestStart' in _.app) _.app.onRequestStart( requestId, url, options, new Date() ) 

        $.ajax( url, options )
        .done( function( response, status, xhr ){
//            if ( 'onRequestEnd' in _.app ) _.app.onRequestEnd( requestId, new Date() ) 
            dfd.resolve( response )

        }).fail( function( xhr, status, error ){
            var response
//            if ( 'onRequestEnd' in _.app ) _.app.onRequestEnd( requestId, new Date() ) 
            try { 
                response= JSON.parse( xhr.responseText );
            } catch (e) { 
                response= {error: 5, errorMsj: 'Error en la respuesta en ' + url + '. Se esperaba un JSON.' };
            }
            dfd.reject( response )

        })

        return dfd.promise()
    }

    route(){
        let route= this.getRoute( ...arguments )
        if ( route ) this.runRoute( route ) 
    }
    runRoute( route ){ route.component[ route.method ].apply( route.component, route.params ) }
    getRoute(){
        let id='', route= { component: null, method: null, params: null }

        switch( typeof arguments[0] ){
            case 'string': id= arguments[0]; break // metodo o componente de la página principal o de inicio
            case 'object': // metodo de un componente al que se accede directamente por el nodo en el que está escrito 
                let element= arguments[0]
                while( (! element.id || ! element.id.startsWith('_') || element.id.endsWith('_') ) && element.parentNode ) element= element.parentNode
                if ( element.id && element.id.startsWith('_') && ! element.id.endsWith('_') ) id= element.id
                break
            case 'function': id= arguments[0](); break// id dinámico 
            case 'undefined': id= 'default'; break // Se puede ejecutar la función por defecto de la página actual o de la página de inicio
            default: return false
        }
        if ( !id ) return false

        // Si el id comienza por _ es el identificador del componente, accedemos directamente y ejecutamos el método mandado como segundo parametro
        if( id.substring(0, 1) == '_' ){
            route.component= this.getObject( id )
            if ( route.component && typeof route.component[ arguments[1] ] === 'function'){
                    route.method= arguments[1]
                    route.params= Array.prototype.slice.call(arguments, 2)
                    return route 
            } else return false
        }

        let runner= this.getCurrPage(), step= 0
        while ( step++ < 3 ) {
            if ( typeof runner[ id ] === 'function') { // Metodo _.( 'method', [params] )
    
                route.component= runner
                route.method= id
                route.params= Array.prototype.slice.call(arguments, 1)
                return route

            } else if ( typeof runner[ id ] == 'object' && typeof arguments[1] == 'string' ) { // Componente _.( linked-component, method, params )
                
                route.component= runner[ id ]
                route.method= arguments[1]
                if( typeof route.component[ method ] === 'function'){
                    route.params= Array.prototype.slice.call(arguments, 2)
                    return route
                    
                } else {
                    console.log(`ERROR: No se ha encontrado el método ${method} para el componente ${component} `)
                    return false
                }
            }
            
            switch ( step ){
                case 1: // set runner as main page
                    if ( this.pageStack.length > 1  ) {
                        runner= this.getMainPage()
                        break
                    }
                    step++
                case 2: // set runner as app
                    runner= _.app
            }
        }
        console.log(`ERROR. No se ha encontrado destino para la ruta '${id}'`)
        return false

    }

	keyEvent( event ){
        let route
//		console.log( `key: ${event.key} code: ${event.code}`)
        let curr= this.modalOpened ? this.getObject(this.modalOpened) : this.getCurrPage()
        switch( event.code ){
            case 'Enter': case'NumpadEnter':
                if ( document.querySelector('.modal-open') ) {
                    route= this.getRoute( 'modalDefault' )
                    if ( route.component !== this.getCurrPage() ) route = false 
                } else route= this.getRoute('default')
                if ( route ){
                    this.runRoute( route )
                    event.preventDefault()
                } 
                break

            case 'Escape': 
                // si tenemos un modal abierto, no enrutamos nada.
                if ( ! document.querySelector('.modal-open') ) {
                    route= this.getRoute( 'cancel' )
                    if ( route ){
                        this.runRoute( route )
                        event.preventDefault()
                    } 
                }
                break

        }
        this.fire( 'key-down', event, curr.id )
	}

    checkMinimalMode( refresh ){
        let mm= this.minimalMode
        this.minimalMode= window.innerWidth < 600
        if( this.minimalMode )console.log('Minimal Mode!!')
        if( refresh && mm !== this.minimalMode ){
            let cp= this.getCurrPage()
            if(cp) cp.refresh()
        } 
    }
    

}

export default new Manager()