import {Component} from './Component'
import manager from './Manager'

export class UIComponent extends Component{
    htmlTemplate
    UIComponents= []
    renderParams={} // atributos asociados a este componente que se pasan directamente a la plantilla.
    tag= 'div'
    classname= [':alias:'] 

    addUIComponent(classDef, ...params){
        let UIComponent= super.addComponent(classDef, ...params)
        this.UIComponents.push( UIComponent )
        return UIComponent
    }

    // crea un UIComponent anclado fuera del espacio del componente padre.
    // Se crea su html en el record a renderizar y en lugar de ponerle su nombre se pone el nombre de un ancla
    // Al generar la plantilla automatica de subcomponentes no aparecen los anclados.
    // ¿ Se ancla automáticamente ?
    addAnchoredUIComponent(classDef, anchor, ...params){
        let component= this.addUIComponent( classDef, ...params)
        component.anchor= anchor
        return component
    }

    concatenateComponent_tpt(){
        let tpt= ''
        this.UIComponents.forEach( component => { 
            if ( typeof component.anchor !== 'undefined' ) tpt+= `{{{${component.alias}}}}` 
        })
        return tpt
    }

    getHtmlTemplate(){
        let tpt
        if( typeof this.htmlTemplate !== 'undefined' ) tpt= Object.assign({}, this.htmlTemplate)
        else tpt= manager.getHtmlResource( this.constructor.name )
        if ( typeof tpt === 'object' && typeof tpt.html === 'undefined' ) {
            let defHtmlResource= manager.getHtmlResource( this.constructor.name )
            if (defHtmlResource) tpt.html= defHtmlResource
            else tpt.html= this.concatenateComponent_tpt() 
        }
        return tpt
    }


    html(){
        if(_.DEBUG_) console.log( `COMPONENTE.HTML: ${this.constructor.name} (${this.id})` )
        let tpt, idsetted= false

        {   let static_tpt 
            if( typeof this.htmlTemplate !== 'undefined' ) static_tpt= this.htmlTemplate
            else static_tpt= manager.getHtmlResource( this.constructor.name )
            if( typeof static_tpt === 'string' ) tpt={ html: static_tpt }
            else tpt= Object.assign( {}, static_tpt)
            if(_.DEBUG_) console.log( `STATIC (${this.id}): ` + this.print(static_tpt) )
        }
        {   let dynamic_tpt
            if( typeof this.htmlDynamic === 'function' ) dynamic_tpt= this.htmlDynamic()
            if( dynamic_tpt ){
                if (typeof dynamic_tpt === 'string') Object.assign( tpt, {html: dynamic_tpt} )
                else Object.assign( tpt, dynamic_tpt )
            }
            if(_.DEBUG_) console.log( `DYNAMIC: (${this.id})` + this.print( dynamic_tpt) )
        }
        {
            let implicit_tpt
            if ( typeof tpt.html === 'undefined' ){
                let defHtmlResource= manager.getHtmlResource( this.constructor.name )
                if (defHtmlResource) implicit_tpt= defHtmlResource
                else implicit_tpt= this.concatenateComponent_tpt()
            }
            if (implicit_tpt) tpt.html= implicit_tpt
            if(_.DEBUG_) console.log( `IMPLICIT: (${this.id})` + this.print( implicit_tpt) )
        }
        {
            //let general_tpt. contenido por app, modulo o estandar.
            if ( typeof tpt.html === 'undefined' ){
                tpt.html='<p>Undefined Page Content</p>'
            }
        }
        

        if(_.DEBUG_) console.log( `TPT (${this.id}): ` + this.print(tpt) )

        let ret= this.componentsHtml()
        if(_.DEBUG_) console.log( `COMPONENTS (${this.id}): ` + this.print(ret) )
        if (typeof ret == 'string') ret= {html: html}
        if ( this.renderParams ) Object.assign( ret, this.renderParams )

        let warp=[]
        for (let [part, part_tpt] of Object.entries( tpt )) {
            if( part == 'warp' ) warp.push( part_tpt )
            else ret[ part ]= _.render( part_tpt, ret, this.getClassName( true ) + `/${part}`  )
        }
        // para poder hacer un update de la página principal y que no cambie el layout.
        // Miramos si algún warp tenga el ancla _page_. Si es así, añadimos el identificador de la página antes del warp sino despues.
        for ( let i= 0; i < warp.length; i++ ) {
            if ( warp[i].includes('_page_') ){
                ret.html= this.addIdToHtml( ret.html )
                idsetted= true
            }
            ret['html']= _.render( warp[i], ret , this.getClassName( true ) ) 
        }
        if( !idsetted && typeof ret.html === 'string' && ret.html.length > 0 ) ret.html= this.addIdToHtml( ret[ 'html' ] )
        if(_.DEBUG_) console.log( `RESULT (${this.id}) :` + this.print(ret) )

        return ret
                  
    }

    print( a ) {
        if (typeof a === 'string') return a
        else return JSON.stringify( a )
    }


    getClassName( onlyId= false ){
        let classname=''
        let self= this
        this.classname.forEach((cname)=> {
            if ( cname == ':alias:' ){
                if ( self.alias ) classname+= ` ${self.alias}`
            } else classname+= ` ${cname}`
        })
        if ( classname ) {
            if ( onlyId ) return classname
            else return ` class= "${classname}"`
        } else return ''
    }

    addClassName(classname){ this.classname.push(classname)}
    setClassName(classname){ this.classname= classname}
    clearClassName(){ this.classname= []}

    // TODO 
    // Añade el id del componente al primer elemento que aparezca en el texto html 
    addIdToHtml( html ){
        let classname= this.getClassName()
        if ( this.tag !== 'none'){
            return `<${this.tag} id="${this.id}"${classname}>${html}</${this.tag}>`
        } else return html
    }

    componentsHtml(){
        let html, mainHtml, part, anchor
        mainHtml= {}
        this.UIComponents.forEach( component => {
            if( typeof component.html === 'function' ){

                html= component.html()
                anchor= typeof component.anchor !== 'undefined' ? component.anchor : component.alias
                if ( html && typeof html === 'string' ){
                    mainHtml[ anchor ]= html
                }
                if ( html && typeof html === 'object' ){
                    
                    for( let part in html ){
                        if ( part == 'html' ) mainHtml[ anchor ]= html[ part ]
                        else mainHtml[ part ]= html[ part ]
                    }
                }
                
            }
        }) 
        return mainHtml
    }

    refresh(){
        let html= this.html(), part, element
        if ( typeof html !== 'object' ) html.html= html
        for( part in html ){
            if ( part == 'html' || part == 'page' ) {
                element= document.getElementById( this.id )
                if ( element ) element.outerHTML= html[ part ]
                else {
                    if( _.DEBUG_ ) console.log(`COMPONENT. REFRESH. ID: '${this.id}'`)
                }
            } else {
                let element= window.document.getElementById( `_${part}_` )
                if ( element ) element.innerHTML= html[ part ]
            }
        }
    }


}