import manager from '../platform/Manager'
import {UIComponent} from '../platform/UIComponent'
import layout from './layout.html'
import home from './home.html'
import modals from '../comun/modals/modals.html'
import {Dialog} from '../comun/modals/Dialog'

export class Operaciones extends UIComponent{
    renderCount= 0

    constructor(id, datosSesion){
        super(id)
        this.datosSesion= datosSesion
        this.menu= this.addUIComponent( manager.getClass( 'Menu' ) )
        this.menu.setAlias('menu')
        this.menu.addItem( "_.open('AgendaLista')", 'AGENDAS' , 'Lista de Agendas pendientes de registrar.', 'A' )
        this.menu.addItem( "_.open('Salones')", 'SALONES' , 'Listado de salones (priv).', 'S' )
//        this.menu.addItem( "_.open('Salones')", 'SALONES' , 'Listado de salones (priv).', 'L' )
        this.renderParams={ empresa: datosSesion.nombre_empresa, usuario: datosSesion.usuario, modals: modals }
        this.showErrorDlg= this.addComponent(Dialog, 1)
        
    }


     htmlDynamic(){
        let htmlTemplate= {
             html: home
        }
    
        if ( !this.renderCount++ ){
            htmlTemplate.wrap= layout
        }

        return htmlTemplate
    }

    showError( err ){
        if ( err.errorMsg ) err.errorMsj= err.errorMsg 
        if ( ! document.querySelector('.modal-open') ) { // miramos que no hubiese otro dialogo mostrandose}
            this.showErrorDlg.show( err ) 
        }
    }

    
}