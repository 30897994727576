import manager from '../../platform/Manager'
import {UIComponent} from '../../platform/UIComponent'
import html from './InsertarMultipuesto.html'

export class GTM_InsertarMultipuesto extends UIComponent{
    htmlTemplate= html

    chequea(){
        let cod_gestimaq= document.getElementById( this.id + '_cod' ).value.trim()
        manager.request('DB/procedure/run/migracion__testea_insercion', [cod_gestimaq])
        
    }

    inserta(){

    }
}