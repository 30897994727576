export default class {

    sessionData={}

    setSessionData( data ){ this.sessionData= data  }
    getSessionData(){ return this.sessionData  }

    exportCsv( base64data ){
        let byteCharacters = atob( base64data )
        let byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        let byteArray = new Uint8Array(byteNumbers)
        let file = new Blob([byteArray], { type: 'text/csv;base64' })
        let fileURL = URL.createObjectURL(file)
        window.open(fileURL)
    }

	showError( err ){
        console.log( err )
	}






}