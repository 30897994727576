// Module
var code = `<div class="container">
    <h4>INSERTAR MULTIPUESTO</h4>
    <form id="{{id}}_form" class="bg-secondary-subtle p-3">

        <div class="row pb-3">

            <div class="col-8">
                <label for="{{id}}_cod" class="form-label">Cód. Gestimaq</label>
                <input  class="form-control" id="{{id}}_cod"  />
            </div>
            
        </div>

    </form>

    <nav class="d-flex pt-3">
        <div class="ms-auto">
            <button class="btn btn-secondary me-2" onclick="_('chequea')"><i class="bi bi-check"></i> CHEQUEAR</button>
            <button class="btn btn-primary" onclick="_('inserta')" disabled><i class="bi bi-plus"></i> INSERTAR</button>
        </div>
    </nav>

</div>
    



`;
// Exports
export default code;