import { Lista } from '../../platform/pages/Lista'
import actions_html from '../../platform/pages/lista-actions.html'

export class FabricanteLst extends Lista{

    constructor(id){
        super( id, 'Fabricante' )
        this.renderParams= { actions: actions_html}
    }

}
 