import manager from '../../platform/Manager'
import {UIComponent} from '../../platform/UIComponent'
import html from './login.html'
import htmlError from './login-error.html'

export class Login extends UIComponent{
    htmlTemplate= html

    constructor(id){
        super(id)
        this.renderParams={id:id}
    }

    login(){
        let data= _.dom.getFormData( 'login' )
        manager.request('/DB/procedure/run/sesion__inicio_pub', [data.usuario, data.clave])
        .done( (response) => {
            _.app.setSessionData( {token: response[0][0].token })
            switch( response[0][0].perfil ){
                case 'SAT':  _.switchTo( 'Sat', response[0][0]); break
                case 'OPERACIONES': _.switchTo( 'Operaciones', response[0][0]); break
                case 'CONTABLE': _.switchTo( 'Contable', response[0][0]); break
                case 'ADMIN': _.switchTo( 'Admin', response[0][0]); break
                case 'DOCUMENTACION': _.switchTo( 'Documentacion', response[0][0]); break
                case 'SALON':  _.switchTo( 'Salon', response[0][0]); break
                case 'GESTOR': _.switchTo( 'Gestor', response[0][0] ); break

                case 'RECAUDACION':
                case 'CONSULTOR':
                default: _.switchTo( 'SinPerfil', response[0][0] )
            } 
        })
    }

    showError( err ){
        if ( err.errorMsg ) err.errorMsj= err.errorMsg 
        let html= _.render( htmlError, Object.assign( {id: this.id} , err ) )
        $( '#' + this.id +'_error' ).append( html )
    }

    default(){ this.login() }

}