// Module
var code = `<div class="container">
    <h4 id="{{id}}_ubicacion"></h4>
    <h4 id="{{id}}_grupo"></h4>
    <h4>Maquinas</h4>

    {{{list}}}

    <div class="row">
        <div class="col">
            <ul class="nav justify-content-start ">
                <li class="nav-item"><a class="text-primary" onclick="_('previous')"><i class="bi-skip-start" style="font-size: 2rem;"></i></a></li>
                <li class="nav-item"><a class="text-primary" onclick="_('next')"><i class="bi-skip-end" style="font-size: 2rem; "></i></a></li>
            </ul>
        </div>
        <div class="col">
            {{{acciones}}}
        </div>
    </div>


</div>`;
// Exports
export default code;