// Module
var code = `<div class="container">
    <h3>Pruebas de formularios modales</h3>
    <h4 class="pt-3">Testea Predefinido</h4>
    <p>Dialogo predefinido</p>
    <nav class="d-flex"><button class="ms-auto btn btn-secondary" onclick="_('test')">TEST</button></nav>
    <h4 class="pt-3">Testea Custom OK</h4>
    <p>Dialogo customizable OK</p>
    <nav class="d-flex"><button class="ms-auto btn btn-secondary" onclick="_('customOK')">TEST</button></nav>
    <h4 class="pt-3">Testea Custom SINO</h4>
    <p>Dialogo customizable OK</p>
    <nav class="d-flex"><button class="ms-auto btn btn-secondary" onclick="_('customSINO')">TEST</button></nav>
    <h4 class="pt-3">Testea Custom ACCIONES</h4>
    <p>Dialogo customizable OK</p>
    <nav class="d-flex"><button class="ms-auto btn btn-secondary" onclick="_('customAcciones')">TEST</button></nav>
    <h4 class="pt-3">Show Error</h4>
    <p>Formulario 01 de muestra de errores a nivel página principal</p>
    <nav class="d-flex"><button class="btn btn-danger ms-auto" onclick="_('showError')">SHOW ERROR</button></nav>
    <h4 class="pt-3">Upload Document</h4>
    <p>Formulario 02 subida de documentos a la base de datos</p>
    <nav class="d-flex"><button class="btn btn-primary ms-auto" onclick="_('upload')">UPLOAD</button></nav>
    <hl class="py-3"></hl>
</div>`;
// Exports
export default code;