// Module
var code = `<div class="container bg-secondary-subtle">
    <form id="{{id}}_form">

<!-- 
    ENUM 
    En la propiedad de la pagina selects se crea un array con el nombre del campo y contenido la lista de posibles valores de texto.
    En la Base de datos de tratan como enteros. Y punto.   
-->
        <div class="row pb-3">
            <div class="col-6">
                <label for="{{id}}_tipo" class="form-label">Tipo de ubicación</label>
                <select class="form-select" id="{{id}}_tipo" onblur="_('validate', {id:'tipo'})">
                    <option value="0" {{tipo_sel_0}}>SALON</option>
                    <option value="1" {{tipo_sel_1}}>BAR</option>
                    <option value="2" {{tipo_sel_2}}>HOTEL</option>
                    <option value="3" {{tipo_sel_3}}>ALMACEN</option>
                    <option value="4" {{tipo_sel_4}}>BINGO</option>
                  </select>
            </div>
        </div>
    
<!-- 
    INPUT NORMAL
    Usa col-n en lugar de col para darle el espacio correcto (entre 12)
    Debe tener permiso de update para el campo en la tabla permisos__update para el perfil actual.
-->
    
        <div class="row pb-3">

            <div class="col">
                <label for="{{id}}_cod_gestimaq" class="form-label">Cod. Gestimaq</label>
                <input class="form-control" id="{{id}}_cod_gestimaq" value="{{cod_gestimaq}}" onblur="_('validate', {id:'cod_gestimaq'})">
            </div>

        </div>

<!--
    LOOKUP
    Ponemos el código como lookup y el nombre en la segunda columna.
    Para los lookup se están utilizando el validate concreto para el campo, porque está programado para recibir un texto y autocompletarlo.
-->
        <div class="row pb-3">
            <div class="col-3">
                <label for="{{id}}_zona" class="form-label">Zona</label>
                <div class="input-group">
                    <input class="form-control" id="{{id}}_zona" onblur="_('validate', {id:'zona'})" value="{{zona}}">
                    <button class="btn btn-secondary" type="button" onclick="_('lookup', {id:'zona'} )" ><i class="bi bi-three-dots"></i></button>
                </div>
            </div>

            <div class="col-9">
                <label for="{{id}}_zona_nombre" class="form-label">Nombre zona</label>
                <input class="form-control" id="{{id}}_zona_nombre" readonly value="{{zona_nombre}}">
            </div>
        </div>


<!--
    UPLOAD
-->

    </form>
    </div>
    `;
// Exports
export default code;