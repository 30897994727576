// Module
var code = `<div class="border-top border-light bg-secondary-subtle pt-3">

    <div class="d-flex pb-3">
        <span class="me-auto">
            <span class="badge fs-5 text-bg-secondary"><strong>{{no_puesto_agenda}}</strong></span>
{{#maquina_nueva}}
            <span class="fs-5 text-secondary mx-3"><strong>- MAQUINA NUEVA -</strong></span>
{{/maquina_nueva}}
{{^maquina_nueva}}
            <span class="fs-5 text-secondary mx-3"><strong>MAQUINA {{alias_maquina}}</strong></span>
            <span class="badge px-3 text-bg-secondary">{{alias_grupo}} - {{no_puesto_grupo}}</span>
{{/maquina_nueva}}
        </span>
        <a class="btn btn-primary" onclick="_('mueveMaquina', {{no_puesto_agenda}}, -1)"><i class="bi bi-arrow-up"></i></a>
        <a class="btn btn-primary ms-1" onclick="_('mueveMaquina',{{no_puesto_agenda}}, 1)"><i class="bi bi-arrow-down"></i></a>
        <a class="btn btn-danger ms-1" onclick="_('quitaMaquina',{{no_puesto_agenda}})"><i class="bi bi-trash"></i></a>
    </div>

    <div class="row pb-3">
        <div class="col-3">
            <label for="{{idx}}_modelo" class="form-label">Modelo</label>
            <div class="input-group">
                <input class="form-control" id="{{idx}}_modelo" onblur="_('validate', {set:{{set}}, row:{{row}}, id:'modelo'})" value="{{modelo}}">
                <button class="btn btn-secondary" type="button" onclick="_('lookup', {set:{{set}}, row:{{row}}, id:'modelo'} )" ><i class="bi bi-three-dots"></i></button>
            </div>
        </div>
        <div class="col-9">
            <label for="{{idx}}_modelo_nombre" class="form-label">Nombre modelo</label>
            <input class="form-control" id="{{idx}}_modelo_nombre" readonly value="{{modelo_nombre}}">
        </div>
    </div>

    <div class="row pb-3">
        <div class="col-3">
            <label for="{{idx}}_mueble" class="form-label">Mueble</label>
            <div class="input-group">
                <input class="form-control" id="{{idx}}_mueble" onblur="_('validate', {set:{{set}}, row:{{row}}, id:'mueble'})" value="{{mueble}}">
                <button class="btn btn-secondary" type="button" onclick="_('lookup', {set:{{set}}, row:{{row}}, id:'mueble'} )" ><i class="bi bi-three-dots"></i></button>
            </div>
        </div>
        <div class="col-9">
            <label for="{{idx}}_mueble_nombre" class="form-label">Nombre mueble</label>
            <input class="form-control" id="{{idx}}_mueble_nombre" readonly value="{{mueble_nombre}}">
        </div>
    </div>

    <div class="row pb-3">
        <div class="col-3">
            <label for="{{idx}}_juego" class="form-label">Juego</label>
            <div class="input-group">
                <input class="form-control" id="{{idx}}_juego" onblur="_('validate', {set:{{set}}, row:{{row}}, id:'juego'})" value="{{juego}}">
                <button class="btn btn-secondary" type="button" onclick="_('lookup', {set:{{set}}, row:{{row}}, id:'juego'} )" ><i class="bi bi-three-dots"></i></button>
            </div>
        </div>
        <div class="col-9">
            <label for="{{idx}}_juego_nombre" class="form-label">Nombre juego</label>
            <input class="form-control" id="{{idx}}_juego_nombre" readonly value="{{juego_nombre}}">
        </div>
    </div>

    <div class="row pb-3">
        <div class="col">
            <label for="{{idx}}_acuerdo" class="form-label">Acuerdo</label>
            <input class="form-control" id="{{idx}}_acuerdo" onblur="_('validate', {set:{{set}}, row:{{row}}, id:'acuerdo'})" value="{{acuerdo}}">
        </div>
        <div class="col">
            <label for="{{idx}}_importe" class="form-label">Importe</label>
            <input class="form-control" id="{{idx}}_importe" value="{{importe}}" onblur="_('validate', {set:{{set}}, row:{{row}}, id:'importe'})">
        </div>
    </div>

    <div class="row pb-3">
        <div class="col-3">
            <label for="{{idx}}_observaciones" class="form-label">Observaciones</label>
            <input class="form-control" id="{{idx}}_observaciones" onblur="_('validate', {set:{{set}}, row:{{row}}, id:'observaciones'})" value="{{observaciones}}">
        </div>
    </div>

</div>`;
// Exports
export default code;