import {UIComponent} from '../../platform/UIComponent'
import html from './maquina.html'

export class Maquina extends UIComponent{
    tag= false

    constructor( id, ficha ){
        super(id)
        this.ficha= ficha
        //maquina['maquina_nueva']= ! maquina.maquina && typeof maquina.maquina === 'object'
    }


    getHtmlTemplate(){ 
        let tpt= {html: ''}
        if ( this.ficha.data && this.ficha.data.maquina ){
            for (let m= 0; m < this.ficha.data.maquina.length; m++){
                let id= 'MAQ' + m
                tpt.html+= '{{{' + id + '}}}'
                tpt[id]= html
            }
            return tpt 

        } else return {html: ''}
    }


    getRenderParams( id ){
        if( id.substring(0,3) == 'MAQ' ){
            let m= parseInt( id.substring(3) )
            return Object.assign( {idx: [this.ficha.id, 'maquina', m ].join('_'), set: '"maquina"', row: m}, this.ficha.data.maquina[m]  )
        } else return {}
    }
}
