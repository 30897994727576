import manager from '../../platform/Manager'
import {Ficha} from '../../platform/pages/Ficha'
import html from './ficha.html'
import html_nuevo from './ficha-nueva.html'

export class Usuario extends Ficha{
    selects= {
        impresora: ['', 'MAIN', 'SAT-GC' , 'SAT-LZ', 'SAT-TF', 'SAT-PM']
        , perfil: ['ADMIN', 'CONTABLE', 'CONSULTOR', 'DOCUMENTACION', 'GESTOR', 'OPERACIONES', 'RECAUDACION', 'SAT']
    }


    constructor (id, usuario){
        super(id, { procView: 'usuario__ficha', updateTable: 'usuario', htmlCreate: html_nuevo, htmlEdit: html }, usuario)
    }

    calculaCuenta(){
        let nombre= this.getValor('nombre')
            , apellidos= this.getValor('apellidos')
            , cuenta= this.getValor('usuario')
            , fromchars='áéíóúüñ', tochars='aeiouun'
        if( cuenta == '' ){
            cuenta= nombre.substring(0,1).toLowerCase()
            cuenta+= apellidos.indexOf(' ') == -1 ? apellidos.toLowerCase() 
                    : apellidos.substring( 0, apellidos.indexOf(' ') ).toLowerCase()
            for( let i=0; i < fromchars.length; i++){
                if ( cuenta.indexOf( fromchars[i] >= 0) ) cuenta= cuenta.replaceAll( fromchars[i], tochars[i]) 
            }  
            this.setValor('usuario', cuenta)
        }
    }
  
    crear(){
        let nombre= this.getValor('nombre')
            , apellidos= this.getValor('apellidos')
            , usuario= this.getValor('usuario')
            , self= this
        manager.request('DB/procedure/run/usuario__crea',[usuario, nombre, apellidos])
        .done( response => {
            self.mode= 'edit'
            self.receiveDataAndRefresh( response )
        })
    }

    prepareRenderParams( params ){
        if (this.data ){

            if ( this.data.perfil ) {
                let ix= this.selects['perfil'].indexOf( this.data.perfil )
                if ( ix >= 0 ) params[ 'perfil_sel_' + ix ] = 'selected'
            }

            if ( this.data.impresora ) {
                let ix= this.selects['impresora'].indexOf( this.data.impresora )
                if ( ix >= 0 ) params[ 'impresora_sel_' + ix ] = 'selected'
            }
        }
        return params
    }

    

    getClave(){ return this.data.usuario }


    validate( ){
        let validando = arguments[0]
            , self= this
        if (typeof validando == 'string') validando= {id: validando}
        if (typeof validando.valor == 'undefined'){
            validando.valor= this.getValor( validando.id )
        }
        switch( validando.id ){
            case 'empresa': case 'zona':

                if ( validando.valor == '') validando.valor= null
                if ( validando.valor !== this.data[validando.id] ){
                    manager.request('DB/procedure/run/usuario__valida_' + validando.id, [this.data.usuario, validando.valor] )
                    .done( response => {
                        self.setValor( validando.id, response[0][0][validando.id])
                        self.setValor( validando.id + '_nombre', response[0][0].nombre)
                        self.data[validando.id]= response[0][0][validando.id]
                        self.data[validando.id +'_nombre']= response[0][0].nombre
                    })
                }
                break;

            default: super.validate( validando ) 
        }
    }



}
