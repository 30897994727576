// Module
var code = `<div class="dialog">
    <div >
        <h1  >Acceso</h1>
    </div>
    <form name="login">
        <div class="">
            <label for="{{id}}_U" class="form-label">Usuario</label>
            <input type="input" class="form-control" name="usuario" id="{{id}}_U">
        </div>
        <div class="">
            <label for="{{id}}_C" class="form-label">Clave</label>
            <input type="password" class="form-control" name="clave" id="{{id}}_C">
        </div>
        <div id="{{id}}_error" ></div>
    </form>
    <nav class="navbar mt-3">
        <button type="button" class="btn btn-primary" onclick="_('login')" >ACEPTAR</button>
    </nav>
</div>
`;
// Exports
export default code;