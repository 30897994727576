// Module
var code = `<header class="p-3 mb-3 border-bottom">
    <div class="container">
        <div class="d-flex flex-wrap align-items-center justify-content-start justify-content-lg-start">

            <a class="mb-2 mb-lg-0 link-body-emphasis text-decoration-none"  onclick="_.home()">
                <img src="logo-pama.svg" width="48" height="48" class="rounded-circle flex-shrink-0">
            </a>

            <span class="flex-grow-1" id="_zona_">{{zona}}</span>

            <div class="dropdown text-end">
                <a href="#" class="d-block text-pama link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    {{usuario}}
                </a>
                <ul class="dropdown-menu text-small" >
                <li><a class="dropdown-item" href="#">Configuración</a></li>
                <li><a class="dropdown-item" href="#">Profile</a></li>
                <li><hr class="dropdown-divider"></li>
                <li><a class="dropdown-item" href="#" onclick="_('logout')">Salir</a></li>
                </ul>
            </div>
        </div>
    </div>
</header>    

<div id="_page_">{{{html}}}</div>
<div id="_modals_">{{{modals}}}</div>

<footer class="py-3 my-4 border-top">
    <div class="d-flex container">
    <div class="w-100">
        <a href="/" class="mb-3 me-2 mb-md-0 text-body-secondary text-decoration-none lh-1">
            <img src="logo-pama.svg" width="48" height="48" class="rounded-circle flex-shrink-0">
        </a>
        <span class="mb-3 mb-md-0 text-body-secondary">© 2024 Grupo Juan Padrón</span>
    </div>
    <nav class="">
        <button class="btn btn-secondary" onclick="_.returns()">VOLVER</button>
    </nav>

    </div>
</footer>
`;
// Exports
export default code;