import manager from './platform/Manager'

export default class {

    sessionData={}

    setSessionData( data ){ this.sessionData= data  }
    getSessionData(){ return this.sessionData  }

    exportCsv( base64data ){
        let fileURL = URL.createObjectURL( this.prepareToExport( base64data, 'text/csv;base64' ))
        window.open(fileURL)
    }

    exportPdf( pdfdata ){
        let fileURL = URL.createObjectURL( this.prepareToExport( pdfdata, 'application/pdf' ))
        window.open(fileURL)
    }

    export ( data, mimetype, filename ){
        let abreEnVentana= false
        let binaryContent = atob( data ) // Decodificar desde base64
        let byteNumbers = new Uint8Array(binaryContent.length) //Uint8Array son datos binarios puros, el string en js es utf16, por lo que no se puede usar y hay que hacer esta transformación
        for (let i = 0; i < binaryContent.length; i++) {
            byteNumbers[i] = binaryContent.charCodeAt(i)
        }
        let blob = new Blob([byteNumbers], { type: mimetype })
        let fileURL = URL.createObjectURL(blob)
        if ( abreEnVentana ){
            window.open(fileURL);
        } else { //Descarga con un nombre de fichero específico 
            let a = document.createElement('a')
            a.href = fileURL
            a.target = '_blank' //para que abra en otra ventana si no le ponemos download para el nombre del fichero
            //a.download = filename || 'archivo.pdf' // Asignar un nombre al archivo
            document.body.appendChild(a) // Agregar el enlace temporalmente al DOM
            a.click() // Simular un clic para iniciar la descarga
            document.body.removeChild(a) // Eliminar el enlace del DOM
        }

    }

    prepareToExport( data, mimetype ){
    /*    let byteCharacters = atob( data )
            , byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) byteNumbers[i] = byteCharacters.charCodeAt(i)
        let byteArray = new Uint8Array(byteNumbers)
    */
        return new Blob([data], { type: mimetype })
    }

	showError( err ){
        console.log( '[app.showError]' + JSON.stringify( err ) )
	}

    cancel(){ manager.returns() }

 /**
  * Cuando se intenta salir de la última página. O se pica en el botón salir del menu
  * Debe terminar la sesión y cargar la página ByeBye
  */
    logout(){
        manager.request('/DB/procedure/run/sesion__finaliza')
        .done( response => {
            manager.closeAllPages()
            manager.open( 'ByeBye' )
        })
    }

}