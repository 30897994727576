// Module
var code = `<div class="container">
    <h1>AGENDAS</h1>
    {{{list}}}

    <ul class="nav justify-content-start">
        <li class="nav-item"><a class="nav-link"  onclick="_('previous')">ANT</a></li>
        <li class="nav-item"><a class="nav-link"  onclick="_('next')">SIG</a></li>
        <li class="nav-item"><a class="nav-link"  onclick="_('ficha')">FICHA</a></li>
        <li class="nav-item"><a class="nav-link text-danger" onclick="_('elimina')">- QUITAR</a></li>
        <li class="nav-item"><a class="nav-link"  onclick="_.returns()">VOLVER</a></li>
    </ul>


</div>`;
// Exports
export default code;