// Module
var code = `<div class="container bg-secondary-subtle">
    <form id="{{id}}_form">

        <div class="row mb-3">
            <div class="col">
                <label for="{{id}}_usuario" class="form-label">Cuenta de usuario</label>
                <input class="form-control" id="{{id}}_usuario" readonly value="{{usuario}}"/>
            </div>
    
            <div class="col">
                <label for="{{id}}_perfil" class="form-label">Perfil</label>
                <select class="form-select" id="{{id}}_perfil" onblur="_('validate', {id:'perfil'})">
                    <option value="0" {{perfil_sel_0}}>ADMIN</option>
                    <option value="1" {{perfil_sel_1}}>CONTABLE</option>
                    <option value="2" {{perfil_sel_2}}>CONSULTOR</option>
                    <option value="3" {{perfil_sel_3}}>DOCUMENTACION</option>
                    <option value="4" {{perfil_sel_4}}>GESTOR</option>
                    <option value="5" {{perfil_sel_5}}>OPERACIONES</option>
                    <option value="6" {{perfil_sel_6}}>RECAUDACION</option>
                    <option value="7" {{perfil_sel_7}}>SAT</option>
                  </select>
            </div>
        </div>


        <div class="col">
            <label for="{{id}}_nombre" class="form-label">Nombre</label>
            <input class="form-control" id="{{id}}_nombre" value="{{nombre}}" onblur="_('validate', {id:'nombre'})"/>
        </div>
    
        <div class="mb-3">
            <label for="{{id}}_apellidos" class="form-label">Apellidos</label>
            <input class="form-control" id="{{id}}_apellidos" value="{{apellidos}}" onblur="_('validate', {id:'apellidos'})"/>
        </div>
    
        
        <div class="row mb-3">
            <div class="col-8">
                <label for="{{id}}_email" class="form-label">Correo electrónico</label>
                <input  class="form-control" id="{{id}}_email" onblur="_('validate', {id:'email'})" value="{{email}}"/>
            </div>
            
            <div class="col-4">
                <label for="{{id}}_telefono" class="form-label">Teléfono</label>
                <input  class="form-control" id="{{id}}_telefono" onblur="_('validate', {id:'telefono'})" value="{{telefono}}"/>
            </div>
        </div>
    
        <div class="row mb-3">
            <div class="col-3">
                <label for="{{id}}_empresa" class="form-label">Empresa</label>
                <div class="input-group">
                    <input class="form-control" id="{{id}}_empresa" onblur="_('validate', {id:'empresa'})" value="{{empresa}}">
                    <button class="btn btn-secondary" type="button" onclick="_('lookup', {id:'empresa'} )" ><i class="bi bi-three-dots"></i></button>
                </div>
            </div>
            <div class="col-9">
                <label for="{{id}}_empresa_nombre" class="form-label">Nombre empresa</label>
                <input class="form-control" id="{{id}}_empresa_nombre" readonly value="{{empresa_nombre}}">
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-3">
                <label for="{{id}}_zona" class="form-label">Zona</label>
                <div class="input-group">
                    <input class="form-control" id="{{id}}_zona" onblur="_('validate', {id:'zona'})" value="{{zona}}">
                    <button class="btn btn-secondary" type="button" onclick="_('lookup', {id:'zona'} )" ><i class="bi bi-three-dots"></i></button>
                </div>
            </div>
            <div class="col-9">
                <label for="{{id}}_zona_nombre" class="form-label">Nombre zona</label>
                <input class="form-control" id="{{id}}_zona_nombre" readonly value="{{zona_nombre}}">
            </div>
        </div>

        <div class="pb-3">
            <label for="{{id}}_impresora" class="form-label">Impresora</label>
            <select class="form-select" id="{{id}}_impresora"  onblur="_('validate', 'impresora')">
                <option value="0" {{impresora_sel_0}}></option>
                <option value="1" {{impresora_sel_1}}>MAIN</option>
                <option value="2" {{impresora_sel_2}}>SAT-GC</option>
                <option value="3" {{impresora_sel_3}}>SAT-LZ</option>
                <option value="4" {{impresora_sel_4}}>SAT-TF</option>
                <option value="5" {{impresora_sel_5}}>SAT-PM</option>
              </select>
        </div>

    
    </form>
    </div>
    `;
// Exports
export default code;