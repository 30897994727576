import { Lista } from '../../platform/pages/Lista'
import html_actions from './lookup-actions.html'

export class Lookup extends Lista{

    constructor(id, query, title){
        super( id, query )
        if( typeof title == 'undefined' ) title= this.query.id
        this.query.id+= '_lookup'
        this.renderParams={title: title, actions: html_actions}
    }


    retornaSelected(){
        let select= this.list.getSelectedKeys()
        if( select.length == 1 ) _.returns( select[0] )
        else _.returns( false )
    }

    default(){ this.retornaSelected() }

}