import manager from '../platform/Manager'
import html from './upload.html'
import { Ficha } from '../platform/pages/Ficha'
import { Dialog } from '../comun/modals/Dialog'
import dom from '../platform/libs/Dom'

export default class extends Ficha{
    htmlTemplate= html
    agenda

    constructor(id){
        let agenda= 18
        super(id, {
            procView: 'agenda__ficha_certificado'

        }, agenda )
        this.agenda= agenda
        this.uploadDlg= this.addComponent( Dialog, 2)
//        this.renderParams={id:id}
    }

    doupload( element ){
        let self= this
            , enabledTypes= ['application/pdf','text/csv']

        if ( element.files.length ){
            let file= element.files[0], reader= new FileReader()
            if ( enabledTypes.includes( file.type )){

                reader.onload = () => {
                    let base64Content= reader.result.split(',')[1] // Obtén solo la parte base64 del resultado
                    manager.request(`/DB/upload/run/agenda/${this.agenda}/certificado`, {
                        content: base64Content, // Enviar directamente la base64
                        filename: file.name,
                        filetype: file.type
                    }).done( response => {
                        console.log(response[0][0])
                        document.getElementById( self.id + '_certificado').value= response[0][0].documento
                        dom.createTextNodes([{id: self.id + '_certificado_nombre' , text: response[0][0].nombre}])
                        this.uploadDlg.hide()
                    })
                }
                reader.readAsDataURL(file) // Leer el archivo como una URL base64

            } else {
                this.uploadDlg.hide()
                manager.route('showError', {
                    errorMsg: `Los ficheros de tipo '${file.type}' no están permitidos.
                    Los ficheros permitidos son: ${enabledTypes}.`
                })
            }
        }
    }

    upload( id ){
        console.log( 'upload ' + id )
        document.getElementById('_M02_upload').value= null
        this.uploadDlg.show({
            titulo: 'Super certificado el fabricante'
        })
    }

    download( id ){ //tabla, clave, campo 
        console.log( 'download ' + id )
        manager.request(`DB/download/run/agenda/${this.agenda}/` + id )
        .done(response => { _.app.export( response[0][0].contenido, response[0][0].mime_type)}) 
        
    }


    /* todo: 
        - dejar solo pdfs para los documentos
        - implementar download.php
        - terminar estado-3 con el download
        - generalizar en ficha.
    */

}