// Module
var code = `<!-- FILTERS -->

<div class="row mb-3">
    <div class="col-3">
        <label for="{{id}}_fabricante" class="form-label">Fabricante</label>
        <div class="input-group">
            <input class="form-control" id="{{id}}_fabricante" onblur="_('{{id}}', 'validate', {id:'fabricante'})" value="{{fabricante}}">
            <button class="btn btn-secondary" type="button" onclick="_('{{id}}', 'lookup', {id:'fabricante'} )" ><i class="bi bi-three-dots"></i></button>
        </div>
    </div>
    <div class="col-9">
        <label for="{{id}}_fabricante_nombre" class="form-label">Nombre fabricante</label>
        <input class="form-control" id="{{id}}_fabricante_nombre" readonly value="{{fabricante_nombre}}">
    </div>
</div>
`;
// Exports
export default code;