import manager from '../../platform/Manager'
import {Ficha} from '../../platform/pages/Ficha'
import html from './ficha.html'
import html_nuevo from './ficha-nueva.html'

export class Fabricante extends Ficha{

    constructor (id, fabricante){
        super(id, { procView: 'fabricante__ficha', updateTable: 'fabricante', htmlCreate: html_nuevo, htmlEdit: html }, fabricante)
    }

    crear(){
        let nombre= this.getValor('nombre')
            , self= this
        manager.request('DB/procedure/run/fabricante__crea', [nombre])
        .done( response => {
            self.mode= 'edit'
            self.receiveDataAndRefresh( response )
        })
    }

    getClave(){ return this.data.fabricante }



}
