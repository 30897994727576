import $ from 'jquery'
import manager from '../../platform/Manager'
import {UIComponent} from '../../platform/UIComponent'
import htmltpt from './menu.html'
import {Item} from './Item'

export class Menu extends UIComponent {
    items=[]

    constructor(id){
        super(id)
        manager.addListener( 'key-down', this.id,  'onKeyDown')

    }

    onKeyDown( event ){
        for( let i= 0; i < this.items.length; i++ ){
            if ( event.key.toLowerCase() === this.items[i].tecla.toLowerCase() ) eval( this.items[i].comando )
        }
        //console.log( event )
    }

    htmlDynamic(){
        let html= ''
        this.items.map( (item) => html+= item.component.html().html )
        return _.render( htmltpt, { items: html })
    }

    addItem( comando, titulo, descripcion, tecla ){
        let item={ 
            comando: comando
            , tecla: tecla
            , component: this.addUIComponent( Item, comando, titulo, descripcion, tecla )
        }, ix= this.items.push( item )
        this.items[ ix-1 ].component.setAlias ( 'item'+ix )
    }

    /** Incluye un badget de un color específico bootstrap primary, secondary, danger...
     * 
     */ 
    setBadged( ix, text, color ){
        let span= document.getElementById( this.items[ix].component.id + '_B' )
        if ( this.items[ix].color ) $( span ).removeClass( 'text-bg-' + this.items[ix].color )
        this.items[ix].color= color
        $( span ).addClass( 'text-bg-' + this.items[ix].color ).text( text )
    }

}