/* PAGE SET 
    Es un conjunto fijo de records extraidos de un recordset y con su definición.
    No se actualiza.
    Se utiliza para almacenar y recorrer la página actual del recordset.
    Y posiblemente para almacenar los registros seleccionados.
    Es la clase ( o interfase ) que se usa para recorrer los registros de la página actual.

    reset()
    next()
    eop()

    add() remove() ¿ para los seleccionados ?

*/

export class PageSet{
    
    recs= false
    endOfPage // cierto si en el último reset o next de la página actual llegamos al final de la misma
    curr

    constructor( recs ){
        if ( recs ){
            this.recs= [...recs]
            this.reset()
        } else {
            this.recs= false
            this.endOfPage= true
        }
    }

    /**	RESET
        Apunta al primer registro de la pagina actual guardada en cache.
        Devuelve cierto si hay registros en la página actual.
    */
    reset(){ 
        if ( this.recs && this.recs.length ){
            this.curr= 0
            this.endOfPage= this.recs.length == 0
            return ! this.endOfPage
        } else {
            this.endOfPage= true
            return false
        }
    }


    /**	NEXT 
        Apunta al siguiente registro de la página guardado en cache.
        Devuelve cierto si pudo moverse.
    */
    next(){ 
        if ( !this.endOfPage ){
            this.curr++
            this.endOfPage= this.curr >= this.recs.length
            return ! this.endOfPage
        } else return false
    }

    current(){
        return this.recs[ this.curr ]
    }

    /** END OF PAGE
    */
    EOP(){ return this.endOfPage }


}