import {Component} from './Component'
import manager from './Manager'

export class UIComponent extends Component{
    htmlTemplate
    UIComponents= []
    tag='div'

    /** TAG
     * Es el tipo de división que vamos a crear de forma automática para meterle el id al componente para que se pueda refrescar y acceder a el.
     * Por defecto será un div, pero si es falso indicará que la clase derivada se encargará por si sola de meter el identificador
     */

    addUIComponent(classDef, ...params){
        let UIComponent= super.addComponent(classDef, ...params)
        this.UIComponents.push( UIComponent )
        return UIComponent
    }

    // crea un UIComponent anclado fuera del espacio del componente padre.
    // Se crea su html en el record a renderizar y en lugar de ponerle su nombre se pone el nombre de un ancla
    // Al generar la plantilla automatica de subcomponentes no aparecen los anclados.
    // ¿ Se ancla automáticamente ?
    addAnchoredUIComponent(classDef, anchor, ...params){
        let component= this.addUIComponent( classDef, ...params)
        component.anchor= anchor
        return component
    }


    /** PRE HTML - GET HTML TEMPLATE 
     * -------------------------------------------------------------------------------------
     *  Esta función se usa para obtener la plantilla html del componente. 
     *  Esta función reemplaza el atributo htmlTemplate y la función htmlDynamic
     *  Por compatibilidad con versiones anteriores retorna como resultado y en ese orden lo que encuentre
     *  1) El resultado de la función htmlDynamic si existe y no es falso
     *  2) El atributo htmlTemplate si existe y no es falso
     *  3) El recurso manager.getHtmlResource( this.constructor.name ) si existe y no es falso
     *  4) La concatenación de sus componentes si los tuviera
     *  5) El recurso global manager.getHtmlResource( 'undefined_tpt' )
    */

    getHtmlTemplate(){
        let tpt
        if( typeof this.htmlDynamic === 'function' ) tpt= this.htmlDynamic()
        if( !tpt && typeof this.htmlTemplate !== 'undefined' && this.htmlTemplate) tpt= this.htmlTemplate
        if( !tpt ) tpt= manager.getHtmlResource( this.constructor.name )
        if( !tpt ) tpt= manager.getHtmlResource( 'undefined_tpt' )
        if( !tpt && this.UIComponents.length > 0 ){
            tpt= ''
            this.UIComponents.map( (component) =>{
                if ( typeof component.anchor == 'undefined' ) tpt+= `{{{${component.alias}}}}`
            })
        }
        if( !tpt ) tpt= `undefined template for component ${this.constructor.name}`
        return tpt
    }

    /** PRE HTML - GET RENDER PARAMS
     * -------------------------------------------------------------------------------------
     *  Esta función se ejecuta justo antes de renderizar las plantillas del componente.
     *  Debe devolver los parámetros de renderizado de la plantilla principal 'html' del componente. 
     *  como parámetro se le puede poner el identificador de la plantilla a renderizar.
     *  Por defecto por compatibilidad con versiones anteriores devuelve el atributo renderParams.
     *  Las clases derivadas implementan este método para refrescar los parámetros de renderización en el momento del refresco o inciación.
     *  Por defecto en los parametros de renderizado se meten el id del objeto y el nombre del constructor para el log en caso de error.
     *  id y classname
     */
    getRenderParams( id ){ return id == 'html' && typeof this.renderParams !== 'undefined' ? this.renderParams : {} }

    html(){
        let renderParams
            , tpt= this.getHtmlTemplate()
            , ret= {}
        

        if (typeof tpt == 'string' ) tpt={ 'html': tpt }

        for (let [ id, part ] of Object.entries( tpt )) {
            if (id != 'html' && id != 'wrap' ) ret[ id ]= _.render( part, this.getRenderParams( id ) )
        }

        renderParams= Object.assign( { id: this.id, classname: this.constructor.name }
            , this.componentsHtml()
            , this.getRenderParams( 'html' )
            , ret
        )
        ret[ 'html' ]= _.render( tpt['html'], renderParams  )

        if ( tpt['wrap'] ) ret[ 'html' ]= _.render( tpt['wrap'], Object.assign({ html: ret[ 'html' ]}, renderParams ))
        if ( this.tag ) ret[ 'html' ]= `<${this.tag} id="${this.id}">` + ret[ 'html' ] + `</${this.tag}>`

        return ret
    }

    componentsHtml(){
        let mainHtml= {}

        this.UIComponents.forEach( component => {
            let html= component.html()
                , anchor= typeof component.anchor !== 'undefined' ? component.anchor : component.alias
                
            for( let id in html ){
                if ( id == 'html' ) mainHtml[ anchor ]= html[ 'html' ]
                else mainHtml[ id ]= html[ id ]
            }
            
        }) 
        return mainHtml
    }


    /** POST HTML
     *  --------------------------------------------------------------
     *  El encargado de incluir la plantilla html en el dom es el manager. 
     *  Para poder acceder a los nodos una vez creados debemos escuchar el evento PostHtml del manager.
     */

    refresh(){
        let html= this.html(), part, element
        for( part in html ){
            if ( part == 'html' ) {
                element= document.getElementById( this.id )
                if ( element ) element.outerHTML= html[ part ]
            } else {
                element= window.document.getElementById( `_${part}_` )
                if ( element ) element.innerHTML= html[ part ]
            }
        }
    }


}