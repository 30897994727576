import manager from '../platform/Manager'
import {UIComponent} from '../platform/UIComponent'

export default class extends UIComponent{
    htmlTemplate='<div class="container">{{{menu}}}</div>'

    constructor(id){
        super(id)
        this.menu= this.addUIComponent( manager.getClass( 'Menu' ) )
        this.menu.setAlias('menu')
        let tecla= 1
        this.menu.addItem( "_.open('GenericUpdateTest')", 'Prueba de actualización genérico' , 'Prueba los procedimientos php y mysql de actualización generica.', tecla.toString() ); tecla++
        this.menu.addItem( "_.open('GenericUpdateTest')", 'Prueba de actualización genérico' , 'Prueba los procedimientos php y mysql de actualización generica.', tecla.toString() ); tecla++
        this.menu.addItem( "_.open('Upload')", 'UPLOAD' , 'Prueba de subidas', tecla.toString() ); tecla++
        this.menu.addItem( "_.open('TestModals')", 'Probar modal global' , 'que se cargen en la sección modals de la página principal y se llamen desde cualquier formulario', tecla.toString() ); tecla++
        this.menu.addItem( "_.open('Query','sesionvars')", 'VARIABLES DE SESION' , 'Variables de la sesión actual.', tecla.toString() ); tecla++
        
//        this.menu.addItem( "_.open('')", titulo , descripcion, tecla )
    }
   
}