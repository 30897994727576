// Module
var code = `<div class="container">
<form id="{{id}}_form">
    <div class="mb-3">
        <label for="{{id}}_nombre" class="form-label">Nombre</label>
        <input class="form-control" id="{{id}}_nombre"/>
    </div>

    <div class="mb-3">
        <label for="{{id}}_apellidos" class="form-label">Apellidos</label>
        <input class="form-control" id="{{id}}_apellidos"/>
    </div>

    <div class="mb-3 border-bottom">
        <label for="{{id}}_usuario" class="form-label">Cuenta de usuario</label>
        <input class="form-control" id="{{id}}_usuario" onfocus="_('calculaCuenta')" />
        <div class="form-text">Solemos usar la primera inicial del nombre o nombre completo y apellido sin espacios.</div>
    </div>
    
    <div class="d-flex"> 
        <button class="btn btn-secondary ms-auto me-2" type='button' onclick="_.returns()">CANCELAR</button>
        <button type='button' class="btn btn-primary" onclick="_('crear')">ACEPTAR</button>
    </div>


</form>
</div>
`;
// Exports
export default code;