// Module
var code = `<div class="modal fade" tabindex="-1" id="_M00">
    <div class="modal-dialog">
        <div class="modal-content" id="_M00_content_">
        </div>
    </div>
</div>
<div class="modal fade" tabindex="-1" id="_M01">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header text-bg-danger">
                <h5 class="modal-title">ERROR</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-danger">
                <p><strong id="_M01_errorMsj"></strong></p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger" data-bs-dismiss="modal">ACEPTAR</button>
            </div>
            
        </div>
    </div>
</div>
<div class="modal fade" tabindex="-1" id="_M02">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="_M02_titulo">Certificado del fabricante</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <input class="form-control" id="_M02_upload" type="file" onchange="_('doupload', this)">
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger" data-bs-dismiss="modal">CANCELAR</button>
            </div>
        </div>
    </div>
</div>
`;
// Exports
export default code;