import { Lista } from '../../platform/pages/Lista'

export class Query extends Lista{

    constructor(id, query, title, html_actions){
        super( id, query )
        if( typeof title == 'undefined' ) title= this.query.id
        this.renderParams={id:id, title: title, actions: html_actions}
    }

}