import manager from '../platform/Manager'

import './gestimaq/_index'
import './infraspeak/_index'
import './jackpot/_index'

// HTML resources
//import resource from './resource.html'; manager.addHtmlResource( 'resource', resource )

// Page classes
//import page from './page'; manager.addPageClass( 'page', page )
import Integraciones from './Integraciones'; manager.addPageClass( 'Integraciones', Integraciones )
import GestimaqIX from './Gestimaq'; manager.addPageClass( 'GTMIX', GestimaqIX )
import InfraspeakIX from './Infraspeak'; manager.addPageClass( 'ISIX', InfraspeakIX )
import JackpotIX from './Jackpot'; manager.addPageClass( 'JSIX', JackpotIX )
