import manager from '../../platform/Manager'

// HTML resources
//import resource from './resource.html'; manager.addHtmlResource( 'resource', resource )

/**
 * CREA LA CONSULTA Empresa.sql en el servidor
 * PONLE CONTENIDO a los formularios de ficha
 * CERA LOS PROCEDIMIENTOS. empresa__ficha, empresa__elimina, empresa__crea
 * ACTUALIZA LOS VALORES DE LA TABLA permiso__update por para los diferentes perfiles de los campos actualizables por perfil
 * PREPARA LOS CAMPOS DE LOOKUP, UPLOAD, Y ENUMS.
 */


// Page classes
//import page from './page'; manager.addPageClass( 'page', page )
import {Empresa} from './Ficha'; manager.addPageClass( 'EmpresaFicha', Empresa )
import {EmpresaLst} from './Lista'; manager.addPageClass( 'EmpresaLst', EmpresaLst )
