//import { Page } from './Page'
import { UIComponent } from './UIComponent'

export class StaticPage extends UIComponent{

    constructor(id, html, params){
        super( id )
        this.htmlTemplate= html
        this.renderParams= params
    }

}


