import manager from '../../platform/Manager'
import { UIComponent } from '../../platform/UIComponent'
import html from './sinperfil.html'

export class SinPerfil extends UIComponent{
    htmlTemplate= html
    constructor(id){
        super(id)
        manager.request('/DB/procedure/run/sesion__finaliza')
    }
}