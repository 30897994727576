/**
 * La lista editable es una lista de lookup con funcionalidad para crear nuevos, eliminar y editar en una ventana solo, con modales para la edición. 
 * Se trata de una tabla pequeña con pocos campos de los cuales no hay lookups, normalmente solo el nombre y poca cosa mas.
 * No tiene la funcionalidad de creación, ni validación. 
 * La conexión con la base de datos es a través de procedimentos. __crea, __update y __elimina
 * El GUI es como un lookup al que se le añaden los botones pencil (editar y crear) y trash (eliminar)
 */
import {Modal} from 'bootstrap'
import manager from '../Manager'
import { Lista } from './Lista'
import listaHtml from './listaeditable.html'
import actionsHtml from './listaeditable-actions.html'

export class ListaEditable extends Lista{
    procedures={} /* create, update, delete, view */
    editMode

    constructor( id, query, Form ){
        super(id, query, listaHtml )
        this.renderParams={ id: this.id, actions: actionsHtml }
        this.form= this.addUIComponent( Form )
        this.form.alias= 'form'
        this.setProcedureNames( this.query.id )
        setTimeout( this.creaModal.bind(this), 100 )
    }

    creaModal(){
        let element= document.getElementById( this.id + '_LEF')
        if ( element ) this.modal= new Modal( element  ) //, options
        else console.log( 'No encuentro el elemento '  +  this.id + '_LEF')
    }

    setProcedureNames( procedures ){
        switch( typeof procedures ){
            case 'string':
                this.procedures.create= procedures + '__crea'
                this.procedures.update= procedures + '__actualiza'
                this.procedures.delete= procedures + '__elimina'
                this.procedures.view= procedures + '__ficha'
                break;

            default:
                this.procedures= procedures
        }
    }

    onShow(){
        this.recordset.refresh()
    }

    limpiaFicha(){
        let form= document.forms[ this.id+ '_LEF_form']
        for ( let i=0; i < form.length; i++ ) form[i].value= ''
    }


    cargaFicha( clave, response ){
        this.editingKey= clave
        this.data= response[0][0]
        for( let fd in this.data ) this.setValor( fd, this.data[fd] )
    }

    setValor(id, valor){
        this.form.setValor(id, valor)
    }

    guardaFicha(){
        let proc= this.editMode == 'create' ? this.procedures.create : this.procedures.update
            , form= form= document.forms[ this.id+ '_LEF_form']
            , params=[]
        for ( let i=0; i < form.length; i++ ) form[i].value= ''
        console.log('donde estan los ids')
        
    }

    ficha(){
        let select= this.list.getSelectedKeys()
        this.limpiaFicha()
        if( select.length == 1 ) {
            this.editMode= 'edit'
            manager.request('DB/procedure/run/' + this.procedures.view, [ ...select[0] ]) 
            .done( this.cargaFicha.bind(this, select[0]) )

        } else {
            this.editMode= 'create'
        }
        this.modal.show()
    }

    elimina(){
        console.log('elimina')
        let select= this.list.getSelectedKeys()
        if( select.length == 1 ) {
            manager.request('DB/procedure/run/' + this.procedures.create, [select[0]])
            .done( this.refresh )
        }

    }


    retornaSelected(){
        let select= this.list.getSelectedKeys()
        if( select.length == 1 ) _.returns( select[0] )
        else _.returns( false )
    }

    default(){ this.retornaSelected() }
    cancel(){ 
        let selected= this.list.getSelectedKeys()
        if ( selected.length > 0 ) this.list.unselect()
        else manager.returns( false )
     }



}
 
