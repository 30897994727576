/* global _ */

/** Clase Base de DataDef y record que mantiene la definición del dataset.
**/

import controllers from './TypeControllers'

export class RecordDef {
    cols 
        // id, type, sqltype - include_result_sql
        // list - width(rem), hdclass, caption, dclass, (textAlign), 'printFormat()'
        // card - widget, dropdown, lookup, readonly, disabled, calc - validate, autofill

    key
    views
    actions
    caption= ''

    setFromResult( result ){
        this.auto= true
        this.caption= ""
        this.cols= {}
        if ( result.length ){
            let view=[]
            for( let colName in result[0] ){
                if (typeof this.key === 'undefined' ) this.key= [ colName ]
                this.cols[colName]= {
                    type: typeof result[0][colName ] == 'number' ? 'double' : 'string'
                    , length: 8
                }
                view.push( colName )
                this.cols[colName].controllerClass= this.cols[colName].type
                this.cols[colName].widgetClass= this.cols[colName].type
                this.cols[colName].controller= new controllers[ this.cols[colName].controllerClass ]()
            }
            this.views={ default: view } 
        }
    }
 
    setDef( def ){
        let defaultView= []
        this.auto= def.auto ? true : false;
        this.cols= def.cols
        if (typeof def.key !== 'undefined' ) this.key= def.key
        else this.key= [ Object.keys(this.cols)[0] ]
        for(let col in this.cols ) {
            defaultView.push( col )
            if( typeof this.cols[col].controllerClass === 'undefined' ) this.cols[col].controllerClass= this.cols[col].type;
            this.cols[col].controller= new controllers[ this.cols[col].controllerClass ]()
            if( typeof this.cols[col].widgetClass === 'undefined' ) this.cols[col].widgetClass= this.cols[col].type;
//            col.widget= new widgets[col.widgetClass]()   // los widgets los pasamos al card. Es solo UI o debería serlo.
        }
        this.views= def.views ? def.views : {}
        if ( typeof this.views.default === 'undefined' ) this.views={ default: defaultView }
        if ( typeof def.actions !== 'undefined') this.actions= def.actions
        else this.actions= {} //TODO. poner el retornar el registro seleccionado.
        if (def.caption) this.caption= def.caption

    }

    getKey( rec ){
        let key= []
        for (let i=0; i < this.key.length; i++) {
            key.push( rec[ this.key[i] ] )
        }
        return key
    }

    getCols( view ){ 
        let cols= [], col
        if( typeof this.views[view] === 'undefined' ) view= 'default'
        if( typeof this.views[ view ]  === 'undefined' ) return {}
        this.views[view].forEach( (colid) => {
            let col= { id: colid}
            Object.assign( col, this.cols[colid] )
            cols.push( col )
        })
        return cols }

    getColNames(){ return Object.keys(this.cols) }
    
}